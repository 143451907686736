import React, { Component } from 'react';
import { MDBDataTable } from 'mdbreact';

class DataTableComponent extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <MDBDataTable
                hover
                infoLabel={["Mostrando del ", "al", " de ", "resultados"]}
                paginationLabel={["Anterior", "Siguiente"]}
                searchLabel="Buscar"
                entriesLabel='Mostrar'
                pagesAmount={12}
                //entries={8}
                //displayEntries={false}
                data={this.props.data}
            />
        )
    }
}
export default DataTableComponent;