
import React, { Component } from "react";
import firebase from 'firebase';
import Form from 'react-bootstrap/Form';
import CurrentUser from "../public/core/PublicUserSessionContext";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import Alert from 'react-bootstrap/Alert'

class PublicContactComponent extends Component {
    constructor() {
        super();
        this.state = {
            contact: { name: "", email: "", subject: "", message: "" },
            isSending: false,
            hasSent: false,
            error: false,
            errors: {}
        };
    }

    handleInputChange = (event) => {
        const contact = this.state.contact;
        contact[event.target.name] = event.target.value;
        this.setState({ contact, errors: [], hasSent: false });
    }

    handleFormSubmit = (event) => {
        event.preventDefault();
        if (this.validateErrors())
            this.saveContact();
    }

    validateErrors = () => {
        let errors = {}
        const required = ["name", "email", "subject", "message"]
        required.forEach(field => {
            if (!this.state.contact[field])
                errors[field] = "Debes llenar este campo."
        })

        let email = this.state.contact.email
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (email && !re.test(String(email).toLowerCase()))
            errors.email = "Introduce un correo válido.";

        this.setState({ errors });
        return Object.keys(errors).length === 0
    }

    saveContact = async () => {
        const contact = this.state.contact;
        this.setState({ isSending: true, hasSent: false, error: false });
        await firebase.firestore().collection("contacts").add({ ...contact, date: Date.now() }).catch(error => {
            this.setState({ isSending: false, hasSent: false, error: true });
            this.context.mostrarError("No se pudo enviar correo", "ERROR CORREO CONTACTO");
        })
        this.setState({ contact: { name: "", email: "", subject: "", message: "" }, isSending: false, hasSent: true, error: false });
    }

    render() {
        const errors = this.state.errors;
        return (
            <section className="contact-page">
                <div className="container">
                    <h1 className="mb-5">Contacto</h1>

                    <div className="row ">
                        <div className="col-12 col-md-12 ml-md-auto">
                            {this.state.isSending && <p className="h3 mb-3">Enviando mensaje, espere por favor...</p>}
                            {this.state.hasSent && <p className="h3 mb-3">Gracias por contactarte con nosotros, nos contactaremos a la brevedad.</p>}
                        </div>

                        {!this.state.isSending && !this.state.hasSent &&
                            <div className="col-12 col-md-6 ml-md-auto order-md-2">
                                <p className="h3 mb-3">Nos encantaría saber tu opinión y ayudarte en lo que podamos.</p>
                                <p className="lead mb-3">Contáctanos y te responderemos en la brevedad.</p>
                                <ul className="list-unstyled list-icon">
                                    <li>
                                        <a href="mailto:contacto@cirugiaveterinaria.mx"><FontAwesomeIcon icon={faEnvelope} />  contacto@cirugiaveterinaria.mx</a>
                                    </li>
                                </ul>
                            </div>}

                        {!this.state.isSending && !this.state.hasSent &&
                            <div className="col-12 col-md-6">
                                <Form onSubmit={this.handleFormSubmit} className="form form-contact" >
                                    <div className="form-group">
                                        <label htmlFor="name">Nombre</label>
                                        <Form.Control type="text" isInvalid={errors.name} name="name" value={this.state.contact.name} onChange={this.handleInputChange} className="form-control" />
                                        <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="email"> Correo Electrónico</label>
                                        <Form.Control type="text" isInvalid={errors.email} name="email" value={this.state.contact.email} onChange={this.handleInputChange} className="form-control" />
                                        <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="subject ">Asunto</label>
                                        <Form.Control type="text" isInvalid={errors.subject} name="subject" value={this.state.contact.subject} onChange={this.handleInputChange} className="form-control" />
                                        <Form.Control.Feedback type="invalid">{errors.subject}</Form.Control.Feedback>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="message ">Mensaje</label>
                                        <Form.Control as="textarea" isInvalid={errors.message} name="message" value={this.state.contact.message} onChange={this.handleInputChange} className="form-control" />
                                        <Form.Control.Feedback type="invalid">{errors.message}</Form.Control.Feedback>
                                    </div>
                                    <div className="form-group">
                                        <button id="contact-submit" type="submit" className="btn btn-primary btn-block">
                                            Enviar
                                        </button>
                                    </div>
                                </Form>

                                {this.state.error && <Alert variant="danger"> Ocurrio un error.</Alert>}
                            </div>}
                    </div>
                </div>
            </section>
        );
    }
}
PublicContactComponent.contextType = CurrentUser;
export default PublicContactComponent;