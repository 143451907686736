import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import Alert from 'react-bootstrap/Alert';
import DataTableComponent from '../../admin/core/DataTableComponent';
import firebase from 'firebase';

var moment = require("moment");
moment.locale("es");

class ExpirationWarningModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            
        }
    }

    formatTime = () => {
        let timeLeft = this.props.dateExpiration - Date.now()
        let timeLeftDays = timeLeft / 86400000
        let timeLeftHours = timeLeft / 3600000

        if (timeLeftDays < 3) {
            if (timeLeftHours < 1)
                return "1 hora"
            else
                return Math.ceil(timeLeftHours) + " horas"
        }
        else {
            return Math.ceil(timeLeftDays) + " días"
        }

    }

    render() {

        return (
            <Modal {...this.props} size="lg" className="progress-modal" centered >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <h5 className="modal-title" id="modalTerminoLabel">Alerta</h5>
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <p className="text-center mb-0">Solo te quedan menos de {this.formatTime()} para que caduque esta aula virtual y ya no tendrás acceso a su contenido.</p>

                </Modal.Body>

                <Modal.Footer >
                    <button role="button" className="btn btn-primary" onClick={this.props.onHide}>Cerrar</button>
                </Modal.Footer>
            </Modal>
        );
    }
}
export default ExpirationWarningModal;