import React, { Component } from 'react';
import ContPaypal from "../core/ContPaypal";
import CurrentUser from '../core/PublicUserSessionContext';
import firebase from 'firebase';

import Alert from 'react-bootstrap/Alert'
class paymentFormComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            uid: this.props.location.uid || "", planAnual: this.props.location.planAnual || false,
            paymentType: this.props.location.paymentType || "Premium", type: this.props.location.type || "Premium",
            email: this.props.location.email || "", userName: this.props.location.userName || ""
        };
    }
    componentDidMount = () => {
        if ((this.state.uid === "" && !this.context.currentUser.id) || this.context.currentUser.type === "Premium") {
            this.props.history.push("/registro")
        }

        if (this.state.uid === "" && this.context.currentUser.id)
            this.setState({ uid: this.context.currentUser.id, email: this.context.currentUser.email, userName: this.context.currentUser.name })

    }
    handlePaymentTypeChange = (event) => {
        this.setState({ planAnual: event.target.value == "PremiumAnual", paymentType: event.target.value, type: event.target.value == "Gratis" ? "Gratis" : "Premium" })
    }

    registerSubscriptionID = (subscriptionId) => {
        console.log("subscriptionId: " + subscriptionId)

        firebase.firestore().collection("users").doc(this.state.uid).update({
            subscriptionId: subscriptionId
        })
    }
    registrarPago = (subscriptionId) => {
        console.log("Approved subscriptionId: " + subscriptionId)

        firebase.firestore().collection("users").doc(this.state.uid).update({
            subscriptionId: subscriptionId,
            type: "Premium",
            hasPremiumAnual: this.state.planAnual,
            paymentDay: Date.now()
        }).then(() => {
            this.props.history.push({ pathname: "/registro", type: "Premium" })
        })
    }

    sendUserEmail = () => {
        this.context.iniciaCarga();
        const updateAccountEmailFunction = firebase.functions().httpsCallable('updateAccountEmail');
        let data = {
            accountType: "Gratis",
            actionType: "SIGNUP",
            emailAddress:  this.state.email,
            nombreCliente: this.state.userName,
            fechaPago: Date.now(),
            planAnual: false
        }

        updateAccountEmailFunction(data).then((result) => {
            console.log("ALL GOOD");
            this.context.terminaCarga();
            this.props.history.push("/registro")
        }).catch((error) => {
            console.log(error);
            this.context.terminaCarga();
            this.props.history.push("/registro")
        });
    }

    render() {
        return (
            <section className="register-page">
                <div className="container">
                    <div className="row">
                        <div className="container">
                            <h1 className="">
                                Método de pago
                            </h1>
                            <h2 className="subtitle mb-5">
                                Todas nuestras transacciones son realizadas de forma segura
                            </h2>
                            <div className="row justify-content-center ">
                                <div className="col-md-10 ">
                                    <ul className="step d-none d-sm-flex flex-nowrap mb-5">
                                        <li class="step-item ">
                                            <a className="link-no-btn" >Tipo de membresía</a>
                                        </li>
                                        <li class="step-item ">
                                            <a className="link-no-btn" >Información personal</a>
                                        </li>
                                        <li class="step-item  active">
                                            <a><button type="button" disabled className="link-no-btn" >Método de pago</button></a>
                                        </li>
                                    </ul>
                                    {this.state.type === "Premium" &&
                                        <Alert variant="warning"> Aviso, para evitar problemas con tu registro, por favor no cierres tu explorador ni las ventanas hasta que tu pago sea completado y vuelvas a la página de CxVet. </Alert>
                                    }
                                    <div className="row justify-content-center ">
                                        <div className="col-md-8">
                                            <div className="form-payment">
                                                <h4>Membresía</h4>
                                                <div className="form-group">
                                                    <label>Tipo de membresía elegida</label>
                                                    <select className="form-control" onChange={this.handlePaymentTypeChange} name="type" value={this.state.paymentType}>
                                                        <option value="Premium">Membresia Premium ($9.99 USD / mes)</option>
                                                        <option value="PremiumAnual">Membresia Premium ($99.90 USD / año)</option>
                                                        <option value="Gratis">Membresia Básica (sin costo)</option>
                                                    </select>
                                                </div>
                                                {this.state.type === "Premium" ?
                                                    <React.Fragment>
                                                        <ContPaypal registerSubscriptionID={this.registerSubscriptionID} registrarPago={this.registrarPago} planAnual={this.state.planAnual} uid={this.state.uid} />
                                                    </React.Fragment>
                                                    :
                                                    <div className="form-group">
                                                        <button onClick={this.sendUserEmail} className="btn btn-secondary btn-block">
                                                            FINALIZAR
                                                    </button>
                                                    </div>
                                                }
                                            </div>


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
paymentFormComponent.contextType = CurrentUser;

export default paymentFormComponent;