import React,{Component} from "react";
import Modal from "react-bootstrap/Modal";
class UsoCookies extends Component{
    constructor(props){
        super(props);
        this.state = {aceptaCookies:false}
    }
    componentDidMount (){
        let acepta = localStorage.getItem("ACEPTA-USO-COOKIES");
        if(acepta){
            this.setState({aceptaCookies:true})
        }
    }
    aceptar = ()=>{
        this.setState({aceptaCookies:true})
        localStorage.setItem("ACEPTA-USO-COOKIES",true);
    }

    render(){
        return <Modal show = {!this.state.aceptaCookies}>
            <Modal.Header>USO DE COOKIES</Modal.Header>
            <Modal.Body>
              Este sitio utiliza cookies. Al continuar navegando en él consideramos que aceptas su uso.

            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-primary" onClick = {this.aceptar}>ACEPTAR</button>
                </Modal.Footer>
        </Modal>
    }
}

export default UsoCookies;