import React,{Component} from "react";
import queryString from 'query-string';
import firebase from "firebase";
import Alert from "react-bootstrap/Alert";
import Modal from "react-bootstrap/Modal";
import CurrentUser from "../public/core/PublicUserSessionContext";
class ReasignarPasswordComponent extends Component{
    constructor(props){
        super(props);
        this.state = {mode:"",oobCode:"", apiKey:"",password:"",confirmacion:"",success:false,error:""}
    }
    irIniciarSesion=()=>{
        //console.log("IR INICIAR SESION");
        //console.log(this.props);
        this.props.history.push("/login");
    }
    componentDidMount(){
        let that = this;
        let params = queryString.parse(this.props.location.search);
        console.log("PARAMAETERS");
        console.log(params);
        
        firebase.auth().verifyPasswordResetCode(params.oobCode).then(function(email) {
            var accountEmail = email;                
            that.setState(params);    
          }).catch(function(error) {
              console.log("LINK YA NO ES VALIDO")
              console.log(error);
              if(error.code){
                if(error.code==="auth/invalid-action-code"){
                    that.context.mostrarError("El código ya no es válido","CÓDIGO INVÁLIDO");
                }
              }
              else
                that.context.mostrarError("Ocurrió un error", "ERROR");
            // Invalid or expired action code. Ask user to try to reset the password
            // again.
          });
    }
    cambiarPassword = ()=>{
        let that = this;
        if(this.state.password.length <6){
            this.setState({error:"El password debe tener al menos 6 caracteres"});
            return;
        }
        if(this.state.password!==this.state.confirmacion){
            this.setState({error:"Los password no coinciden"});
            return;
        }
        firebase.auth().confirmPasswordReset(this.state.oobCode, this.state.password).then(function(resp) {
            that.setState({success:true});
            // Password reset has been confirmed and new password updated.
      
            // TODO: Display a link back to the app, or sign-in the user directly
            // if the page belongs to the same domain as the app:
            // auth.signInWithEmailAndPassword(accountEmail, newPassword);
      
            // TODO: If a continue URL is available, display a button which on
            // click redirects the user back to the app via continueUrl with
            // additional state determined from that URL's parameters.
          }).catch(function(error) {
              console.log("ERROR");
              console.log(error);
              that.context.mostrarError("Hubo un error al cambiar password","ERROR CAMBIO DE PASSWORD");
            // Error occurred during confirmation. The code might have expired or the
            // password is too weak.
          });
    }
    render(){
        
        
        
        return <div>
            <Modal show = {this.state.success} onHide={this.irIniciarSesion}>
            <Modal.Header closeButton>
                <Modal.Title>Contraseña modificada</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <p>Tu contraseña fue modificada con éxito, ya puedes ingresar a tu cuenta</p>
            </Modal.Body>

            <Modal.Footer>
                <button onClick = {this.irIniciarSesion} variant="primary">Aceptar</button>
            </Modal.Footer>
        </Modal>
            <section class="register-page">
			
			<div class="container">
				<div class="row">
					
					<div class="container">
						<h1 class="mb-5">
							Cambiar contraseña
						</h1>
						
						<div class="row justify-content-center ">
							<div class="col-md-4 ">
								
														
								<div class="form-group">
									<label >Nueva contraseña</label>
									<input type="password" class="form-control" required="" value = {this.state.password}
                                        onChange ={(ev)=>{this.setState({password:ev.target.value})}}/>
									
								</div>
								<div class="form-group">
									<label >Repetir contraseña</label>
									<input type="password" class="form-control" required="" value = {this.state.confirmacion}
                                        onChange ={(ev)=>{this.setState({confirmacion:ev.target.value})}}/>
									
								</div>
								<Alert show = {this.state.error !==""} variant = "danger">{this.state.error}</Alert>
								<div class="form-group">
									<button class="btn btn-primary btn-block" onClick = {this.cambiarPassword}>
										Cambiar  
									</button>
									
								</div>
							</div>
							
						</div>
					</div>
					
					
				</div>
			</div>
			
		</section>

        </div>
    }
}

function handleResetPassword(auth, actionCode, continueUrl, lang) {
    // Localize the UI to the selected language as determined by the lang
    // parameter.
    var accountEmail;
    // Verify the password reset code is valid.
    
  }
  ReasignarPasswordComponent.contextType = CurrentUser;
export default ReasignarPasswordComponent;