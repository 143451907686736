import React,{Component} from 'react';
import firebase from 'firebase';
import EventListComponent from './EventListComponent';

class EventComponent extends Component{
    constructor(props){
        super(props);
        this.state = {events:[]};
    }

    componentDidMount = () =>{
        const db = firebase.firestore();
        
        db.collection('events').get().then((snapshot) => {
            const events = [];
            snapshot.docs.map((doc) => events.push({id:doc.id,...doc.data()}));
            this.setState({events});
        });
    }

    render(){
        return(
            <div className="content-wrapper p-5">
                <div className="row">
                    <div className="col-md-12">
                        <h1>Eventos</h1>
                        <EventListComponent events={this.state.events} redirectTo={(url) => this.props.history.push(url)}/>
                        <div className="btn-row"  >
                            <a href="/admin/events/event" className="btn btn-primary" >Crear nuevo evento</a>

                        </div>
                    </div>
                </div>
		 	</div>
        );
    }
}

export default EventComponent;