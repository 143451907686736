import React,{Component} from 'react';
import firebase from 'firebase';
import {Redirect,Link} from 'react-router-dom';
import Alert from 'react-bootstrap/Alert'
import Form from 'react-bootstrap/Form'
import CurrentUser from '../public/core/PublicUserSessionContext';

class LoginComponent extends Component{
    
    constructor(props){
        super(props);
        this.state = {
            isSessionRestart:this.props.sessionRestart,
            homeUrl:{admin:"/admin",user:"/"},
            currentUser : {type:"Gest"},
            credentials : {email:"", password:""},
            errors : {}
        }
    }

    handleInputChange = (event) => {
        const value = event.target.value;
        const name = event.target.name;
        const element = this.state.credentials;
        element[name] = value;
        const errors = Object.assign({},this.state.errors);
        delete errors[name];
        this.setState({element,errors,error:""});
    }

    handleSubmit = (event) => {
        event.preventDefault();
        if(this.validate()){
            this.logIn();
        }
    }

    validate(){
        const values = this.state.credentials;
        const errors = {};
        const requiredMessage = "Debes llenar este campo";

        if(!values.email){
            errors.email = requiredMessage;
        }
        if(!values.password){
            errors.password = requiredMessage;
        }

        this.setState({errors});
        if(!Object.keys(errors).length){
            return true;
        }
    }

    logIn = () => {
        this.context.iniciaCarga();
        this.setState({error:false});
        firebase.auth().signInWithEmailAndPassword(this.state.credentials.email,this.state.credentials.password).then((response) => {
            if(this.state.isSessionRestart){
                //console.log("ZZZZZZZZZZZZZZZZZZZZZZZZZZ ",response);
                this.props.sessionRenewed(response.user.uid);
            }
        }).catch(response => {
            //console.log(response);
            if(response.code){

                if(response.code === "auth/user-disabled"){
                    this.setState({error:"Este usuario esta deshabilitado, por favor contacta con el administrador."});
                }else if(response.code=== "auth/user-not-found"){
                    this.setState({error:"Usuario no encontrado."});             
                }else if(response.code=== "auth/invalid-email"){
                    this.setState({error:"Dirección de correo inválida."});
                }
                else if(response.code==="auth/wrong-password"){
                    this.setState({error:"Contraseña incorrecta"});
                }
                else{
                    this.context.mostrarError("Ocurrió un error, intente más tarde","ERROR LOGIN",);
                }
            }else{
                this.context.mostrarError("Ocurrió un error, intente más tarde","ERROR LOGIN",);
            }
        }).finally(err=>{
            this.context.terminaCarga();
        });
    }

    render(){
        let currentUser = this.context.currentUser;
        const errors = this.state.errors;
        const credentials = this.state.credentials;
        return (
            <section className="register-page">
                {(currentUser.type !== "Gest" && !this.state.isSessionRestart) &&
                    <Redirect to={(currentUser.type === "Admin")?this.state.homeUrl.admin:this.state.homeUrl.user} />
                }
                <div className="container">
                    <div className="row">
                        <div className="container">
                            <h1 className="mb-5">
                                {this.state.isSessionRestart?
                                this.props.message
                                :    
                                'Iniciar sesión'
                                }
                            </h1>
                            <Form onSubmit={this.handleSubmit} >
                                <div className="row justify-content-center ">
                                    <div className="col-md-4 ">		
                                        {this.state.error &&
                                        <Alert  variant="danger">
                                            {this.state.error}
                                        </Alert>
                                        }
                                        <div className="form-group">
                                            <label htmlFor="email">Correo Electrónico</label>
                                            <Form.Control type="text" isInvalid={errors.email} onChange={this.handleInputChange} name="email" value={credentials.email} className="form-control" />
                                            <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                                            
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="password">Contraseña</label>
                                            <Form.Control type="password" isInvalid={errors.password} onChange={this.handleInputChange} name="password" value={credentials.password} className="form-control" />
                                            <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
                                        </div>
                                        <div className="form-group">
                                        
                                        <button className="btn btn-block btn-primary btn-block" type="submit" >Entrar</button>
                                            {!this.state.isSessionRestart &&
                                            <Link to = "/login/recuperar_password" className="text-center btn-block" >
                                                Recuperar mi contraseña
                                            </Link>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
LoginComponent.contextType = CurrentUser;
export default LoginComponent;