import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import { getExamScore} from '../../../service/exams'

var moment = require("moment");
moment.locale("es");

class CompletedExamModal extends Component {
    constructor(props) {
        super(props);

        this.state = {

        }
    }

    componentDidMount = () => {
    }

    render() {
        let threshold = this.props.threshold
        let percent = getExamScore(this.props.exam).percent
        return (
            <Modal {...this.props} size="lg" className="progress-modal" centered >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <h5 className="modal-title" id="modalTerminoLabel">Ha terminado el examen con: {percent}%</h5>
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    {percent < threshold && <h3>Se requiere una puntuación de {threshold}% o más para aprobar. Puede volver a tomar el examen hasta que has pasado.</h3>}
                    {percent >= threshold && <h3>¡Ha completado el examen con una calificación aprobatoria, Felicidades!</h3>}
                </Modal.Body>

                <Modal.Footer>
                    <button type="button" className="btn btn-primary" onClick={this.props.onHide}>Cerrar</button>
                </Modal.Footer>
            </Modal>
        );

    }

}
export default CompletedExamModal;