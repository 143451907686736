import React, { Component } from 'react';
import firebase from 'firebase';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import DataTableComponent from './core/DataTableComponent';
import upArrow from '../../resources/images/up-arrow.png'
import downArrow from '../../resources/images/down-arrow.png'
import CurrentUser from "../public/core/PublicUserSessionContext";
import Spinner from "../commons/Spinner";
import AdministrarFotoLandingModal from "./AdministrarFotoLandingModal"
import { getPhotosLanding } from '../../service/home';

class AdminPhotosLanding extends Component {

    constructor(props) {
        super(props);
        this.state = {
            photosLanding: [],
            loading: true,
            adminModalOpen: false,
            tiempo: 5,
            fileAEditar: undefined
        }
        this.hiddenFileInput = React.createRef(null)
    }

    componentDidMount() {
        this.getInitialData()
    }

    getInitialData = async () => {
        let photosLanding = await getPhotosLanding()
        if (photosLanding.length > 0)
            this.setState({ photosLanding, loading: false, tiempo: photosLanding[0].tiempo || 5 })
        else
            this.setState({ photosLanding, loading: false })
    }
    getPhotosLandingColumns = () => [
        { label: '', field: 'reorderUp', sort: 'disabled' },
        { label: '', field: 'reorderDown', sort: 'disabled' },
        { label: 'Título', field: 'name', sort: 'asc' },
        { label: 'Vista Previa', field: 'preview', sort: 'asc' },
        { label: 'Quitar', field: 'delete', sort: 'asc' },
    ];
    getPhotosLandingData = () => {
        const rows = [];
        this.state.photosLanding.map((foto, index) => {
            rows.push({
                reorderUp: <button type="button" className="btn btn-primary" disabled={index === 0} onClick={() => this.reorder(index, -1)}><img src={upArrow} height={32} width={32}></img> </button>,
                reorderDown: <button type="button" className="btn btn-primary" disabled={index === 0} onClick={() => this.reorder(index, 1)}><img src={downArrow} height={32} width={32}></img> </button>,
                name: foto.name,
                preview: foto.video ? this.getVideo(foto.url) : <img onClick={() => { this.setState({ fileAEditar: index === 0 ? undefined : foto, adminModalOpen: index !== 0 }) }} width={240} controls src={foto.url} />,
                delete: <a className="text-light btn btn-primary" onClick={(e) => this.removeResource(index)}>
                    <FontAwesomeIcon icon={faTrash} />
                </a>
            });
        });
        return { columns: this.getPhotosLandingColumns(), rows };
    }

    reorder = (index, direction) => {
        if (index === 0 && direction === -1) {
            //do nothing
        }
        else if (index === this.state.photosLanding.length - 1 && direction === 1) {
            //do nothing
        }
        else if (this.state.photosLanding.length === 1) {
            //do nothing
        }
        else {
            let photosCopy = this.state.photosLanding
            let thefotoToMove = photosCopy[index]
            let previousfoto = photosCopy[index + direction]

            photosCopy[index] = previousfoto
            photosCopy[index + direction] = thefotoToMove
            this.setState({ photosLanding: photosCopy })
        }
    }

    subirPhotos = async () => {
        this.setState({ loading: true })
        for (let index in this.state.photosLanding) {
            let foto = this.state.photosLanding[index]
            if (foto.file) {
                let storagePath = undefined, storageRef = undefined;

                storagePath = `landing_page/${foto.fecha_creacion}_${foto.name}`
                storageRef = firebase.storage().ref().child(storagePath)

                let uploadResourceTask = await storageRef.put(foto.file)
                let url = await storageRef.getDownloadURL()
                delete foto.file
                await firebase.firestore().collection("photosLanding").add({ ...foto, url, tiempo: this.state.tiempo, orden: parseInt(index) + 1 })
            }
            if (foto.id)
                await firebase.firestore().collection("photosLanding").doc(foto.id).update({ ...foto, tiempo: this.state.tiempo, orden: parseInt(index) + 1 })

        }
        this.setState({ loading: false })
        this.props.history.goBack()
    }

    removeResource = async (index) => {
        if (index > 0) {
            this.setState({ loading: true })
            let foto = this.state.photosLanding[index]

            let photosLandingCopy = this.state.photosLanding.map(el => ({ ...el }))
            photosLandingCopy.splice(index, 1)

            if (foto.id) {
                await firebase.firestore().collection("photosLanding").doc(foto.id).delete()
            }
            this.setState({ photosLanding: photosLandingCopy, loading: false })
        }
    }

    handleInputFoto = (file, index) => {
        if (index) {
            index = index - 1
            let photosLandingCopy = this.state.photosLanding.map(el => ({ ...el }))
            photosLandingCopy[index] = file
            this.setState({ photosLanding: photosLandingCopy, adminModalOpen: false, fileAEditar: undefined })
        }
        else
            this.setState({ photosLanding: [...this.state.photosLanding, file], adminModalOpen: false, fileAEditar: undefined })
    }

    getVideo = (url) =>
        <video id="myVideo" width={240} controls >
            <source src={url} type="video/mp4" />
            <source src={url} type="video/webm" />
            <source src={url} type="video/ogg" />
            Your browser does not support HTML video.
        </video>

    handleClick = (event) => {
        this.hiddenFileInput.current.click();
    };

    render() {
        return (
            <React.Fragment>
                <div className="content-wrapper p-5">
                    <div className="row">

                        <div className="col-md-12">
                            <h1 className="mb-0">Photos landing page</h1>
                            <h3 className="mb-5">Administrar</h3>

                            <div className="row justify-content-center mb-4 mt-5">
                                <div className="col-md-10" >
                                    <h3>Foto landing!</h3>
                                    <label>Photos...</label><br />
                                </div>
                                <div className="col-md-10" >
                                    <div className="border border-dark p-2">
                                        <DataTableComponent data={this.getPhotosLandingData()} />
                                    </div>
                                </div>

                                <div className="col-md-10" >
                                    <button type="button" className="btn btn-primary resource-align resource-margin-small mb-5" onClick={() => this.setState({ adminModalOpen: true })}>Agregar foto para el landing page</button>
                                </div>
                            </div>

                            {this.state.adminModalOpen && <AdministrarFotoLandingModal show cancel={() => this.setState({ adminModalOpen: false, fileAEditar: undefined })}
                                guardar={this.handleInputFoto} fileAEditar={this.state.fileAEditar} onHide={() => this.setState({ adminModalOpen: false, fileAEditar: undefined })} />}

                            <div className="form-group">
                                <label>Tiempo de exposición (segundos)</label>
                                <input name="tiempo" onChange={(e) => this.setState({ tiempo: e.target.value })} value={this.state.tiempo} type="number" className="form-control" />
                            </div>

                            <div className="btn-row" >
                                <button type="button" className="btn btn-primary" onClick={() => this.props.history.goBack()}>Cancelar</button>
                                <button type="submit" className="btn btn-primary" onClick={this.subirPhotos}>Guardar</button>
                            </div>
                        </div>
                    </div>
                </div>
                <Spinner show={this.state.loading} />

            </React.Fragment >
        );
    }
}
AdminPhotosLanding.contextType = CurrentUser;
export default AdminPhotosLanding;