import React, { Component } from 'react';
import DeleteAlertCompoment from '../core/DeleteAlertComponent';
import Alert from 'react-bootstrap/Alert';
import Form from 'react-bootstrap/Form';
import { deleteTag, getTag, saveTagData } from '../../../service/procedures';
import CurrentUser from '../../public/core/PublicUserSessionContext';
import { REQUIRED_INPUT_MESSAGE } from '../../../service/constants';

class TagFormComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tagID: this.props.match.params.id,
            editing: this.props.match.params.id ? true : false,
            tag: {
                name: ""
            },
            errors: {},
            modalShow: false,

        };
    }

    componentDidMount() {
        this.getInitialData()
    }
    getInitialData = async () => {
        const { tagID, editing } = this.state
        if (editing) {
            this.context.iniciaCarga()
            let tag = await getTag(tagID)
            this.setState({ tag })
            this.context.terminaCarga()
        }
    }

    handleInputChange = (event) => {
        const { name, value } = event.target
        const tag = this.state.tag;
        tag[name] = value;
        this.setState({ tag, errors: {} });
    }

    handleFormSubmit = async (event) => {
        event.preventDefault();
        if (this.validateErrors()) {
            this.context.iniciaCarga()
            const { tagID, tag } = this.state

            await saveTagData(tagID, tag)
            this.context.terminaCarga()
            this.goToTagsPage()
        }
    }

    validateErrors = () => {
        let errors = {}
        const required = ["name"]

        required.forEach(field => {
            if (!this.state.tag[field])
                errors[field] = REQUIRED_INPUT_MESSAGE
        })

        this.setState({ errors })
        return Object.keys(errors).length === 0
    }

    deleteTag = async () => {
        this.context.iniciaCarga()
        await deleteTag(this.state.tagID)
        this.context.terminaCarga()
        this.goToTagsPage()
    }
    goToTagsPage = () => this.props.history.push("/admin/tags")

    render() {
        let modalClose = () => this.setState({ modalShow: false });
        let modalOpen = () => this.setState({ modalShow: true });

        const { editing, tag, errors, modalShow } = this.state
        return (
            <div className="content-wrapper p-5">
                <div className="row">
                    <div className="col-md-12">
                        <h1 className="mb-0">Etiquetas</h1>
                        <h3 className="mb-5">Nueva etiqueta</h3>
                        <Form onSubmit={this.handleFormSubmit} >
                            <div className="row justify-content-center mb-4">
                                <div className="col-md-10">
                                    <div className="form-container ">
                                        <div className="form-wrapper">
                                            <div className="form-group">
                                                <label>Nombre</label>
                                                <input onChange={this.handleInputChange} type="text" name="name" value={tag.name} className="form-control" />
                                                <Alert show={!!errors.name} variant="danger">{errors.name}</Alert>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="btn-row"  >
                                {editing && <button type="button" onClick={modalOpen} className="btn btn-primary">Eliminar</button>}
                                <button type="button" onClick={this.goToTagsPage} className="btn btn-primary">Cancelar</button>
                                <button type="submit" className="btn btn-primary" >Guardar</button>
                            </div>
                        </Form>
                    </div>
                </div>
                <DeleteAlertCompoment show={modalShow} onHide={modalClose} deleteElement={this.deleteTag} />
            </div>
        );
    }
}
TagFormComponent.contextType = CurrentUser;
export default TagFormComponent;