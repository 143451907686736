import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import Alert from 'react-bootstrap/Alert';
import firebase from 'firebase';
import MaskedFormControl from 'react-bootstrap-maskedinput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPlayCircle } from '@fortawesome/free-solid-svg-icons';

var moment = require("moment");
moment.locale("es");

class VideoComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    currentTime = (time) => {
        const vid = document.getElementById("video");
        const timeParts = time.split(":");
        let seconds = (timeParts[0] * 3600) + (timeParts[1] * 60) + (parseInt(timeParts[2]));
        vid.currentTime = seconds;
    }

    render() {
        if (!this.props.video){
            return null}
        else
            return (
                <section className="video-page">
                    <div className="container ">
                        <div name="video ">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="main-title my-3">
                                            <h1 className="video-title mb-0">
                                                {this.props.video.title || this.props.video.name}
                                            </h1>
                                        </div>
                                    </div>
                                </div>

                                <div className="row mt-4">
                                    <div className="col-md-12">
                                        <p className="h4">Descripción de la cirugía</p>
                                        {this.props.video.description && this.props.video.description.split("\n").map(parrafo => <p>{parrafo}</p>)}
                                        {this.props.video.detail && this.props.video.detail.split("\n").map(parrafo => <p>{parrafo}</p>)}
                                    </div>
                                </div>

                                {this.props.video.times && this.props.video.times.length > 0 && <div className="row mt-4">
                                    <div className="col-md-12" name="marcador">
                                        <p className="h4" >Secciones navegables</p>
                                        <ul className="list-unstyled pl-3 mt-3">
                                            {Array.isArray(this.props.video.times) && this.props.video.times.map((time, index) => {
                                                return (
                                                    <li key={index}>
                                                        <p onClick={(e) => this.currentTime(time.time)}
                                                            className={"link-no-btn"} >
                                                            <span className="mr-2"><FontAwesomeIcon icon={faPlayCircle} /></span>
                                                            {time.name}
                                                        </p>
                                                    </li>
                                                )
                                            })
                                            }
                                        </ul>
                                    </div>
                                </div>}
                                <div className="videoplayer-container h-100">
                                    <video onContextMenu={e => e.preventDefault()} id="video" className="w-100 h-100" controls src={this.props.video.url || this.props.video.video}
                                        controls controlsList="nodownload" onEnded={() => this.props.setVideoCompleted(this.props.video.id, this.props.video.index)}></video>
                                </div>

                                {!this.props.video.procedureId && <div className=" filter-sidebar">
                                    <span className="h4">Imagenes descargables</span>
                                    <ul className="list-unstyled pl-3  mt-3">

                                        {this.props.video.images.map(image => {
                                            return <li className={"Gratis active mb-2"}><a href={image.url}  target="_blank" rel="noopener noreferrer">{image.title}</a></li>

                                        })}
                                    </ul>
                                </div>}
                                {!this.props.video.procedureId && <div className=" filter-sidebar">
                                    <span className="h4">PDFS descargables</span>
                                    <ul className="list-unstyled pl-3 mt-3">

                                        {this.props.video.pdfs.map(pdf => {
                                            return <li className={"Gratis active mb-2"}><a href={pdf.url} target="_blank" rel="noopener noreferrer">{pdf.title}</a></li>

                                        })}
                                    </ul>
                                </div>}
                                {this.props.video.procedureId && this.props.video.fascicle && <div className=" filter-sidebar">
                                    <span className="h4">PDF descargable</span>
                                    <ul className="list-unstyled pl-3 mt-3">
                                        <li className={"Gratis active"}><a href={this.props.video.fascicle}  target="_blank" rel="noopener noreferrer">Guía - {this.props.video.name}</a></li>
                                    </ul>
                                </div>}

                            </div>


                        </div>
                    </div>
                </section>
            )
    }
}
export default VideoComponent;