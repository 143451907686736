import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';

class DeleteAlertComponent extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <Modal show={this.props.show} onHide={this.props.onHide} size="md" aria-labelledby="contained-modal-title-vcenter" centered >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <h5 className="modal-title" id="modalTerminoLabel">Alerta</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Este proceso no tiene forma de revertirse, ¿Aún desea eliminar este elemento?</p>
                </Modal.Body>
                <Modal.Footer>
                    <button onClick={this.props.onHide} className="btn btn-primary " >Cancelar</button>
                    <button onClick={this.props.deleteElement} className="btn btn-primary " >Continuar</button>
                </Modal.Footer>
            </Modal>
        );
    }
}
export default DeleteAlertComponent;