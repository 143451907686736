import firebase from 'firebase';
import { uploadFileToStorage } from './storage';

export async function getAulasVirtuales() {
    let response = await firebase.firestore().collection("aulas").get().catch(error => console.log(`Error obtener aulas: ${JSON.stringify(error)}`));
    let aulas = response.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    return aulas
}
export async function getAulaVirtual(aulaID) {
    let aula = await firebase.firestore().collection("aulas").doc(aulaID).get().catch(error => console.log(`Error obtener datos del aula: ${JSON.stringify(error)}`));
    return aula.data()
}

export async function changeAulaActiveStatus(aulaID, active) {
    await firebase.firestore().collection("aulas").doc(aulaID).update({ active, users: [] }).catch(error => console.log(`Error al cambiar estatus: ${JSON.stringify(error)}`));
}
export async function saveAulaData(aulaID, data) {
    aulaID = aulaID || firebase.firestore().collection("aulas").doc().id
    await firebase.firestore().collection("aulas").doc(aulaID).set(data, { merge: true }).catch(error => console.log(`Error al guardar aula: ${JSON.stringify(error)}`));
}

export async function deleteAula(aulaID) {
    await firebase.firestore().collection('aulas').doc(aulaID).delete().catch(error => console.log(`Error al borrar aula: ${JSON.stringify(error)}`));
    await deleteAulaFiles(aulaID)
}
async function deleteAulaFiles(aulaID) {
    let storageRef = firebase.storage().ref()
    var imageRef = storageRef.child(`aulas/${aulaID}/Preview-Image`);
    var pdfRef = storageRef.child(`aulas/${aulaID}/Preview-pdf`);

    await imageRef.delete()
    await pdfRef.delete()
}

export async function uploadPreviewPdf(aulaID, previewPdfFile, setState) {
    return await uploadFileToStorage("aulas", aulaID, "preview-pdf", previewPdfFile, "PDF previa", setState)
}
export async function uploadPreviewImage(aulaID, previewImageFile, setState) {
    return await uploadFileToStorage("aulas", aulaID, "preview-image", previewImageFile, "imagen previa", setState)
}