import React,{Component} from "react";
import Pagination from 'react-bootstrap/Pagination';

class PaginationComponent extends Component {
    constructor(props){
        super(props);
    }

    createElements = () => {
        
        const pagination = this.props.pagination;console.log("-------------------Paginador",pagination);
        const paginator = [];
        const hveBefore = pagination.actualPage > 4;
        const init = hveBefore? pagination.actualPage - 2: 1;
        const hveAfter = pagination.numberPages > pagination.actualPage + 3;
        const limit = hveAfter ?  pagination.actualPage + 2: pagination.numberPages;
        if(hveBefore){
            
            paginator.push(<Pagination.Item onClick={e => this.props.handlePagination(1)}>{1}</Pagination.Item>);
            paginator.push(<Pagination.Ellipsis disabled/>);
        }
        for(let index = init; index <= limit; index++){
            console.log("index",index);
            paginator.push(<Pagination.Item active={index===pagination.actualPage} onClick={e => this.props.handlePagination(index)}>{index}</Pagination.Item>);
        }
        if(hveAfter){
            paginator.push(<Pagination.Ellipsis  disabled/>);
            paginator.push(<Pagination.Item onClick={e => this.props.handlePagination(pagination.numberPages)}>{pagination.numberPages}</Pagination.Item>);
            
        }
        return paginator;
    }

    render(){
        const pagination = this.props.pagination;
        return (
            <Pagination>
                <Pagination.First disabled={pagination.actualPage === 1} onClick={e => this.props.handlePagination(1)} />
                <Pagination.Prev disabled={pagination.actualPage === 1} onClick={e => this.props.handlePagination(pagination.actualPage-1)}/>
                {this.createElements()}
                <Pagination.Next disabled={pagination.actualPage === pagination.numberPages} onClick={e => this.props.handlePagination(pagination.actualPage+1)}/>
                <Pagination.Last disabled={pagination.actualPage === pagination.numberPages} onClick={e => this.props.handlePagination(pagination.numberPages)}/>
            </Pagination>
        );
    }
}
export default PaginationComponent;
