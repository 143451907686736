import React, { Component } from 'react';
import firebase from 'firebase';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';

import DataTableComponent from '../../admin/core/DataTableComponent';
import CurrentUser from "../core/PublicUserSessionContext";
import CKEditor from '@ckeditor/ckeditor5-react';
import Spinner from "../../commons/Spinner";
import UploadFileAlertComponent from '../../admin/core/UploadFileAlertComponent';
import DatePicker from 'react-date-picker';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import Carousel from 'react-simply-carousel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlayCircle } from '@fortawesome/free-solid-svg-icons'
import AulaItemComponent from './AulaItemComponent'
import { faSearch } from '@fortawesome/free-solid-svg-icons';

import ExpirationWarningModal from './ExpirationWarningModal';

class AulaPageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      procedures: [],
      logoImageURL: "",
      title: "",

      organization: { id: "", logoImageURL: "", promoImageURL: "", promoLink: "", title: "", private: false },
      allOrganizations: [],

      species: [],
      speciesFilter: "Todos",

      allTags: [],
      tagFilter: "Todos",
      expirationModalOpen: false,
      dateExpiration: -1,
      aulaId: this.props.match.params.id
    }
  }


  componentDidMount = async () => {
    const db = firebase.firestore();
    let currentUser = this.context.currentUser;

    let procedures = []
    let species = []
    let allTags = []

    try{

      let aula = await db.collection('aulas').doc(this.state.aulaId).get()

    if (!aula.data().active || !aula.data().users.includes(currentUser.id))
      this.props.history.push('/mis-aulas')
    else {
      for (let i = 0; i < aula.data().videos.length; i++) {
        let procedure = await db.collection('procedures').doc(aula.data().videos[i]).get()
        if (!species.includes(procedure.data().kynd)) {
          species.push(procedure.data().kynd)
        }


        let tags = []
        let tagIds = Object.keys(procedure.data().tags)

        for (let j = 0; j < tagIds.length; j++) {
          let tag = await db.collection('tags').doc(tagIds[j]).get()
          tags.push(tag.data().name)

          if (!allTags.includes(tag.data().name)) {
            allTags.push(tag.data().name)
          }
        }

        procedures.push({ id: procedure.id, ...procedure.data(), tags })

      }

      let organization = await db.collection('organizations').doc(aula.data().organization).get()
      this.setState({ ...aula.data(), procedures, organization: { id: organization.id, ...organization.data() }, species, allTags, loading: false }, () => {
        this.checkExpirationWarnings(aula.data().dateExpiration, aula.data().threeDayWarning, aula.data().tenDayWarning)
      })
    }

    }catch(error){

      this.props.history.push('/mis-aulas')
    }
    
  }

  checkExpirationWarnings = (dateExpiration, threeDayWarning = [], tenDayWarning = []) => {
    let currentUser = this.context.currentUser;

    if ((dateExpiration - Date.now()) / 86400000 < 3) {

      if (!threeDayWarning.includes(currentUser.id)) {
        this.setState({ expirationModalOpen: true })

        let threeDayWarningCopy = threeDayWarning
        threeDayWarning.push(currentUser.id)

        firebase.firestore().collection('aulas').doc(this.props.match.params.id).update({ threeDayWarning: threeDayWarningCopy }).then(aula => {
        }).catch(error => {
          console.log(error)
        })
      }

    }
    else if ((dateExpiration - Date.now()) / 86400000 < 10) {

      if (!tenDayWarning.includes(currentUser.id)) {
        this.setState({ expirationModalOpen: true })

        let tenDayWarningCopy = tenDayWarning
        tenDayWarning.push(currentUser.id)

        firebase.firestore().collection('aulas').doc(this.props.match.params.id).update({ tenDayWarning: tenDayWarningCopy }).then(aula => {
        }).catch(error => {
          console.log(error)
        })
      }

    }
  }

  render() {
    let orgTitle = this.state.organization.title ? (this.state.organization.title + " - " + this.state.title) : ""
    let currentUser = this.context.currentUser;

    return (
      <section className="video-grid">
        <div className="container">

          {this.state.organization.private && <a href={"https://" + this.state.organization.promoLink} target="_blank"> <section className="about-intro intro bg-secondary aula-banner" style={{ backgroundImage: `url(${this.state.organization.promoImageURL})` }} >
          </section></a>}


          <div className="row ">
            <div className="col-md-12">

              <h1 className="main-title">
                {orgTitle}
              </h1>

              <div className="video-title-bar d-md-flex justify-content-between">

                <h1 className="main-title">
                  Cirugías
                </h1>

                <div>
                  <div className="mb-3 p-0 row no-gutters align-items-center">
                    <div className="col">
                      {//<input onChange={this.searchByName} className="form-control form-control-borderless" type="search" placeholder="Buscar en catálogo" />
                      }
                    </div>
                    <div className="col-auto pl-3 pr-3">
                      <a>
                        {//<FontAwesomeIcon icon={faSearch} />
                        }
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">


                <div className="col-lg-3 col-md-4 col-sm-5 col-12" >
                  <img className="img-fluid  mb-2 preview" src={this.state.organization.logoImageURL} />
                  <div className=" filter-sidebar">

                    <span className="h4">Filtros</span>
                    <p >Especie</p>
                    <ul className="list-unstyled pl-3">
                      {this.state.species.map((kynd, index) => {
                        return <li className={kynd === this.state.speciesFilter ? "Gratis active" : "Gratis"}><button onClick={() => this.setState({ speciesFilter: kynd })}>{kynd}</button></li>
                      })}
                      <li className={this.state.speciesFilter === "Todos" ? "Gratis active" : "Gratis"}><button onClick={() => this.setState({ speciesFilter: "Todos" })}>Todos</button></li>

                    </ul>

                    <p >Tags</p>
                    <ul className="list-unstyled pl-3">
                      {this.state.allTags.map((tag, index) => {
                        return <li className={tag === this.state.tagFilter ? "Gratis active" : "Gratis"}><button onClick={() => this.setState({ tagFilter: tag })}>{tag}</button></li>
                      })}
                      <li className={this.state.tagFilter === "Todos" ? "Gratis active" : "Gratis"}><button onClick={() => this.setState({ tagFilter: "Todos" })}>Todos</button></li>

                    </ul>

                  </div>
                </div>


                <div className="col-lg-9 col-md-8 col-sm-7 col-12" >

                  <div className="row">
                    {
                      this.state.procedures.map(procedure => {

                        if ((this.state.speciesFilter === "Todos" || procedure.kynd === this.state.speciesFilter) && (this.state.tagFilter === "Todos" || procedure.tags.includes(this.state.tagFilter)))
                          return <AulaItemComponent key={procedure.id} previewImageURL={procedure.preview} title={procedure.name} description={procedure.detail} link={`/procedimiento/${procedure.id}`} />

                      })
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Spinner show={this.state.loading} />

        {this.state.dateExpiration > 0 && <ExpirationWarningModal show={this.state.expirationModalOpen} onHide={() => this.setState({ expirationModalOpen: false })} dateExpiration={this.state.dateExpiration} aulaId={this.state.aulaId} />}
      </section>
    );
  }
}
AulaPageComponent.contextType = CurrentUser;
export default AulaPageComponent