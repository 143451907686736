import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import Alert from 'react-bootstrap/Alert';
import DataTableComponent from '../../admin/core/DataTableComponent';
import firebase from 'firebase';

var moment = require("moment");
moment.locale("es");

class UsuariosModalAula extends Component {
    constructor(props) {
        super(props);

        this.state = {
            users: [],
            aulaId: this.props.aulaId,
            users: [],
            aulaUsers: this.props.aulaUsers,
            organization: this.props.organization,
            typeOrganization: this.props.typeOrganization,
        }
    }

    componentDidMount = () => {
        const db = firebase.firestore();

        //get users
        db.collection('users').orderBy("email", "asc").get().then((usersColl) => {
            let users = usersColl.docs.map((doc) => { return { id: doc.id, ...doc.data() } });
            this.setState({ users,  })

        }).catch(error => console.log(error));

    }

    handleUsersCheck = (event) => {
        let aulaUsersCopy = this.state.aulaUsers
        const index = aulaUsersCopy.indexOf(event.target.name);

        if (index > -1) {
            aulaUsersCopy.splice(index, 1);
        }
        else {
            aulaUsersCopy.push(event.target.name)
        }
        this.setState({ aulaUsers: aulaUsersCopy })
    }

    getUserColumns = () => {
        return [
            { label: 'Agregar', field: 'select', sort: 'asc' },
            { label: 'Nombre', field: 'name', sort: 'asc' },
            { label: 'Correo', field: 'email', sort: 'asc' },
            { label: 'Membresía', field: 'type', sort: 'asc' },
        ];
    }
    getUserData = (aulaUsers) => {
        const rows = [];

        this.state.users.map(user => {
            if (user.university === this.state.organization  || this.state.typeOrganization !== "universidad") {
                rows.push({
                    select: <div className="form-check form-check-inline">
                        <input name={user.id} checked={(this.state.aulaUsers.includes(user.id)) ? true : false} className="form-check-input" onChange={this.handleUsersCheck} type="checkbox" />
                    </div>,
                    name: user.name,
                    email: user.email,
                    type: user.type,
                });
            }
        });
        return { columns: this.getUserColumns(), rows };
    }

    saveUsers = () => {
        const db = firebase.firestore();

        db.collection("aulas").doc(this.state.aulaId).set({
            users: this.state.aulaUsers,
        }, { merge: true }).then(() => {
            this.props.onHide()
        })
    }

    render() {

        return (
            <Modal {...this.props} size="lg" className="progress-modal" centered >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <h5 className="modal-title" id="modalTerminoLabel">Administrar Usuarios</h5>
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <p className="text-center mb-0">Usuarios</p>
                    <DataTableComponent data={this.getUserData(this.props.aulaUsers)} />
                </Modal.Body>

                <Modal.Footer >
                    <button role="button" className="btn btn-primary" onClick={this.props.onHide}>Cerrar</button>
                    <button role="button"  className="btn btn-primary" onClick={this.saveUsers}>Guardar</button>
                </Modal.Footer>
            </Modal>
        );
    }
}
export default UsuariosModalAula;