import React, { Component } from 'react';
import firebase from 'firebase';
import CurrentUser from "../../public/core/PublicUserSessionContext";
import Spinner from "../../commons/Spinner";
import DataTableComponent from '../core/DataTableComponent';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import ExitosoModal from './ExitosoModal'
import ErrorModal from './ErrorModal'

class PremiumManual extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            email: "",
            uid: "",
            planAnual: false,
            allUsers: [],
            errors: [],
            showExitosoModal: false,
            showErrorModal: false
        }
    }

    componentDidMount = () => {
        this.getAllUsers()
    }
    getAllUsers = () => {
        firebase.firestore().collection('users').where("type", "==", "Gratis").get().then((snapshot) => {
            let allUsers = snapshot.docs.map((doc) => { return { id: doc.id, ...doc.data() } });
            this.setState({ allUsers, loading: false });

        }).catch(error => {
            console.log(error)
            this.setState({ loading: false, showErrorModal: true })
        });
    }
    getUserColumns = () => {
        return [
            { label: 'Nombre', field: 'name', sort: 'asc' },
            { label: 'Correo', field: 'email', sort: 'asc' },
            { label: 'Membresía', field: 'type', sort: 'asc' },
        ];
    }
    getUserData = () => {
        const rows = [];

        this.state.allUsers.map(user => {
            rows.push({
                name: user.name || "",
                email: user.email || "",
                type: user.type || "",
                clickEvent: () => this.setState({ email: user.email, uid: user.id, errors: [] })
            });
        });
        return { columns: this.getUserColumns(), rows: rows };
    }

    actualizarPremium = () => {
        let usersCollection = firebase.firestore().collection('users')
        this.setState({ loading: true })
        let data = {
            paymentDay: Date.now(),
            subscriptionId: "MANUAL",
            hasPremiumAnual: this.state.planAnual,
            type: "Premium"
        }

        if (this.checkForErrors() === 0) {

            usersCollection.doc(this.state.uid).update(data).then((snapshot) => {
                this.setState({ showExitosoModal: true })
                this.getAllUsers()
            }).catch(error => {
                console.log(error)
                this.setState({ loading: false, showErrorModal: true })
            });
        }
        else
            this.setState({ loading: false })

    }

    checkForErrors = () => {
        let errors = []
        const required = ["email"]

        required.forEach(field => {
            if (this.state[field] === "" || this.state[field] === undefined || this.state[field] === null)
                errors.push(field)
        })

        this.setState({ errors })
        return errors.length
    }

    render() {
        return (
            <React.Fragment>
                <Form onSubmit={this.submitAula}>
                    <div className="content-wrapper p-5">
                        <div className="row">

                            <div className="col-md-12">
                                <h1 className="mb-0">Actulizar premium</h1>
                                <h3 className="mb-5">Manual</h3>
                                <div className="row justify-content-center mb-4">
                                    <div className="col-md-10">
                                        <div className="form-container">
                                            <div className="row">
                                                <div className="col-md-12">

                                                    <div className="form-group">
                                                        <h1>Usuarios</h1>
                                                        <DataTableComponent data={this.getUserData()} />
                                                    </div>

                                                    <div className="form-group">
                                                        <label>Usuario correo</label>
                                                        <input disabled name="email" value={this.state.email} type="text" className="form-control" />
                                                        {this.state.errors.includes("email") && <Alert variant="danger"> Este campo es requerido.</Alert>}
                                                    </div>

                                                    <div className="form-group">
                                                        <label>Seleccionar tipo suscripción</label>
                                                        <select name="typeDiplomado" onChange={(e) => { this.setState({ planAnual: e.target.value === "year" }) }} value={this.state.planAnual ? "year" : "month"} className="form-control">
                                                            <option value="month">1 Mes</option>
                                                            <option value="year">1 Año</option>
                                                        </select>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="btn-row" >
                                    <button type="button" className="btn btn-primary" onClick={() => this.props.history.goBack()}>Cancelar</button>,
                                    <button type="button" className="btn btn-primary" onClick={() => this.actualizarPremium()}>Actualizar premium manual</button>,
                                </div>

                                <Spinner show={this.state.loading} />

                            </div>
                        </div>
                    </div>
                    <ExitosoModal show={this.state.showExitosoModal} onHide={() => this.setState({ showExitosoModal: false, email: "", uid: "", planAnual: false })} periodo={this.state.planAnual ? "1 año" : "1 mes"} />
                    <ErrorModal show={this.state.showErrorModal} onHide={() => this.setState({ showErrorModal: false, email: "", uid: "", planAnual: false })} />
                </Form>
            </React.Fragment>
        );
    }
}
PremiumManual.contextType = CurrentUser;
export default PremiumManual;