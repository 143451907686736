export const [EMPRESA, UNIVERSIDAD, EXTERNAL, CXVET] = ["Empresa", "Universidad", "External", "CxVet"]
export const ORGANIZATION_TYPES = [EMPRESA, UNIVERSIDAD]
export const DIPLOMADO_TYPES = [EXTERNAL, CXVET]

export const [GUEST, GRATIS, PREMIUM, LEGACY, ADMIN] = ["Gest", "Gratis", "Premium", "Legacy", "Admin"];
export const USER_TYPES_BASIC = [GRATIS, PREMIUM];
export const USER_TYPES_ADMIN = [GRATIS, PREMIUM, ADMIN];
export const USER_TYPES_PREMIUM = [PREMIUM, ADMIN];
export const USER_TYPES_ALL = [GUEST, GRATIS, PREMIUM, LEGACY, ADMIN];

export const CXVET_VIDEO_TYPES = USER_TYPES_BASIC
export const SPECIES_TYPES = ["Perro", "Gato", "Otras especies"];

export const ACCEPTED_IMAGES = "image/jpeg,image/jpg,image/png"
export const ACCEPTED_VIDEOS = "video/ogg,video/mp4,video/webm"
export const ACCEPTED_FILES = "application/pdf"
export const IMAGE_TYPES = ACCEPTED_IMAGES.split(",")
export const VIDEO_TYPES = ACCEPTED_VIDEOS.split(",")
export const FILE_TYPES = ACCEPTED_FILES.split(",")

export const REQUIRED_INPUT_MESSAGE = "Debes llenar este campo.";
export const REQUIRED_SELECT_MESSAGE = "Debes seleccionar un valor";
export const VALID_FORMAT_MESSAGE = "Introduce un formato válido."

export const [ALL_USERS, ONLY_PREMIUM, ONLY_GRATIS, ONLY_NOT_REGISTERED] = ["Todos los usuarios", "Solo Premium", "Solo básicos", "Solo No Registrados"]
export const ANALYTICS_USER_FILTER_OPTIONS = [ALL_USERS, ONLY_PREMIUM, ONLY_GRATIS, ONLY_NOT_REGISTERED];
export const ANALYTICS_USER_FILTER_MAP = {
    [ONLY_PREMIUM]: PREMIUM,
    [ONLY_GRATIS]: GRATIS,
    [ONLY_NOT_REGISTERED]: GUEST
}