import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
class ModalConfirmacion extends Component {
    render() {
        return <Modal show={this.props.show} onHide={this.props.cancelar}>
            <Modal.Header>Aviso de cambio de membresía</Modal.Header>

            <Modal.Body>
                <p>Al cambiar tu cuenta Premium a Básica perderás los beneficios de esta membresía a partir del último día de tu periodo de pago. </p>
                <p>¿Seguro de que deseas realizar este cambio?</p>
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-primary" onClick={this.props.cancelar}>Cancelar</button>
                <button className="btn btn-primary" onClick={this.props.confirmar} >Cambiar a Básica</button>
            </Modal.Footer>
        </Modal>
    }
}
export default ModalConfirmacion