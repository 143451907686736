import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';

var moment = require("moment");
moment.locale("es");

class EliminarModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
        }
    }

    componentDidMount = () => {
       
    }

    render() {
        return (
            <Modal {...this.props} size="lg" className="progress-modal" centered >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <h5 className="modal-title" id="modalTerminoLabel">Diplomado activado</h5>
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <p  className="mb-0">Este diplomado se ha activado correctamente.</p>
                </Modal.Body>

                <Modal.Footer >
                    <button role="button" className="btn btn-primary" onClick={this.props.onHide}>Cancelar</button>
                </Modal.Footer>
            </Modal>
        );
    }
}
export default EliminarModal;