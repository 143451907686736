const countries = [
"Afganistan",
"Africa Central",
"Albania",
"Alemania",
"Andorra",
"Angola",
"Anguilla",
"Antartida",
"Antigua y Barbuda",
"Arabia Saudita",
"Argelia",
"Argentina",
"Armenia",
"Aruba",
"Australia",
"Austria",
"Azerbaiyan",
"Bahamas",
"Bahrein",
"Bangladesh",
"Barbados",
"Bielorrusia",
"Belgica",
"Belice",
"Benin",
"Bermuda",
"Butan",
"Bolivia",
"Bosnia y Herzegovina",
"Botswana",
"Brasil",
"Brunei",
"Bulgaria",
"Burkina Faso",
"Burundi",
"Camboya",
"Camerun",
"Canada",
"Cabo Verde",
"Ciudad del Vaticano",
"Chad",
"Chile",
"China",
"Colombia",
"Comoras",
"Congo",
"Corea del Norte",
"Corea del Sur",
"Costa Rica",
"Costa de Marfil",
"Croacia",
"Cuba",
"Chipre",
"Dinamarca",
"Dominica",
"Ecuador",
"Egipto",
"El Salvador",
"Emiratos Arabes",
"Eritrea",
"Eslovaquia",
"Eslovenia",
"España",
"Estados Unidos",
"Estonia",
"Etiopia",
"Fiji",
"Filipinas",
"Finlandia",
"Francia",
"Gabon",
"Gambia",
"Georgia",
"Ghana",
"Gibraltar",
"Grecia",
"Granada",
"Groenlandia",
"Guadalupe",
"Guam",
"Guatemala",
"Guinea",
"Guinea-bissau",
"Guinea Ecuatorial",
"Guyana",
"Guyana Francesa",
"Haiti",
"Holanda",
"Honduras",
"Hong Kong",
"Hungria",
"India",
"Indonesia",
"Iran",
"Irak",
"Irlanda",
"Isla Bouvet",
"Isla de Coco",
"Isla de Navidad",
"Isla de Pascua",
"Isla Norfolk",
"Islandia",
"Islas Cayman",
"Islas Cook",
"Islas Feroe",
"Islas Heard y McDonald",
"Islas Malvinas",
"Islas Marianas del Norte",
"Islas Marshall",
"Islas Pitcairn",
"Islas Salomon",
"Islas Turcas y Caicos",
"Islas Virgenes",
"Israel",
"Italia",
"Jamaica",
"Japon",
"Jordania",
"Kazakhstan",
"Kenia",
"Kiribati",
"Kosovo",
"Kuwait",
"Kirguistan",
"Laos",
"Letonia",
"Libano",
"Lesoto",
"Liberia",
"Libia",
"Liechtenstein",
"Lituania",
"Luxemburgo",
"Macao",
"Macedonia",
"Madagascar",
"Malawi",
"Malasia",
"Maldivas",
"Mali",
"Malta",
"Martinica",
"Mauritania",
"Mauricio",
"Mayotte",
"México",
"Micronesia",
"Moldavia",
"Monaco",
"Mongolia",
"Montenegro",
"Montserrat",
"Marruecos",
"Mozambique",
"Myanmar",
"Namibia",
"Nauru",
"Nepal",
"Nicaragua",
"Niger",
"Nigeria",
"Niue",
"Noruega",
"Nueva Caledonia",
"Nueva Zelanda",
"Oman",
"Pakistan",
"Palau",
"Palestina",
"Panama",
"Papua Nueva Guinea",
"Paraguay",
"Peru",
"Polinesia Francesa",
"Polonia",
"Portugal",
"Puerto Rico",
"Qatar",
"Republica Checa",
"Republica Dominicana",
"Reino Unido",
"Reunion",
"Rumania",
"Rusia",
"Ruanda",
"Sahara Occidental",
"Samoa",
"Santa Helena",
"San Cristobal y Nieves",
"Santa Lucia",
"San Pedro y Miguelon",
"San Vicente y las Granadinas",
"Samoa",
"San Marino",
"Santo Tome y Principe",
"Senegal",
"Serbia y Montenegro",
"Seychelles",
"Sierra Leona",
"Singapur",
"Siria",
"Somalia",
"Sri Lanka",
"Sudafrica",
"Sudan",
"Surinam",
"Suazilandia",
"Suecia",
"Suiza",
"Taiwan",
"Tayikistan",
"Tanzania",
"Tailandia",
"Timor Oriental",
"Togo",
"Tokelau",
"Tonga",
"Trinidad y Tobago",
"Tunez",
"Turquia",
"Turkey",
"Turkmenistan",
"Tuvalu",
"Uganda",
"Ukrania",
"Uruguay",
"Uzbekistan",
"Vanuatu",
"Venezuela",
"Vietnam",
"Wallis y Futuna",
"Yemen",
"Yibuti",
"Zambia",
"Zimbaue",
"Otro"];

export {countries};