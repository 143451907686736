import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';


class ExitosoModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
        }
    }

    componentDidMount = () => {

    }

    render() {
        return (
            <Modal {...this.props} size="lg" className="progress-modal" centered >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <h5 className="modal-title" id="modalTerminoLabel">Cambio exitoso</h5>
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    {
                        this.props.downgrade ? <p className="mb-0">Premium se eliminó correctamente de este usuario.</p>
                            : <p className="mb-0">Este usuario se actualizó con éxito manualmente a una prima durante {this.props.periodo}.</p>
                    }
                </Modal.Body>

                <Modal.Footer >
                    <button role="button" className="btn btn-primary" onClick={this.props.onHide}>Cerrar</button>
                </Modal.Footer>
            </Modal>
        );
    }
}
export default ExitosoModal;