import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import Alert from 'react-bootstrap/Alert';
import DataTableComponent from '../../core/DataTableComponent';
import firebase from 'firebase';
import MaskedFormControl from 'react-bootstrap-maskedinput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

var moment = require("moment");
moment.locale("es");

class ExamEditModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            title: "",
            questions: [{ question: "", answers: [], correctAnswer: 0, userAnswers: {} }],
        }
    }

    componentDidMount = () => {

    }


    render() {
        let exam = this.props.exam || {title: "", questions: [{ question: "", answers: [], correctAnswer: 0 }],}

        return (
            <Modal {...this.props} size="lg" className="progress-modal" centered >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <h5 className="modal-title" id="modalTerminoLabel">Editar Exámen</h5>
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>

                    <div className="form-group">
                        <h1>Exámen</h1>
                        <input className="w-50 mb-5" placeholder="Título" onChange={this.props.handleChangeTitle} value={exam.title}/>

                        <div className="mb-3">
                            {exam.questions.map((question, index) => {
                                return <div >

                                    <div className="mb-3">
                                        <input placeholder={`Pregunta ` + (index+1)} className="height-pregunta width-exam-modal" onChange={(e) => this.props.handleChangeQuestion(e, index)} value={question.question}/>
                                        <button type="button" className="btn btn-primary ml-3" onClick={() => this.props.addAnswer(index)} >Agregar Respuesta</button>

                                        <a  className="text-light btn btn-primary ml-3 " onClick={(e) => this.props.deleteQuestion(index)}>
                                            <FontAwesomeIcon icon={faTrash} />
                                        </a>
                                    </div>

                                    <div >
                                    {question.answers.map((answer, indexA) => {
                                        return <div className="mb-2 ml-4 ">
                                            <input checked={question.correctAnswer === indexA} onChange={(e) => this.props.handleCheck(index, indexA)} className="form-check-input" type="checkbox" />
                                            <input placeholder={`Respuesta ` + (indexA+1)} className="width-exam-respuesta" onChange={(e) => this.props.handleChangeAnswer(e, index, indexA)} value={answer}>
                                            </input>

                                            <a  className="text-light btn btn-primary ml-3" onClick={(e) => this.props.deleteAnswer(index, indexA)}>
                                                <FontAwesomeIcon icon={faTrash} />
                                            </a>
                                        </div>
                                    })}
                                        </div>

                                    <br /> <br />
                                </div>
                            })}
                        </div>

                        <button type="button" className="btn btn-primary" onClick={this.props.addQuestion} >Agregar Pregunta</button>

                    </div>

                </Modal.Body>

                <Modal.Footer >
                    <button type="button" className="btn btn-primary" onClick={this.props.cancel} >Cancelar</button>
                    <button type="button" className="btn btn-primary" onClick={this.props.cancel}>Guardar</button>
                </Modal.Footer>
            </Modal>
        );
    }
}
export default ExamEditModal;