import React, { Component } from 'react';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import ModalVideo from 'react-modal-video'
class EventItemComponent extends Component {
    constructor(props) {
        super(props);
        this.state = { isOpen: false }
    }
    render() {
        const event = this.props.event;
        return (
            <div className="evento-wrapper">
                <div className="font-weight-bold evento-fecha h2">{event.date}</div>
                <div className="row justify-content-center">
                    <div className="col-md-12">
                        <div className="tarjeta-bold mt-3 p-3">

                            <img className="evento-img img-fluid" src={event.image} />

                            <h2 className="evento-name h3 mt-3 mb-0">{event.name}</h2>
                            <div className="evento-tipo font-italic">{event.type}</div>
                            <div className="evento-content">
                                {ReactHtmlParser(event.detail)}
                            </div>

                            <div className="btn-row">
                                {event.pdf &&
                                    <a className="btn-block  btn btn-secondary mt-1" target="_blank" href={event.pdf}>
                                        Ver información completa
                                    </a>}
                                {event.pdfPrograma &&
                                    <a className="btn-block  btn btn-secondary mt-1" target="_blank" href={event.pdfPrograma}>
                                        Ver programa
                                    </a>}

                                {event.videoPromo &&
                                    <button className="btn btn-secondary btn-block mt-1" onClick={() => this.setState({ isOpen: true })}>Ver video</button>}
                                <ModalVideo channel="youtube" isOpen={this.state.isOpen} onClose={() => this.setState({ isOpen: false })}
                                    videoId={event.videoPromo}>
                                </ModalVideo>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default EventItemComponent;