import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import Alert from 'react-bootstrap/Alert';
import DataTableComponent from '../../core/DataTableComponent';
import firebase from 'firebase';
import MaskedFormControl from 'react-bootstrap-maskedinput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

var moment = require("moment");
moment.locale("es");

class CirugiasModal extends Component {
    constructor(props) {
        super(props);

        this.state = {

        }
    }

    componentDidMount = () => {

    }


    render() {
        return (
            <Modal {...this.props} size="xl" className="progress-modal" centered >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <h5 className="modal-title" id="modalTerminoLabel">Administrar Videos De La Plataforma</h5>
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                <div className="row justify-content-center mb-4">
                        <div className="col-md-10">
                    <div className="form-group">
                        {//<h1>Cirugías</h1>
                        }
                        <label>{this.props.videos.length}{this.props.videos.length === 1 ? " video seleccionado" : " videos seleccionados"}</label><br />
                        <DataTableComponent data={this.props.getVideoData()} />
                    </div>
                    </div>
                    </div>
                </Modal.Body>

                <Modal.Footer >
                    <button type="button" className="btn btn-primary" onClick={this.props.cancel} >Cancelar</button>
                    <button type="button" className="btn btn-primary" onClick={this.props.cancel}>Guardar</button>
                </Modal.Footer>
            </Modal>
        );
    }
}
export default CirugiasModal;