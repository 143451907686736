import React,{Component} from 'react';

import iconCheck from '../../../resources/images/icon-check.svg';
import iconTache from '../../../resources/images/icon-tache.svg';

class SelectTypeComponent extends Component{
    constructor(props){
        super(props);
        this.state = {newUser : this.props.newUser, premiumAnual: false};

    }

    continue = (newUserType, premiumAnual=false) => {
        const newUser = this.state.newUser;
        newUser.type = (newUserType===1)?"Gratis":"Premium";
        newUser.processStep = 2;
        newUser.hasPremiumAnual = premiumAnual

        this.props.setAnual(premiumAnual);
        this.props.updateNewUser(newUser);

    }

    render(){
        return(
            <section className="register-page">
			    <div className="container">
				    <div className="row">
					    <div className="container">
                            <h1 className="">
                            Selecciona tu membresía
                            </h1>
                            <h2 className="subtitle mb-5">
                            Puedes cambiar de tipo de membresía en cualquier momento
                            </h2>
                            <div className="row justify-content-center ">
                                <div className="col-md-10 plans">
                                    <ul className="step flex-nowrap mb-5  d-none d-sm-flex">
                                        <li className="step-item active">
                                            <a>
                                            <button type="button" className="link-no-btn" >Tipo de membresía</button>
                                            </a>
                                        </li>
                                        <li class="step-item ">
                                            <a className="link-no-btn" >Información personal</a>
                                        </li>
                                        <li class="step-item  ">
                                            <a className="link-no-btn" >Método de pago</a>
                                        </li>
                                    </ul>
                                    <div class="table-responsive">

                                        <table className="table table-striped table-borderless">
                                          <thead className="b-0">
                                              <tr>
                                                  <th>
                                                      
                                                  </th>
                                                  <th>
                                                      Básica
                                                  </th>
                                                  <th>
                                                      Premium (mensual)
                                                  </th>
                                                  <th>
                                                      Premium (anual)
                                                  </th>
                                              </tr>
                                          </thead>
                                          <tbody>
                                              
                                              <tr>

                                                  <td>
                                                      Precio mensual
                                                  </td>
                                                  <td>
                                                      <span className="d-sm-none">Básica </span>Gratis
                                                  </td>
                                                  <td>
                                                      <span className="d-sm-none">Premium (mensual) </span>$9.99 USD/mes
                                                  </td>
                                                  <td>
                                                      <span className="d-sm-none">Premium (anual) </span>$99.90 USD/año 
                                                  </td>
                                                  
                                              </tr>
                                              
                                              <tr >
                                                  <td>
                                                      Acceso al catálogo de procedimientos quirúrgicos gratuitos

                                                  </td>
                                                  <td>
                                                      <span className="d-sm-none">Básica </span><img src={iconCheck} width='20'/>
                                                  </td>
                                                  <td>
                                                      <span className="d-sm-none">Premium (mensual)</span><img src={iconCheck} width='20'/>
                                                  </td>
                                                  <td className="text-center">
                                                      <span className="d-sm-none">Premium (anual)</span><img src={iconCheck} width='20'/>
                                                  </td>
                                                  
                                              </tr>
                                              
                                              <tr >
                                                  <td>
                                                      Acceso al catálogo completo de procedimientos quirúrgicos

                                                  </td>
                                                  <td>
                                                      <span className="d-sm-none">Básica </span><img src={iconTache} width='16'/>
                                                  </td>
                                                  <td>
                                                      <span className="d-sm-none">Premium (mensual)</span><img src={iconCheck} width='20'/>
                                                  </td>
                                                  <td className="text-center">
                                                      <span className="d-sm-none">Premium (anual)</span><img src={iconCheck} width='20'/>
                                                  </td>
                                                  
                                              </tr>
                                              
                                              <tr >
                                                  <td>
                                                      Descarga catálogo completo de guías en PDF


                                                  </td>
                                                  <td>
                                                      <span className="d-sm-none">Básica </span><img src={iconTache} width='16'/>
                                                  </td>
                                                  <td>
                                                      <span className="d-sm-none">Premium (mensual)</span><img src={iconCheck} width='20'/>
                                                  </td>
                                                  <td className="text-center">
                                                      <span className="d-sm-none">Premium (anual)</span><img src={iconCheck} width='20'/>
                                                  </td>
                                                  
                                              </tr>
                                              
                                              <tr >
                                                  <td>
                                                      Descuentos especiales en cursos y talleres
                                                  </td>
                                                  <td>
                                                      <span className="d-sm-none">Básica </span><img src={iconTache} width='16'/>
                                                  </td>
                                                  <td>
                                                      <span className="d-sm-none">Premium (mensual)</span><img src={iconCheck} width='20'/>
                                                  </td>
                                                  <td className="text-center">
                                                      <span className="d-sm-none">Premium (anual)</span><img src={iconCheck} width='20'/>
                                                  </td>
                                                  
                                              </tr>
                                              
                                              <tr>
                                                  <td>
                                                      Entérate siempre de los próximos eventos
                                                  </td>
                                                  <td>
                                                      <span className="d-sm-none">Básica </span><img src={iconCheck} width='20'/>
                                                  </td>
                                                  <td>
                                                      <span className="d-sm-none">Premium (mensual)</span><img src={iconCheck} width='20'/>
                                                  </td>
                                                  <td className="text-center">
                                                      <span className="d-sm-none">Premium (anual)</span><img src={iconCheck} width='20'/>
                                                  </td>
                                                  
                                              </tr>
                                              <tr>
                                                <td >
                                                  <span className="d d-sm-none">
                                                    Elige la membresía que te interese
                                                    </span>
                                                </td>
                                                <td>
                                                    <button onClick={(e) => this.continue(1)} className="btn btn-secondary btn-block height-type" ><span className="d-none d-sm-block">Seleccionar </span>  Básica</button>
                                                </td>
                                                <td>
                                                    <button onClick={(e) => this.continue(2)} className="btn btn-secondary btn-block height-type" ><span className="d-none d-sm-block">Seleccionar </span>Premium (mensual)</button>
                                                </td>
                                                <td>
                                                    <button onClick={(e) => this.continue(2,true)} className="btn btn-secondary btn-block height-type" ><span className="d-none d-sm-block">Seleccionar </span>Premium (anual)</button>
                                                </td>
                                            </tr>
                                              
                                          </tbody>
                                      </table>

                                
                              
                                            
                                        
                                    </div>
					            </div>
				            </div>
						</div>
					</div>
				</div>
	        </section>
        );
    }

}

export default SelectTypeComponent;