import React, { Component } from 'react';
import firebase from 'firebase';
import EventItemComponent from './EventItemComponent';

class EventsComponent extends Component {

    constructor() {
        super();
        this.state = { events: [] };
    }

    componentWillMount = () => {
        firebase.firestore().collection('events').where("status", "==", "Activo").orderBy("order").get().then(response => {
            const events = response.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
            this.setState({ events });
        }).catch(error => console.log(error));
    }

    render() {
        const events = this.state.events;
        return (
            <section className="faq-page">
                <div className="container">
                    <div className="row  ">
                        <div className="col-md-12">
                            <h1 className="main-title">
                                Agenda próximos eventos CxVet
                            </h1>
                            <div className="row justify-content-center">
                                <div className="col-md-10">
                                    {events.length === 0 ?
                                        <div>No hay eventos próximos</div>
                                        :
                                        events.map(event => <EventItemComponent key={event.id} event={event} />)
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
export default EventsComponent;