import React, { Component } from "react";
import firebase from 'firebase';
import CurrentUser from '../public/core/PublicUserSessionContext';
import Dotdotdot from 'react-dotdotdot';

import fotoSlogan from '../../resources/images/fotos/cirugia1.jpg';
import iconCatalogo from '../../resources/images/icon-catalogo.svg';
import iconGuias from '../../resources/images/icon-guias.svg';
import iconComunidad from '../../resources/images/icon-comunidad.svg';
import iconDescuentos from '../../resources/images/icon-descuentos.svg';
import iconUniversidad from '../../resources/images/university.png';
import iconCheck from '../../resources/images/icon-check.svg';
import iconTache from '../../resources/images/icon-tache.svg';
import avalConcervet from '../../resources/images/logos/concervet.jpg';
import avalMeso from '../../resources/images/secive_cliente.jpeg';
import avalCVT from '../../resources/images/logos/instituto cvt.jpeg';
import avalAmhvet from '../../resources/images/logos/avalAmhvet.jpg'
import Slider from "./Slider";
import SliderLanding from "./SliderLanding";

var moment = require("moment");
moment.locale("es");
class PublicLandingPage extends Component {
    constructor() {
        super();
        this.state = {
            activeSlideIndex: 0,
            photosLanding: [],
            procedures: []
        };
    }

    componentWillMount = () => {
        firebase.firestore().collection("procedures").limit(3).get().then(response => {
            const procedures = response.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            this.setState({ procedures });
        });

        //get photos
        firebase.firestore().collection("photosLanding").orderBy("orden", "asc").get().then(response => {
            let photosLanding = response.docs.map(doc => ({ id: doc.id, ...doc.data() }))
            this.setState({ photosLanding })
        })
    }

    render() {
        let currentUser = this.context.currentUser;
        console.log("RENDER LANDING");
        console.log(currentUser);
        console.log(this.context);
        return (
            <React.Fragment>
                {this.state.photosLanding.length > 1 ?
                    <SliderLanding user={currentUser.type} photosLanding={this.state.photosLanding} />
                    :
                    <section className="slogan home intro justify-content-center align-items-center d-flex " style={{ backgroundImage: `url(${fotoSlogan})` }} >
                        <div className="container  ">
                            <div className="row text-light text-center ">
                                <div className="col-12 ">
                                    <p className="h1 font-weight-bold mb-2 ">Un cirujano siempre contigo </p>
                                    <p className="mb-3 text">Prepárate para tu próxima cirugía con el mejor <strong>catálogo de videos</strong>, guías y toda nuestra <strong>experiencia y soporte </strong> para resolver tus dudas</p>
                                    {currentUser.type == "Gest" && <a href="/registro" className="btn btn-light btn-round btn-lg ">Unirte ahora</a>}
                                </div>
                            </div>
                        </div>
                    </section>
                }
                <section className="features home">
                    <div className="container ">
                        <div className="row justify-content-center">
                            <div className="col-md-8">

                                <h2 className="section-title text-center mb-5">Por qué unirte a CxVet</h2>
                                <div className=" row mb-3 align-items-center justify-content-center">
                                    <div className="col-12 col-sm-auto mb-3">
                                        <img alt="" src={iconCatalogo} className="img-fluid" />
                                    </div>
                                    <div className="col">
                                        <h3>Extenso catálogo de procedimientos quirúrgicos en video</h3>
                                        <p>Contamos con un extenso catálogo de procedimientos quirúrgicos en video y guías en PDF en continuo crecimiento con las cirugías más buscadas y necesarias</p>
                                    </div>
                                </div>

                                <div className=" row mb-3 align-items-center justify-content-center">
                                    <div className="col-12 col-sm-auto mb-3">
                                        <img alt="" src={iconGuias} className="img-fluid" />
                                    </div>
                                    <div className="col">
                                        <h3>Guías en PDF resumidas y listas para imprimir.</h3>
                                        <p>Descarga los PDF de las cirugías y llévalos contigo o compártelas con tus colegas.</p>
                                    </div>
                                </div>

                                <div className=" row mb-3 align-items-center justify-content-center">
                                    <div className="col-12 col-sm-auto mb-3">
                                        <img alt="" src={iconComunidad} className="img-fluid" />
                                    </div>
                                    <div className="col">
                                        <h3>Comunidad de colegas</h3>
                                        <p>No solo somos un sitio de videos, sino una comunidad en expansión de colegas que formamos una red de apoyo y colaboración mundial</p>
                                    </div>
                                </div>
                                <div className=" row mb-3 align-items-center justify-content-center">
                                    <div className="col-12 col-sm-auto mb-3">
                                        <img alt="" src={iconDescuentos} className="img-fluid" />
                                    </div>
                                    <div className="col">
                                        <h3> Descuentos en eventos y talleres</h3>
                                        <p>Al ser parte de la comunidad activa de Cirugía Veterinaria recibirás promociones para participar en los talleres y cursos que organizamos</p>
                                    </div>
                                </div>
                                <div className=" row mb-3 align-items-center justify-content-center">
                                    <div className="col-12 col-sm-auto mb-3">
                                        <img alt="" src={iconUniversidad} className="img-fluid" />
                                    </div>
                                    <div className="col">
                                        <h3>Institución de cirugía veterinaria</h3>
                                        <p>Estamos orgullosos de ser socios de esta institución, <a target="_blank" href="https://institutocirugiaveterinaria.com/" rel="noreferrer">visite su sitio web aquí</a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="logos1 home">
                    <div className="container home">
                        <div className="row">
                            <div className="col-md-12">
                                <h3 className="text-center section-title mb-5">
                                    Avalados por
                                </h3>
                                <div className="d-flex justify-content-center">
                                    <span><img src={avalConcervet} /></span>
                                    <span><img src={avalMeso} /></span>
                                    <span><img src={avalCVT} /></span>
                                    <span><img src={avalAmhvet} /></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="videos bg-light home">
                    <div className="container home">
                        <div className="row ">
                            <div className="col-md-12">
                                <p className="text-center  mb-5 h2">Conoce un poco de nuestro catálogo de procedimientos quirúrgicos</p>
                                <div className="row justify-content-center">
                                    {this.state.procedures.map((procedure,index) => {
                                        const type = currentUser.type === "Premium" ? "" : procedure.type === "Gratis" ? "free" : "premium";
                                        const videoType = `video ${type}`;
                                        const url = `/procedimiento/${procedure.id}`;
                                        const stylePreview = { backgroundImage: `url(${procedure.preview})` };
                                        return (
                                            <div key={index} className="col-lg-4 col-md-6 video-container d-flex">
                                                <div className={"flex-fill " + videoType} >
                                                    <a href={url}>
                                                        <div className="video-thumb" style={stylePreview} />

                                                        <div className="video-content">
                                                            <h3>{procedure.name}</h3>
                                                            <p className="procedure_detail">
                                                                <Dotdotdot clamp={3}>
                                                                    {procedure.detail}
                                                                </Dotdotdot>
                                                            </p>
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                                <div className="text-center">
                                    <a href="/procedimientos" className="btn-secondary mt-5 btn-lg btn btn-round ">
                                        EXPLORA TODO NUESTRO CATÁLOGO
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="plans home">
                    <div className="container home">
                        <div className="row justify-content-center">
                            <div className="col-md-12 ">
                                <h3 className="text-center section-title mb-5">
                                    Nuestras membresías
                                </h3>
                                <div className="table-responsive-sm">
                                    <table className="table table-striped table-borderless d-none d-md-block">
                                        <thead className="b-0">
                                            <tr>
                                                <th></th>
                                                <th>Básica</th>
                                                <th>Premium (mensual)</th>
                                                <th>Premium (anual)</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Precio mensual</td>
                                                <td>
                                                    <span className="d-sm-none">Básica </span>Gratis
                                                </td>
                                                <td>
                                                    <span className="d-sm-none">Premium (mensual) </span>$9.99 USD/mes
                                                </td>
                                                <td>
                                                    <span className="d-sm-none">Premium (anual) </span>$99.90 USD/año
                                                </td>
                                            </tr>
                                            <tr >
                                                <td>Acceso al catálogo de procedimientos quirúrgicos gratuitos</td>
                                                <td>
                                                    <span className="d-sm-none">Básica </span><img src={iconCheck} width='20' />
                                                </td>
                                                <td>
                                                    <span className="d-sm-none">Premium (mensual)</span><img src={iconCheck} width='20' />
                                                </td>
                                                <td className="text-center">
                                                    <span className="d-sm-none">Premium (anual)</span><img src={iconCheck} width='20' />
                                                </td>
                                            </tr>
                                            <tr >
                                                <td>Acceso al catálogo completo de procedimientos quirúrgicos</td>
                                                <td>
                                                    <span className="d-sm-none">Básica </span><img src={iconTache} width='16' />
                                                </td>
                                                <td>
                                                    <span className="d-sm-none">Premium (mensual)</span><img src={iconCheck} width='20' />
                                                </td>
                                                <td className="text-center">
                                                    <span className="d-sm-none">Premium (anual)</span><img src={iconCheck} width='20' />
                                                </td>
                                            </tr>
                                            <tr >
                                                <td>Descarga catálogo completo de guías en PDF</td>
                                                <td>
                                                    <span className="d-sm-none">Básica </span><img src={iconTache} width='16' />
                                                </td>
                                                <td>
                                                    <span className="d-sm-none">Premium (mensual)</span><img src={iconCheck} width='20' />
                                                </td>
                                                <td className="text-center">
                                                    <span className="d-sm-none">Premium (anual)</span><img src={iconCheck} width='20' />
                                                </td>
                                            </tr>
                                            <tr >
                                                <td>Descuentos especiales en cursos y talleres</td>
                                                <td>
                                                    <span className="d-sm-none">Básica </span><img src={iconTache} width='16' />
                                                </td>
                                                <td>
                                                    <span className="d-sm-none">Premium (mensual)</span><img src={iconCheck} width='20' />
                                                </td>
                                                <td className="text-center">
                                                    <span className="d-sm-none">Premium (anual)</span><img src={iconCheck} width='20' />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Entérate siempre de los próximos eventos</td>
                                                <td>
                                                    <span className="d-sm-none">Básica </span><img src={iconCheck} width='20' />
                                                </td>
                                                <td>
                                                    <span className="d-sm-none">Premium (mensual)</span><img src={iconCheck} width='20' />
                                                </td>
                                                <td className="text-center">
                                                    <span className="d-sm-none">Premium (anual)</span><img src={iconCheck} width='20' />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    
                                    <table className="mb-3 table table-sm table-striped table-borderless justify-self-center d-md-none">
                                        <tbody>
                                            <tr>
                                                <td className="w-50">Precio</td>
                                                <td className="w-50 align-self-center">
                                                    <span>Básica </span>Gratis
                                                </td>
                                            </tr>
                                            <tr >
                                                <td className="w-50">Acceso al catálogo de procedimientos quirúrgicos gratuitos</td>
                                                <td className="w-50 align-self-center">
                                                    <img src={iconCheck} width='20'/>
                                                </td>
                                            </tr>
                                            <tr >
                                                <td className="w-50 h6">Acceso al catálogo completo de procedimientos quirúrgicos</td>
                                                <td className="w-50 align-self-center">
                                                    <img src={iconTache} width='16' />
                                                </td>
                                            </tr>
                                            <tr >
                                                <td className="w-50">Descarga catálogo completo de guías en PDF</td>
                                                <td className="w-50 align-self-center">
                                                    <img src={iconTache} width='16' />
                                                </td>
                                            </tr>
                                            <tr >
                                                <td className="w-50">Descuentos especiales en cursos y talleres</td>
                                                <td className="w-50 align-self-center">
                                                    <img src={iconTache} width='16' />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="w-50">Entérate siempre de los próximos eventos</td>
                                                <td className="w-50 align-self-center">
                                                    <img src={iconCheck} width='20' />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <hr className="d-md-none"/>
                                    <table className="mb-3 table table-sm table-striped table-borderless d-md-none justify-self-center">
                                        <tbody>
                                            <tr>
                                                <td className="w-50">Precio</td>
                                                <td className="w-50 align-self-center">
                                                    Premium 
                                                    <p>(mensual) $9.99 USD/mes</p>
                                                    <p>(anual) $99.90 USD/año</p>
                                                </td>
                                            </tr>
                                            <tr >
                                                <td className="w-50">Acceso al catálogo de procedimientos quirúrgicos gratuitos</td>
                                                <td className="w-50 align-self-center">
                                                    <img src={iconCheck} width='20'/>
                                                </td>
                                            </tr>
                                            <tr >
                                                <td className="w-50 h6">Acceso al catálogo completo de procedimientos quirúrgicos</td>
                                                <td className="w-50 align-self-center">
                                                    <img src={iconCheck} width='20' />
                                                </td>
                                            </tr>
                                            <tr >
                                                <td className="w-50">Descarga catálogo completo de guías en PDF</td>
                                                <td className="w-50 align-self-center">
                                                    <img src={iconCheck} width='20' />
                                                </td>
                                            </tr>
                                            <tr >
                                                <td className="w-50">Descuentos especiales en cursos y talleres</td>
                                                <td className="w-50 align-self-center">
                                                    <img src={iconCheck} width='20' />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="w-50">Entérate siempre de los próximos eventos</td>
                                                <td className="w-50 align-self-center">
                                                    <img src={iconCheck} width='20' />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="text-center">
                                    {currentUser.type == "Gest" && <a href="/registro" className="text-center btn-secondary mt-5 btn-lg btn btn-round ">
                                        UNIRTE AHORA
                                    </a>}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="quotes home">
                    <div className="container home">
                        <div className="row">
                            <div className="col-md-12">
                                <h3 className="text-center section-title mb-5">
                                    Nuestros usuarios opinan
                                </h3>
                                <div className="row">
                                    <div className="col-md-4">
                                        <blockquote className="blockquote">
                                            <p className="mb-0">La organización es excelente!!!! el trato súper cálido y personalizado, nivel académico de excelencia!!!</p>
                                            <div className="mt-4">
                                                <strong className="d-block">M.V.Z. Horte C. Monjaras</strong>
                                            </div>
                                        </blockquote>
                                    </div>
                                    <div className="col-md-4">
                                        <blockquote className="blockquote">
                                            <p className="mb-0">Excelentes docentes y buen material didáctico</p>
                                            <div className="mt-4">
                                                <strong className="d-block">M.V.Z. Diego Andrés Mojica Zúñiga</strong>
                                            </div>
                                        </blockquote>
                                    </div>
                                    <div className="col-md-4">
                                        <blockquote className="blockquote">
                                            <p className="mb-0">Muy especial para actualizarnos y ser mejor profesionales cada día.</p>
                                            <div className="mt-4">
                                                <strong className="d-block">M.V.Z. Ricardo W. Miranda Gonzáles </strong>
                                            </div>
                                        </blockquote>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="sponsors home">
                    <div className="container ">
                        <div className="row">
                            <div className="col-md-12 carousel-container">
                                <h3 className="section-title mb-5">
                                    Con el respaldo de
                                </h3>
                                <div className="carousel-container">
                                    <Slider />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}
PublicLandingPage.contextType = CurrentUser;
export default PublicLandingPage;