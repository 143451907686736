import React, { Component } from 'react';
import firebase from 'firebase';

import CurrentUser from "../core/PublicUserSessionContext";
import Spinner from "../../commons/Spinner";
import DiplomadoItemComponent from './DiplomadoItemComponent';

import logoBlanco from '../../../resources/images/logo-blanco.png'

class DiplomadoGridComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,

      allOrganizations: [{ id: "CxVet", title: "CxVet", logoImageURL: logoBlanco, diplomados: [] }],
      selectedOrganizationIndex: 0,
      logoImageURL: "",
    }
  }

  componentDidMount = () => {
    const db = firebase.firestore();
    let allOrganizations = [{ id: "CxVet", title: "CxVet", logoImageURL: logoBlanco, diplomados: [] }]

    //get organizations
    db.collection('organizations').get().then((organizations) => {

      organizations.docs.forEach(organization => { allOrganizations.push({ id: organization.id, ...organization.data(), diplomados: [] }) })

      //get diplomados
      db.collection('diplomados').get().then((diplomados) => {

        diplomados.docs.forEach(diplomado => {
          if (diplomado.data().active && diplomado.data().title !== "Diplomado-Adam-Test") {
            //If the diplomado is from CxVet, push it to index 0, otherwise find the index of this diplomados 'organization' field
            let indexOfOrganization = allOrganizations.findIndex(org => org.id === diplomado.data().organization)
            allOrganizations[indexOfOrganization] = { ...allOrganizations[indexOfOrganization], diplomados: [...allOrganizations[indexOfOrganization].diplomados, { id: diplomado.id, ...diplomado.data() }] }
          }
        })

        let initialSelectedIndex = allOrganizations.findIndex(org => org.diplomados.length > 0)
        this.setState({ allOrganizations, selectedOrganizationIndex: initialSelectedIndex, loading: false })
      }).catch(error => {
        console.log(error)
        this.setState({ loading: false })
      });

    }).catch(error => {
      console.log(error)
      this.setState({ loading: false })
    });

  }

  hasPushedOrganization = (organizationID, allOrganizations) => {
    let hasPushed = false
    allOrganizations.forEach(organization => {
      if (organization.id === organizationID)
        hasPushed = true
    })

    return hasPushed
  }

  render() {
    let selectedOrganization = this.state.allOrganizations[this.state.selectedOrganizationIndex] || {diplomados: []}
    selectedOrganization.diplomados.sort((a, b)=>{
      return b.created - a.created
    })
    let currentUser = this.context.currentUser;

    return (
      <section className="video-grid">
        <div className="container">
          <div className="row ">
            <div className="col-md-12">
              <div className="video-title-bar d-md-flex justify-content-between">

                <h1 className="main-title">
                  Mis Diplomados
                </h1>

                <div>
                  <div className="mb-3 p-0 row no-gutters align-items-center">
                    <div className="col">
                      {//<input onChange={this.searchByName} className="form-control form-control-borderless" type="search" placeholder="Buscar en catálogo" />
                      }
                    </div>
                    <div className="col-auto pl-3 pr-3">
                      <a>
                        {//<FontAwesomeIcon icon={faSearch} />
                        }
                      </a>
                    </div>
                  </div>
                </div>

              </div>

              <div className="row">

                <div className="col-lg-3 col-md-4 col-sm-5 col-12" >
                {!this.state.loading && <img className="img-fluid  mb-2 preview" src={selectedOrganization.logoImageURL || ""} />}
                  {!this.state.loading && <h1 className="main-title">
                    {selectedOrganization.title || ""}
                  </h1>}
                  <div className=" filter-sidebar">

                    <span className="h4">Organizaciones</span>
                    <p >Todas</p>

                    <ul className="list-unstyled pl-3">
                      {this.state.allOrganizations.map((organization, index) => {
                        if (organization.diplomados.length > 0)
                          return <li key={organization.diplomados.id} className={index === this.state.selectedOrganizationIndex ? "Gratis active" : "Gratis"}><button onClick={() => this.setState({ selectedOrganizationIndex: index })}>{organization.title}</button></li>
                      })}
                    </ul>

                  </div>
                </div>

                <div className="col-lg-9 col-md-8 col-sm-7 col-12" >
                  <div className="row">
                    {selectedOrganization.diplomados.map(diplomado => {
                      return <DiplomadoItemComponent key={diplomado.id} previewImageURL={diplomado.previewImageURL} title={diplomado.title} description={diplomado.description} link={diplomado.users.includes(currentUser.id) ? (diplomado.external ? `/mis-diplomados/diplomado/${diplomado.id}` : `/mis-diplomados/diplomado-CxVet/${diplomado.id}`) : `/mis-diplomados/diplomado-preview/${diplomado.id}`} />
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Spinner show={this.state.loading} />

      </section>
    );
  }
}
DiplomadoGridComponent.contextType = CurrentUser;
export default DiplomadoGridComponent