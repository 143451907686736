import React,{Component} from 'react';
import Modal from 'react-bootstrap/Modal';

class ModalTermsAndConditionsComponent extends Component {
    constructor(props){
        super(props);
    }

    render() {
        return (
            <Modal {...this.props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered className="terms">
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <h5 className="modal-title" id="modalTerminoLabel">Términos y Condiciones</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                                      <p><strong>Procedimientos Quirúrgicos</strong></p>
                                      <ol>
                                            
                                            <li >Los videos de cirugía disponibles en <a class="a_cgv" href="http://cirugiaveterinaria.mx/">cirugiaveterinaria.mx</a> no se podrán copiar ni bajar a su computadora personal.</li>
                                            <li >Los videos de cirugía veterinaria se podrán ver directamente a través de la red de internet, por lo cual la empresa <a class="a_cgv" href="http://cirugiaveterinaria.mx/">cirugiaveterinaria.mx</a> no se hace responsable por ningún problema que pudiera tener o presentar su servicio local de Internet.</li>
                                            <li>La membresía Básica no tiene ningún costo ni tendrá a futuro</li>
                                            <li>Al registrarse con una membresía Básica, el usuario tiene libertad de acceder a los videos y fasciculos gratuitos que se encuentren en el sitio.</li>
                                            <li >En caso de registrarse con membresía Premium,tendrá acceso libre para consultar cualquier video o guía en PDF que desee por el lapso de un año a partir del momento que se realice su pago.</li>
                                            <li >Una cuenta en <a class="a_cgv" href="http://cirugiaveterinaria.mx/">cirugiaveterinaria.mx</a>, es  personal e intransferible, si se detecta que se está haciendo mal uso de ella será bloqueada y podrá ser reestablecida únicamente por el usuario original.</li>
                                        </ol>
                                    </div>
                                    <div class="contenido_parrafo">
                                        <p class="subtitulos"><strong>Guías en PDF</strong></p>
                                        <ol>
                                            <li >Cada video cuenta con un suplemento grafico llamado Guía en PDF el cual se encuentra junto con el video correspondiente.</li>
                                            <li >Cada guía en PDF puede ser accedido dependiendo de su membresía actual y permite su descarga a su computadora personal.</li>
                                           
                                            <li >La impresión del guía en PDF es responsabilidad del usuario que lo descargue.</li>
                                            <li >Todas las imágenes incluidas en los videos y guía en PDFs están protegidas y son propiedad de <a class="a_cgv" href="http://cirugiaveterinaria.mx/">cirugiaveterinaria.mx</a></li>
                                        </ol>
                                    </div>
                                      
                                    <div class="contenido_parrafo">
                                        <p class="subtitulos"><strong>Advertencias y Aclaraciones Videos </strong></p>
                                        <ol>
                                            <li >Los videos de procedimientos quirúrgicos de <a class="a_cgv" href="http://cirugiaveterinaria.mx/">cirugiaveterinaria.mx</a> contienen imágenes de cirugía que pueden ser fuertes para algunos espectadores. Si es fácilmente impresionable evite verlo.</li>
                                            <li >Todos los casos clínicos presentados son reales, ningún animal fue sacrificado ni maltratado.</li>
                                            <li >Cada caso que aquí se presenta se realizó bajo completa autorización de su propietario.</li>
                                            <li >Las imágenes anatómicas fueron realizadas en animales que fallecieron por causas naturales y bajo autorización del propietario para realizar la necropsia.</li>
                                            <li >Todos los videos y escritos están registrados y protegidos bajo los “Derechos de Autor” ante el “Instituto Nacional del Derecho de Autor (INDAUTOR)”.</li>
                                            <li >Esta estrictamente prohibido copiar, vender o comercializar parcial o totalmente cualquier video o escrito que aquí se encuentre</li>
                                            <li >Apoya a todos los autores de esta obra, adquiriendo los productos originales.</li>
                                            <li >Queda estrictamente prohibida la reproducción total o parcial de los contenidos e imágenes de ningún video sin previa autorización por escrito del edito. Las imágenes y los procedimientos en este material son responsabilidad de sus propios autores.</li>
                                            <li >Estos videos tienen por objeto la difusión y transmisión de diversos documentos que contienen información académica y práctica de cirugía veterinaria, encaminada a reforzar conocimientos adquiridos de los Profesionales del ámbito Veterinario, médicos veterinarios y estudiantes de la materia así como gente interesada en el tema.</li>
                                            <li >Todo este trabajo audiovisual antes mencionado ha sido cargado en el sitio web <a class="a_cgv" href="http://cirugiaveterinaria.mx/">cirugiaveterinaria.mx</a>, con la intención de comercializar los videos antes mencionados.</li>
                                        </ol>
                                        
                                    </div>

                                    <div class="contenido_parrafo">
                                        <p class="subtitulos"><strong>Información científica</strong></p>
                                        <ol>
                                            <li >La medicina es una ciencia sometida a un cambio constante. A medida que la investigación y la experiencia clínica amplían nuestros conocimientos, son necesarios cambios en los tratamientos, técnicas quirúrgicas y fármaco terapia.</li>
                                            <li >Estamos en constante actualización.</li>
                                            <li >Los Autores y editores de esta obra han contrastados sus resultados con fuentes consideradas de confianza, en un esfuerzo por proporcionar información completa y general, de acuerdo con los criterios aceptados en el momento de la publicación. Sin embargo debido a la posibilidad de que existan errores humanos en que existan cambios en las ciencias médicas, ni los autores, ni los editores, ni cualquier otra fuente implicada en la preparación o publicación de esta obra, garantiza que la información contenida en la misma sea exacta y completa en todos los aspectos,&nbsp; ni son responsables de los errores u omisiones ni de los resultados derivados del empleo de dicha información.</li>
                                        </ol>
                                        <p>&nbsp;</p>
                                    </div>
                </Modal.Body>
              
            </Modal>
        );
    }
}
export default ModalTermsAndConditionsComponent;