import React, { Component } from 'react';
import firebase from 'firebase';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import CurrentUser from "../../public/core/PublicUserSessionContext";
import { ACCEPTED_IMAGES } from '../../../service/constants';
import { getNewsletterImage, saveNewsletterData, uploadNewsletterImage } from '../../../service/newsletter';
class NewsletterComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            imageURL: "",
            originalImageURL: "",
            srrorAlert: false,
            succesAlert: false,
        }
    }
    componentDidMount() {
        this.getInitialData()
    }
    getInitialData = async () => {
        this.context.iniciaCarga()
        let newsletter = await getNewsletterImage()
        this.setState({ imageURL: newsletter.imagen, originalImageURL: newsletter.imagen })
        this.context.terminaCarga()
    }

    handleFileChange = (event) => {
        const { name, id, files, file = files[0], typeURL = name, typeFile = id } = event.target
        let url = URL.createObjectURL(file)
        this.setState({ [typeURL]: url, [typeFile]: file, errors: {} })
    }

    handleFormSubmit = async (event) => {
        event.preventDefault();
        this.context.iniciaCarga();
        const { imageFile, imageURL } = this.state

        try {

            let imagen = imageFile ? await uploadNewsletterImage(imageFile) : imageURL
            await saveNewsletterData({ imagen })
            this.setState({ succesAlert: true, originalImageURL: imagen });
        } catch (error) {

            console.log(`Error al guardar newsletter: ${JSON.stringify(error)}`);
            this.setState({ errorAlert: true });
        }
        this.context.terminaCarga();
    }

    render() {
        const cancelar = () => { this.setState({ imageURL: this.state.originalImageURL, errorAlert: false, succesAlert: false }) }
        const state = this.state
        return (
            <div className="content-wrapper p-5">
                <div className="row">
                    <div className="col-md-12">
                        <h1 className="mb-5">Configurar imagen principal de Newsletter</h1>
                        <Alert show={state.errorAlert} variant="warning">No se pudo cambiar la imagen</Alert>
                        <Alert show={state.succesAlert} variant="success"> Se cambió la imagen exitosamente.</Alert>

                        <Form onSubmit={this.handleFormSubmit}>
                            <div className="row justify-content-center mb-4">
                                <div className="col-md-8">
                                    <div className="form-group">
                                        <label>Cambiar imagen</label>
                                        <input type="file" className="form-control-file" id="imageFile" name="imageURL" onChange={this.handleFileChange} accept={ACCEPTED_IMAGES} />
                                        <small class="form-text text-muted">La imagen debe tener dimensiones mínimas de 800px x 220px</small>
                                    </div>
                                    <label>Vista previa de imagen seleccionada</label>
                                    <img className="img-fluid  mb-2 preview" src={state.imageURL} id="previewImg" />
                                </div>

                                {state.imageURL &&
                                    <React.Fragment>
                                        <button type="submit" className="btn btn-primary btn-block">Asignar</button>
                                        <button type="button" className="btn btn-primary btn-block" onClick={cancelar}> Cancelar</button>
                                    </React.Fragment>}
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        );
    }
}
NewsletterComponent.contextType = CurrentUser;
export default NewsletterComponent;