import React, { Component } from "react";
class Beneficios extends Component {
  render() {
    return <React.Fragment>
      <ul>
        <li>Acceso al catálogo completo de procedimientos quirúrgicos  </li>
        <li>Descarga catálogo completo de guías en PDF</li>
        <li>Descuentos especiales en cursos y talleres  </li>
        <li>Entérate siempre de los próximos eventos  </li>
        <li>Acceso a los diplomados  </li>
      </ul>
    </React.Fragment>
  }
}
export default Beneficios;