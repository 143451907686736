import React, {Component} from 'react';
import firebase from 'firebase';
import {Redirect, NavLink} from 'react-router-dom';
import DeleteAlertCompoment from '../core/DeleteAlertComponent';
import UploadFileAlertComponent from '../core/UploadFileAlertComponent';
import Alert from 'react-bootstrap/Alert';
import Form from 'react-bootstrap/Form';
import DatePicker from 'react-date-picker';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import es from '@ckeditor/ckeditor5-build-classic/build/translations/es';
class EventFormComponent extends Component{
    constructor(props){
        super(props);
        const imageTypes = ["image/jpeg","image/jpg","image/png"];
        const fileTypes =["application/pdf"];
        this.state = {event : {status:'Activo',order: new Date(Date.now()),},deleteModalShow: false, fileModalShow: false,fileStatus:{name:"",state:0},errors:{},types:{image:imageTypes,file:fileTypes}};
    }

        componentDidMount = () => {
        const db = firebase.firestore();
        const { match: { params } } = this.props;
        if(params.id){
            db.collection('events').doc(params.id).get().then((doc) => {
                if (doc.exists) {
                    this.setState({
                        event:{id:doc.id,...doc.data(),order:new Date(doc.data().order)},
                        edit: true
                    });
                } else {
                    // doc.data() will be undefined in this case
                    console.log("No such document!");
                }
            });
        }
    }

    handleOrderChange = (date) => {
        const actualEvent = this.state.event;
        actualEvent.order = date;
        this.setState({event:actualEvent});
        
    }
    handleInputChange = (event) => {
        
        const element = event.target.name;
        const actualEvent = this.state.event;
        const errors = Object.assign({},this.state.errors);
        delete errors[element];
        if(event.target.type === 'file'){
            actualEvent[element] = event.target.files[0];
            this.setState({event:actualEvent,errors});
        }else{
            const value = event.target.value; 
            actualEvent[element] = value;
            this.setState({event:actualEvent,errors});
        }
    }

    handleEditorChange= (data) => {
        const actualEvent = this.state.event;
        actualEvent.detail = data;
        const errors = Object.assign({},this.state.errors);
        delete errors["detail"];
        this.setState({event:actualEvent,errors});
        
    }

    handleFormSubmit = (event) => {
        //event.preventDefault();
        if(this.validate()){
            this.saveEvent();
        }
    }

    validate(){
        const values = this.state.event;
        const errors = {};
        const requiredMessage = "Debes llenar este campo.";
        const edit = this.state.edit;

        if(!values.name) { errors.name = requiredMessage;}

        if(!values.date) { errors.date = requiredMessage;}

        if(!values.order) { errors.order = requiredMessage;}

        if(!values.type) { errors.type = requiredMessage;}

        if(!values.detail) { errors.detail = requiredMessage;}

        if(!values.status) { errors.status = requiredMessage;}

        if(!values.fileImage){
            if(!edit){
                errors.fileImage = requiredMessage;
            }
        }else{
            if(!this.state.types.image.includes(values.fileImage.type)){
                errors.fileImage = "El formato del archivo no es correcto.";
            }
        }
        
        if(!values.filePDF){
            /*if(!edit){
                errors.filePDF = requiredMessage;
            }*/
        }else{
            if(!this.state.types.file.includes(values.filePDF.type)){
                errors.filePDF = "El formato del archivo no es correcto.";
            }
        }
        this.setState({errors});
        if(!Object.keys(errors).length){
            return true;
        }
    }
    deleteEvent = () => {
        const db = firebase.firestore();
        this.deleteFile();
        db.collection('events').doc(this.state.event.id).delete().then(() => {
            //this.setState({goToEvents:true});
            this.props.history.push("/admin/events")
        }).catch(function(error) {
            console.error("Error: ", error);
        });
    }

    saveEvent = () => {

        if(this.state.event.fileImage){
            this.saveEventWithFileImage();
        }else if(this.state.event.filePDF || this.state.event.filePDFPrograma){
            this.saveEventWithFilePDF();
        }else {
            this.saveEventOnDb();
        }
    }
    saveEventWithFileImage = () =>{
        /*if(this.state.edit){
            this.deleteFile(this.saveFile());
        }*/
        const storage = firebase.storage().ref('events');
        const file = this.state.event.fileImage;
        const time = Date.now().toString();
        const name = time.concat(file.name);
        const uploadTask = storage.child(name).put(file);
        this.setState({fileModalShow:true});
                    
        uploadTask.on('state_changed', 
            (snapshot) => {
                const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                const fileStatus = {name:"Imagen principal",state:progress};
                this.setState({fileStatus});
            }, 
            (error) => {
                console.log(error);
            }, 
            () => {
                storage.child(name).getDownloadURL().then(url => {
                    const event = this.state.event;
                    event.image = url;
                    event.imageFile = name;
                    this.setState({event});
                    if(this.state.event.filePDF  || this.state.event.filePDFPrograma){
                        this.saveEventWithFilePDF();
                    }else {
                        this.saveEventOnDb();
                    }
            })
        });
    }

    saveEventWithFilePDF = () =>{

        /*if(this.state.edit){
            this.deleteFile(this.saveFile());
        }*/
        if (this.state.event.filePDF){

        
        
        const storage = firebase.storage().ref('events');
        const file = this.state.event.filePDF;
        const time = Date.now().toString();
        const name = time.concat(file.name);
        const uploadTask = storage.child(name).put(file);
        this.setState({fileModalShow:true});
        uploadTask.on('state_changed', 
            (snapshot) => {
                const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                const fileStatus = {name:"Archivo PDF",state:progress};
                this.setState({fileStatus});
            }, 
            (error) => {
                console.log(error);
            }, 
            
            () => {                
                if(this.state.event.filePDFPrograma){
                    this.savePDFPrograma((urlPrograma,namePrograma)=>{
                        storage.child(name).getDownloadURL().then(url => {
                            const event = this.state.event;
                            event.pdf = url;
                            event.pdfFile = name;
                            event.pdfPrograma = urlPrograma;
                            event.pdfProgramaFile = namePrograma;
                            this.setState({event});
                            this.saveEventOnDb();
                    })
                    });
                }
                else{
                    storage.child(name).getDownloadURL().then(url => {
                        const event = this.state.event;
                        event.pdf = url;
                        event.pdfFile = name;                        
                        this.setState({event});
                        this.saveEventOnDb();
                    })
                }
                
        });
        }
        else{
            this.savePDFPrograma((urlPrograma,namePrograma)=>{
                    const event = this.state.event;
                    //        event.pdf = url;
                    //      event.pdfFile = name;
                    event.pdfPrograma = urlPrograma;
                    event.pdfProgramaFile = namePrograma;
                    this.setState({event});
                    this.saveEventOnDb();
            
            });
        }
    }
    savePDFPrograma = (callback)=>{
        const storage = firebase.storage().ref('events');
        const file = this.state.event.filePDFPrograma;
        const time = Date.now().toString();
        const name = time.concat("Prog "+ file.name);
        const uploadTask = storage.child(name).put(file);
        this.setState({fileModalShow:true});
        uploadTask.on('state_changed', 
            (snapshot) => {
                const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                const fileProgramaStatus = {name:"Archivo PDF Programa",state:progress};
                this.setState({fileProgramaStatus});
            }, 
            (error) => {
                console.log(error);
            }, 
            ()=>{
                storage.child(name).getDownloadURL().then((url=>{ 
                    callback(url,name)    
                }));
                
            });
    }
    saveFile = () =>{
        const storage = firebase.storage().ref('events');
        const {file} = this.state;
        const time = Date.now().toString();
        const name = time.concat(file.name);
        const uploadTask = storage.child(name).put(file);
        uploadTask.on('state_changed', 
            (snapshot) => {
                const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                this.setState({progress});
            }, 
            (error) => {
                console.log(error);
            }, 
            () => {
                storage.child(name).getDownloadURL().then(url => {
                    const event = this.state.event;
                    event.image = url;
                    event.imageFile = name;
                    this.setState({event});
                    this.saveEventOnDb();
            })
        });
    }
    addEvent = () => {
        const event = this.state.event;
        const db = firebase.firestore();
        db.collection("events").add({
            name: this.state.event.name,
            date: this.state.event.date,
            order: Date.parse(this.state.event.order),
            type: this.state.event.type,
            detail: this.state.event.detail,
            status: this.state.event.status,
            image: this.state.event.image,
            imageFile: this.state.event.imageFile,
            pdf: this.state.event.pdf||"",
            pdfFile: this.state.event.pdfFile||"",
            pdfPrograma: this.state.event.pdfPrograma || "",
            pdfProgramaFile: this.state.event.pdfProgramaFile||"",
            videoPromo: this.state.event.videoPromo || ""
        })
        .then((docRef) => {
            //this.setState({goToEvents:true});
            this.props.history.push("/admin/events")
        })
        .catch(function(error) {
            console.error("Error : ", error);
        });

    }

    updateEvent = () =>{
        const event = this.state.event;
        const db = firebase.firestore();
        db.collection("events").doc(event.id).set({
            name: this.state.event.name,
            date: this.state.event.date,
            order: Date.parse(this.state.event.order),
            type: this.state.event.type,
            detail: this.state.event.detail,
            status: this.state.event.status,
            image: this.state.event.image,
            imageFile: this.state.event.imageFile,
            pdf: this.state.event.pdf,
            pdfFile: this.state.event.pdfFile,
            pdfPrograma: this.state.event.pdfPrograma || "",
            pdfProgramaFile: this.state.event.pdfProgramaFile || "",
            videoPromo: this.state.event.videoPromo || ""
        })
        .then((docRef) => {
            //this.setState({goToEvents:true});
            this.props.history.push("/admin/events");
            
        })
        .catch(function(error) {
            console.error("Error : ", error);
        });
    }
    

    deleteFile = (func = ()=>{}) =>{
        const storage = firebase.storage().ref('events');
        const file = this.state.event.imageFile;
        const fireRef = storage.child(file);
        fireRef.delete()
            .then(() => {
                func();
            })
            .catch(error => {
                console.log(error);
            })
    }

    saveEventOnDb = () => {
        if(this.state.edit){
            this.updateEvent();
        }else{
            this.addEvent();
        }
    }

    
   
    deleteModalClose = () => this.setState({  deleteModalShow: false });
    eliminarPdf = ()=>{
        
        const actualEvent = Object.assign({},this.state.event);
            document.getElementById("filePDF").value="";
            actualEvent["filePDF"] = "";
            actualEvent["pdf"] = "";
            this.setState({event:actualEvent});
        
    }

    eliminarPdfPrograma = ()=>{
        
        
        const actualEvent = Object.assign({},this.state.event);
            document.getElementById("filePDFPrograma").value="";
            actualEvent["filePDFPrograma"] = "";
            actualEvent["pdfPrograma"] = "";
            this.setState({event:actualEvent});
        
    }
    render(){
        //if(this.state.goToEvents){
         //   return <Redirect to='/admin/events' />
        //}
        
        let fileModalClose = () => this.setState({  fileModalShow: false });
        const errors = this.state.errors;
        return(
            <React.Fragment>
            
            <div className="content-wrapper p-5">
				<div className="row">
					<div className="col-md-12">
						<h1 className="mb-0">Eventos</h1>
						<h3 className="mb-5">Nuevo evento</h3>
						<div className="row justify-content-center mb-4">
							<div className="col-md-10">
								<div className="form-container">
									
									
									<div className="row">
										
										<div className="col-md-12">
											<div className="form-group">
												<label>Nombre</label>
												<input name="name" onChange={this.handleInputChange} value={this.state.event.name}  type="text" className="form-control"/>
                                                {errors.name && <Alert variant="danger"> {errors.name}</Alert>}
                      </div>
                      <div className="row">
                        <div className="col-md-6">
    											<div className="form-group">
    												<label>Fecha del evento a mostrar</label>
    												<input name="date" onChange={this.handleInputChange} value={this.state.event.date}  type="text" className="form-control"/>
                                                    {errors.date && <Alert variant="danger"> {errors.date}</Alert>}
                          </div>
                        </div>
                        <div className="col-md-6">

                          <div className="form-group">
    												<label>Fecha del evento para ordenar</label>
                                                    <div>
                                                        <DatePicker
                                                        onChange={this.handleOrderChange}
                                                        value={this.state.event.order}
                                                        className="form-control" 
                                                        />
                                                         {errors.order && <Alert variant="danger"> {errors.order}</Alert>}
                          </div>
                        </div>
                      </div>
												
											</div>
                      <div className="row">
                        <div className="col-md-6"> 
    											<div className="form-group">
    												<label>Tipo de evento</label>
    												<input name="type" onChange={this.handleInputChange} value={this.state.event.type}  type="text" className="form-control" />
                                                    {errors.type && <Alert variant="danger"> {errors.type}</Alert>}
    											</div>
                        </div>
                        <div className="col-md-6"> 
                          <div className="form-group">
                            <label>Estatus del evento (¿Se debe mostrar en el sitio?)</label>
                            <select name="status" onChange={this.handleInputChange} value={this.state.event.status} className="form-control" >
                              <option value="Activo">Activo</option>
                              <option value="Inactivo">Inactivo</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-4">
                          <div className="form-group">
                            <label >Imagen del evento completo</label>
                            <img className="w-100 mb-2" src={this.state.event.image} />
                            <input name="fileImage" onChange={this.handleInputChange}  type="file" className="form-control-file" accept="image/jpeg,image/jpg,image/png"  />
                                                    {errors.fileImage && <Alert variant="danger"> {errors.fileImage}</Alert>}
                          </div>
                        </div>
                        <div className="col-md-4">

                          <div className="form-group">
                                                    <label >Archivo del evento</label>
                                {this.state.event.pdf && this.state.event.pdf!=="" ?
                                                      <React.Fragment>
                                                          <object type="application/pdf" id='filePDF'
                                                              data = {this.state.event.pdf}  height="400"></object>
                                                              <button onClick={this.eliminarPdf}>
                                                                  Descartar
                                                              </button>
                                                      </React.Fragment>
                                                  :
                                                  this.state.event.filePDF ?
                                                      <React.Fragment>                                                        
                                                              <button onClick={this.eliminarPdf}>
                                                                  Descartar
                                                              </button>
                                                      </React.Fragment>
                                                  :
                                                      <input name="filePDF" onChange={this.handleInputChange}  type="file" className="form-control-file"   accept="application/pdf"
                                                      id = "filePDF"/>
                                                  }
                                                  
                                                  
                                                  {errors.filePDF && <Alert variant="danger"> {errors.filePDF}</Alert>}
                                                  
                          
                        
                                                  
                          </div>
                        </div>
                        <div className="col-md-4">

                          <div className="form-group">
                            <label >Programa del evento</label>
                              {this.state.event.pdfPrograma && this.state.event.pdfPrograma!=="" ?
                                                      <React.Fragment>
                                                          <object type="application/pdf" id='filePDFPrograma'
                                                              data = {this.state.event.pdfPrograma} height="400"></object>
                                                              <button onClick={this.eliminarPdfPrograma}>
                                                                  Descartar
                                                              </button>
                                                      </React.Fragment>
                                                  :
                                                  this.state.event.filePDFPrograma ?
                                                      <React.Fragment>                                                        
                                                              <button onClick={this.eliminarPdfPrograma}>
                                                                  Descartar
                                                              </button>
                                                      </React.Fragment>
                                                  :
                                                      <input name="filePDFPrograma" onChange={this.handleInputChange}  type="file" className="form-control-file"   accept="application/pdf"
                                                    id = "filePDFPrograma"/>
                                                  }
                                                
                                                
                                                {errors.filePDFPrograma && <Alert variant="danger"> {errors.filePDFPrograma}</Alert>}
                                                
                          </div>
                        </div>
                      </div>
                       <div className="form-group">
                          <label>Vídeo promocional del evento</label>
                          <div className="row">
                            <div className="col-auto pr-1">
                            <p className="mt-2 mb-0">https://www.youtube.com/watch?v=</p>
                            </div>
                            <div className="col pl-1">
                            <input name="videoPromo" onChange={this.handleInputChange} value={this.state.event.videoPromo}  type="text" className="form-control"/>                                                
                            </div>
                          </div>
                          
                      </div>
											<div className="form-group">
												<label>Detalle del evento</label>
                                                <CKEditor editor={ClassicEditor} 
                                                    data={this.state.event.detail} 
                                                    config={{language:'es',removePlugins: ['MediaEmbed','ImageUpload']}}
                                                    onChange={(event,editor) => {this.handleEditorChange(editor.getData())}}
                                                />
                                                {errors.detail && <Alert variant="danger"> {errors.detail}</Alert>}
                    </div>
											
										</div>
										

                  </div>
                  <div className="row">
                    <div className="col-md-4">
                      
                    </div>
                    
                    <div className="col-md-4">

                     
										</div>
									</div>	
								</div>
							</div>
						</div>
						
                        <div className="btn-row"  >
                            {this.state.event.id && <button onClick={() => this.setState({ deleteModalShow: true })} className="btn btn-primary">Eliminar</button>}                
							<NavLink to="/admin/events" activeClassName="btn btn-primary">Cancelar</NavLink>
                            <button onClick = {this.handleFormSubmit} className="btn btn-primary">Guardar</button>
						</div>
					</div>
				</div>
			</div>
            
            
            <DeleteAlertCompoment show={this.state.deleteModalShow} onHide={this.deleteModalClose} deleteElement={this.deleteEvent}/>
            <UploadFileAlertComponent show={this.state.fileModalShow} onHide={fileModalClose} file={this.state.fileStatus}/>
        </React.Fragment>
        );
    }
}
export default EventFormComponent;