import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';

import Alert from 'react-bootstrap/Alert';
import firebase from 'firebase';
import MaskedFormControl from 'react-bootstrap-maskedinput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

var moment = require("moment");
moment.locale("es");

class VerifyingPurchaseModal extends Component {
    constructor(props) {
        super(props);

        this.state = {

        }
    }

    componentDidMount = () => {
    }


    render() {

        return (
            <Modal {...this.props} size="lg" className="progress-modal" centered >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <h5 className="modal-title" id="modalTerminoLabel">Procesando Pago</h5>
                        
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    Estamos verificando la transacción. Esto puede tardar un minuto...
                </Modal.Body>

                <Modal.Footer className="justify-content-center">
                    <Spinner animation="border" role="status"/>
                </Modal.Footer>
            </Modal>
        )
    }
}
export default VerifyingPurchaseModal;