import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import Alert from 'react-bootstrap/Alert';
import firebase from 'firebase';
import MaskedFormControl from 'react-bootstrap-maskedinput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

var moment = require("moment");
moment.locale("es");

class AdministrarFotoLandingModal extends Component {
    constructor(props) {
        super(props);
        let passedIn = this.props.fileAEditar

        this.state = {
            index: passedIn && passedIn.orden ? passedIn.orden : false,
            title: passedIn && passedIn.title ? passedIn.title : "",
            subtitle: passedIn && passedIn.subtitle ? passedIn.subtitle : "",
            fileLanding: passedIn || {},
            colorTexto: passedIn && passedIn.colorTexto ? passedIn.colorTexto : "Blanco",
        }
    }

    componentDidMount = () => {

    }

    handleFileChange = (event) => {
        let file = event.target.files[0]
        let fileType = file.name.split(".")

        let extension = fileType[fileType.length - 1].toLowerCase();
        let video = ["webm", "mp4", "ogg"].some(ex => ex === extension)

        let fileLanding = {
            name: file.name,
            file: file,
            url: URL.createObjectURL(file),
            fecha_creacion: Date.now(),
            video
        }

        if (video)
            this.setState({ fileLanding, title: "", subtitle: "" })
        else
            this.setState({ fileLanding })
    }

    handleInputChange = (event) => {
        this.setState({ [event.target.name]: event.target.value })
    }

    render() {
        return (
            <Modal {...this.props} size="xl" className="progress-modal" centered >
                <Modal.Header closeButton onHide={this.props.cancel}>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <h5 className="modal-title" id="modalTerminoLabel">Administrar Nuevo Foto o video</h5>
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div className="row justify-content-center mb-4">
                        <div className="col-md-10">
                            <h4>Información general</h4>
                            <div className="form-container">


                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            {this.state.fileLanding.video &&
                                                <>
                                                    <label >Video</label>
                                                    <video className="w-100 mb-2" controls src={this.state.fileLanding.url} type="video/mp4" />
                                                </>}
                                            {this.state.fileLanding.video === false &&
                                                <>
                                                    <label >Foto</label>
                                                    <img width={520} controls src={this.state.fileLanding.url} />
                                                </>}
                                            <input name="file" onChange={this.handleFileChange} type="file" className="form-control-file" accept="image/jpeg,image/jpg,image/png, video/ogg,video/mp4,video/webm" />
                                        </div>
                                    </div>
                                </div>

                                <div className="form-group">
                                    <label>Texto título</label>
                                    <input name="title" onChange={this.handleInputChange} value={this.state.title} type="text" className="form-control" />
                                </div>

                                <div className="form-group">
                                    <label>Texto subtítulo</label>
                                    <textarea name="subtitle" rows="2" cols="25" onChange={this.handleInputChange} value={this.state.subtitle} type="text" className="form-control" />
                                </div>

                                <div className="form-group">
                                    <label>Color texto</label>
                                    <select name="colorTexto" onChange={this.handleInputChange} value={this.state.colorTexto} className="form-control">
                                        <option value={"Blanco"}> Blanco </option>
                                        <option value={"Negro"}> Negro </option>
                                    </select>
                                </div>


                            </div>
                        </div>
                    </div>
                </Modal.Body>

                <Modal.Footer >
                    <button type="button" className="btn btn-primary" onClick={this.props.cancel} >Cancelar</button>
                    <button type="button" className="btn btn-primary" onClick={() => this.props.guardar({
                        ...this.state.fileLanding,
                        colorTexto: this.state.colorTexto,
                        title: this.state.fileLanding.video ? "" : this.state.title,
                        subtitle: this.state.fileLanding.video ? "" : this.state.subtitle
                    }, this.state.index)}
                    >Guardar</button>
                </Modal.Footer>
            </Modal>
        );
    }
}
export default AdministrarFotoLandingModal;