import React,{Component} from "react";
class AvisoPrivacidad extends Component{
    render(){
        return<section className="">
                <div className="container">
                          <div className="row ">
                              <div className="col-md-12">
                                  <h1 className="main-title">
                                  
                                  Aviso de Privacidad
                                  </h1>
                                  <div  className="mt-4">
                                      <div class="contenido_parrafo">
                                        
                                        <p>Con fundamento en los artículos 15 y 16 de la&nbsp;<strong>Ley Federal de Protección de Datos Personales en Posesión de Particulares</strong>&nbsp;hacemos de su conocimiento que&nbsp;<a class="a_cgv" href="http://cirugiaveterinaria.mx/">Cirugia Veterinaria Integral SRL de CV</a>, con domicilio en&nbsp;<strong>Juan Josè Torres Landa 107 Local 2 y 3 E Fracc. Del Parque C.P. 38010 en Celaya Guanajuato, Mèxico, </strong>es responsable de recabar sus datos personales, del uso que se le dé a los mismos y de su protección.</p>
                                        <p>Su información personal será utilizada para las siguientes finalidades: proveer los servicios y productos que ha solicitado; notificarle sobre nuevos servicios o productos que tengan relación con los ya contratados o adquiridos; comunicarle sobre cambios en los mismos; elaborar estudios y programas que son necesarios para determinar hábitos de consumo; realizar evaluaciones periódicas de nuestros productos y servicios a efecto de mejorar la calidad de los mismos; evaluar la calidad del servicio que brindamos, y en general, para dar cumplimiento a las obligaciones que hemos contraído con usted.</p>
                                        <p>Para las finalidades antes mencionadas, requerimos obtener los siguientes datos personales:</p>
                                        <ul>
                                            <li><strong>Nombre completo</strong></li>
                                            <li><strong>Correo electrónico</strong></li>
                                            <li><strong>País</strong></li>
                                            <li><strong>Ciudad</strong></li>
                                        </ul>
                                        
                                        <p>Es importante informarle que usted tiene derecho al Acceso, Rectificación y Cancelación de sus datos personales, a Oponerse al tratamiento de los mismos o a revocar el consentimiento que para dicho fin nos haya otorgado.</p>
                                        <p>Para ello, es necesario que envíe la solicitud en los términos que marca la Ley en su Art. 29 a Dr. Miguel Abelardo Villalva Solà, responsable de nuestro Departamento de Protección de Datos Personales, ubicado en Juan Josè Torres Landa 107 Local 2 y 3 E Fracc. Del Parque C.P. 38010 en Celaya Guanajuato, Mèxico, o bien, se comunique al teléfono 461 6149844 o vía correo electrónico a&nbsp;<a class="a_cgv" href="mailto:mvillalva@cirugiaveterinaria.mx">mvillalva@cirugiaveterinaria.mx</a>, &nbsp;el cual solicitamos confirme vía telefónica para garantizar su correcta recepción.</p>
                                        <p><strong>Importante</strong> Cualquier modificación a este Aviso de Privacidad podrá consultarlo en&nbsp;
                                          <a class="a_cgv" href="http://cirugiaveterinaria.mx/aviso_privacidad">
                                            http://cirugiaveterinaria.mx/aviso_privacidad
                                          </a>
                                        </p> 
                                    </div>
                                  </div>  
                    </div>  
                  </div>
                </div>
              </section>
    }
}

export default AvisoPrivacidad;