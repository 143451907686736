import React, { Component } from 'react';
import firebase from 'firebase';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';

import { throwStatement } from '@babel/types';
import CurrentUser from "../../public/core/PublicUserSessionContext";
import DataTableComponent from '../core/DataTableComponent';
import { getOrganizations } from '../../../service/organizations';

class OrganizationsComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            organizations: []
        }
    }

    componentDidMount() {
        this.setState({ loading: true });
        this.getInitialData()
    }

    getInitialData = async () => {
        let organizations = await getOrganizations()
        this.setState({ organizations, loading: false });
    }

    getColumns = () => [
        { label: 'Título', field: 'title', sort: 'asc' },
        { label: 'Universidad/Empresa', field: 'type', sort: 'asc' },
        { label: 'Editar', field: 'editar', sort: 'asc' },
    ];

    getData = () => {
        const rows = [];
        this.state.organizations.map(organization => {
            rows.push({
                title: organization.title, type: organization.type,
                editar: <button type="button" className="btn btn-primary" onClick={() => this.props.history.push(`/admin/organizations/organization/${organization.id}`)} >Editar</button>,
            });
        });
        return { columns: this.getColumns(), rows };
    }

    render() {
        return (
            <div className="content-wrapper p-5">
                <div className="row">
                    <div className="col-md-12">
                        <h1>Organizaciones</h1>
                        <DataTableComponent data={this.getData()} />
                        <div className="btn-row"  >
                            <a role="button" href="/admin/organizations/crear-organization" className="btn btn-primary">Crear una nueva organización</a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default OrganizationsComponent;