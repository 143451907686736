import React, { Component } from "react";
import TablaSimple from "./TablaSimple";
import { analytics } from "../../../resources/config/keys";
import { getAllUsers } from "../../../service/users"
import CurrentUser from "../../public/core/PublicUserSessionContext";
import { GRATIS, PREMIUM, ANALYTICS_USER_FILTER_OPTIONS as opciones, ANALYTICS_USER_FILTER_MAP as mapaFiltro } from "../../../service/constants";
import { getProcedure, getProcedures } from "../../../service/procedures";

class StatisticsComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            allUsers: [],
            allProcedures: [],
            usersPremium: 0,
            usersPremiumAnual: 0,
            usersGratis: 0,
            filterUserTypes: opciones[0],
            error: "",
            signedIn: false,
            datosVideos: [],
            datosFasciculos: [],
            videosVistos: 0,
            fasciculosDescargados: 0,
            previewPage: 0
        }
    }
    componentDidMount() {
        this.iniciarAnalytics()
        this.getInitialData()
    }
    getInitialData = async () => {
        this.context.iniciaCarga()
        let allUsers = await getAllUsers()
        let allProcedures = await getProcedures()

        let gratis = allUsers.filter(user => user.type === GRATIS)
        let premium = allUsers.filter(user => user.type === PREMIUM)
        let premiumAnual = allUsers.filter(user => user.hasPremiumAnual)

        let [usersGratis, usersPremium, usersPremiumAnual] = [gratis.length, premium.length, premiumAnual.length]
        this.setState({ allUsers, usersGratis, usersPremium, usersPremiumAnual, allProcedures })
        this.context.terminaCarga()
    }
    findProcedure = (procedureID) => this.state.allProcedures.find(el => el.id === procedureID) || null

    iniciarAnalytics = () => {
        this.context.iniciaCarga();
        let gapi = window.gapi.analytics, that = this;

        gapi.ready(() => {
            gapi.auth.authorize({
                container: 'auth-button',
                clientid: analytics.clientId,
                userInfoLabel: "Iniciaste sesión como: "
            });
            gapi.auth.on("signIn", function () {
                that.cargarDatosGA();
                that.setState({ signedIn: true });
            })
            gapi.auth.on("signOut", function () {
                that.setState({ signedIn: false });
            })
            this.context.terminaCarga();
        });
    }

    cargarDatosGA = (filterUserTypes = this.state.filterUserTypes) => {
        this.context.iniciaCarga();
        let filtroVideos = "ga:eventCategory==VIDEOS";
        let filtroFasciculos = "ga:eventCategory==FASCICULOS";
        let filtroPreviewPage = "ga:eventCategory==DIPLOMADO_PREVIEW_PAGE";
        let dimensions = "ga:eventLabel";
        let indexAmount = 1;

        if (filterUserTypes !== opciones[0]) {
            filtroVideos += ";ga:eventAction==" + mapaFiltro[filterUserTypes]
            filtroFasciculos += ";ga:eventAction==" + mapaFiltro[filterUserTypes]
        } else {
            dimensions = "ga:eventLabel"
            indexAmount = 1
        }
        //Cuenta de videos vistos  
        GAQuery("ga:eventCategory", "ga:totalEvents", "ga:eventCategory==VIDEOS", async (rows) => {
            let videosVistos = rows.length == 0 ? 0 : rows[0][1]
            this.setState({ videosVistos });
        }, (error) => { console.log(`Error al hacer GAQuery de videos: ${JSON.stringify(error)}`) })
        //Cuenta fasciculos descargados
        GAQuery("ga:eventCategory", "ga:totalEvents", "ga:eventCategory==FASCICULOS", async (rows) => {
            let fasciculosDescargados = rows.length == 0 ? 0 : rows[0][1]
            this.setState({ fasciculosDescargados });
        }, (error) => { console.log(`Error al hacer GAQuery de fasciculos: ${JSON.stringify(error)}`) })
        //Diplomado vistas previa pantallas visitados
        GAQuery("ga:eventCategory", "ga:totalEvents", "ga:eventCategory==DIPLOMADO_PREVIEW_PAGE", async (rows) => {
            let previewPage = rows.length == 0 ? 0 : rows[0][1]
            this.setState({ previewPage });
        }, (error) => { console.log(`Error al hacer GAQuery de diplomado vistas previa pantallas visitados: ${JSON.stringify(error)}`) })

        //Lista de videos mas descargados
        GAQuery(dimensions, "ga:totalEvents", filtroVideos, async (rows) => {
            let allProcedures = rows.map(row => this.findProcedure(row[0]))
            let filtered = allProcedures.filter(el => (el && el !== null))//Filtrar por cirugias que existen en el base de datos

            let datosVideos = filtered.map((procedure, index) => {
                return {
                    name: procedure.name,
                    id: rows[index][0],
                    id2: procedure.id,
                    amount: rows[index][indexAmount]
                }
            })
            this.setState({ datosVideos });
        }, (error) => { console.log(`Error al hacer GAQuery de videos mas descargados: ${JSON.stringify(error)}`) })

        //Lista de fasciculos mas descargados
        GAQuery(dimensions, "ga:totalEvents", filtroFasciculos, async (rows) => {
            let allProcedures = rows.map(row => this.findProcedure(row[0]))
            let filtered = allProcedures.filter(el => (el && el !== null))//Filtrar por cirugias que existen en el base de datos

            let datosFasciculos = filtered.map((procedure, index) => {
                return {
                    name: procedure.name,
                    id: rows[index][0],
                    id2: procedure.id,
                    amount: rows[index][indexAmount]
                }
            })
            this.setState({ datosFasciculos });
            this.context.terminaCarga();
        }, (error) => { console.log(`Error al hacer GAQuery de fasciculos mas descargados: ${JSON.stringify(error)}`) })
    }

    getCountries = () => {
        let paises = new Set([]), countries = [];
        let { filterUserTypes, allUsers } = this.state
        let filteredUsers = [...allUsers];

        if (filterUserTypes === "Solo No Registrados")
            return [];
        if (filterUserTypes !== "Todos los usuarios")
            filteredUsers = allUsers.filter(user => user.type === mapaFiltro[filterUserTypes]);

        filteredUsers.forEach(user => {
            if (paises.has(user.country)) {
                let pais = countries.find(pais => pais.name === user.country);
                pais.amount++
            }
            else {
                paises.add(user.country);
                countries.push({ name: user.country, amount: 1 })
            }
        })

        let filteredCountries = countries.filter(el => el.amount > 0)
        let countriesSorted = filteredCountries.sort((a, b) => b.amount - a.amount)
        return countriesSorted.slice(0, 20);//Solo regresar los primeros 20 paises de la lista
    }

    handleFiltro = (event) => {
        let filterUserTypes = event.target.value
        this.setState({ filterUserTypes });
        this.cargarDatosGA(filterUserTypes);
    }

    render() {
        const salirGA = () => { window.gapi.analytics.auth.signOut() }
        let state = this.state
        let noLyticsError = (state.error !== "LYTICS")
        return (
            <div className="content-wrapper p-5">
                <div className="row">
                    <div className="col-md-12">

                        <h1 className="mb-4">Estadísticas generales</h1>
                        <div className="lytics-container">
                            <div className="">
                                <div className="d-flex auth align-items-center justify-content-center">

                                    <div className="mx-2 mb-4">
                                        {noLyticsError && !state.signedIn &&
                                            <div className="promptGa"> Por favor inicia sesión en Google Analytics con una cuenta autorizada para el proyecto</div>}
                                        {!noLyticsError && <div className="errorDiv"> Hubo un error con tu cuenta de Google Analytics, asegúrate de que está autorizada</div>}
                                    </div>

                                    <div className="mx-2 mb-4">
                                        <div id="auth-button" />
                                    </div>

                                    {state.signedIn &&
                                        <div className="mx-2 mb-4">
                                            <button className="btn btn-primary" onClick={salirGA}>Salir de Google Analytics</button>
                                        </div>}
                                </div>
                            </div>

                            {noLyticsError && state.signedIn &&
                                <div>
                                    <div className="bg-light pt-4 pb-4">
                                        <div className="row text-center">
                                            <div className="col-md-3 ">
                                                <p className="h1 mb-0">{state.videosVistos}</p>
                                                <p className="mb-0">Videos vistos</p>
                                            </div>
                                            <div className="col-md-3 ">
                                                <p className="h1 mb-0">{state.fasciculosDescargados}</p>
                                                <p className="mb-0">Fascículos descargados</p>
                                            </div>
                                            <div className="col-md-3 ">
                                                <p className="h1 mb-0">{state.usersPremium}</p>
                                                <p className="mb-0">Usuarios Premium Total</p>
                                            </div>
                                            <div className="col-md-3 ">
                                                <p className="h1 mb-0">{state.usersPremium - state.usersPremiumAnual}</p>
                                                <p className="mb-0">Usuarios Premium Mensual</p>
                                            </div>
                                            <div className="col-md-3 ">
                                                <p className="h1 mb-0">{state.usersPremiumAnual}</p>
                                                <p className="mb-0">Usuarios Premium Anual</p>
                                            </div>
                                            <div className="col-md-3 ">
                                                <p className="h1 mb-0">{state.usersGratis}</p>
                                                <p className="mb-0"> Usuarios Gratis</p>
                                            </div>
                                            <div className="col-md-3 ">
                                                <p className="h1 mb-0">{state.previewPage}</p>
                                                <p className="mb-0"> Diplomados previas vistos</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div>
                                        <h1 className="mb-4 pt-4">Estadísticas detalladas</h1>
                                        <div className="bg-light p-4 mb-4">
                                            <div className="form-group">
                                                <label>Filtrar por</label>
                                                <select className="form-control" value={state.filterUserTypes} onChange={this.handleFiltro}>
                                                    {opciones.map((opcion, index) => <option key={index} value={opcion}>{opcion}</option>)}
                                                </select>
                                            </div>
                                        </div>
                                    </div>

                                    {/*Tablas simples de estadisticas*/}
                                    <div className="row">
                                        <TablaSimple tableName={"Videos más vistos"} headerOne="Nombre" headerTwo="# de consultas" rows={state.datosVideos} />

                                        <TablaSimple tableName={"Fascículos mas descargados"} headerOne="Nombre" headerTwo="# de consultas" rows={state.datosFasciculos} />

                                        <TablaSimple tableName={"Top de países"} headerOne="Nombre" headerTwo="# de consultas" rows={this.getCountries()} />
                                    </div>
                                </div>}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export const GAQuery = function (dimensions, metrics, filters, success, error) {
    let gapi = window.gapi;

    gapi.analytics.ready(() => {
        let timeline = new gapi.analytics.report.Data({
            query: {
                //'dimensions': 'ga:eventAction,ga:eventLabel,ga:eventCategory',
                'dimensions': dimensions,
                //'metrics': 'ga:totalEvents',
                'metrics': metrics,
                //'start-date': '30daysAgo',
                'start-date': "2010-12-25",
                //'end-date': 'yesterday',
                'end-date': "today",
                "filters": filters,
                "sort": "-ga:totalEvents",
                "max-results": 20
            }
        });
        var newIds = {
            query: {
                ids: "ga:" + analytics.viewId
            }
        }
        timeline.on('success', function (response) {
            if (response.totalResults == 0) {
                success([]);
                return;
            }
            let rows = response.rows;
            success(rows);

        });
        timeline.on("error", function (error) {
            error(error);
        })
        timeline.set(newIds).execute();
    })
}
StatisticsComponent.contextType = CurrentUser;
export default StatisticsComponent;