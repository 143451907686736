import React, { Component } from 'react';
import DataTableComponent from '../core/DataTableComponent'
import CurrentUser from '../../public/core/PublicUserSessionContext';
import { getTags } from '../../../service/procedures';

class TagsListComponent extends Component {
    constructor() {
        super();
        this.state = { tags: [] };
    }
    componentDidMount() {
        this.getInitialData()
    }
    getInitialData = async () => {
        this.context.iniciaCarga()
        let tags = await getTags()
        this.setState({ tags });
        this.context.terminaCarga()
    }

    getColumns = () => [{ label: 'Nombre', field: 'name', sort: 'asc' }];
    getData = () => {
        const rows = this.state.tags.map(tag => ({ ...tag, clickEvent: () => this.props.history.push(`/admin/tags/tag/${tag.id}`) }));
        return { columns: this.getColumns(), rows };
    }

    render() {
        return (
            <div className="content-wrapper p-5">
                <div className="row">
                    <div className="col-md-12">
                        <h1>Etiquetas</h1>
                        <DataTableComponent data={this.getData()} />
                        <div className="btn-row"  >
                            <a className='btn btn-primary' href="/admin/procedures">Administrar cirugías</a>
                            <a href="/admin/tags/tag" className="btn btn-primary">Crear nueva etiqueta</a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
TagsListComponent.contextType = CurrentUser;
export default TagsListComponent;