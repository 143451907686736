import React, { Component } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";


const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 768 },
        items: 1,
        slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
        breakpoint: { max: 768, min: 464 },
        items: 1,
        slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        slidesToSlide: 1, // optional, default to 1.
    },
};
class SliderLanding extends Component {
    constructor(props) {
        super(props);
        this.state = {
            photosLanding: this.props.photosLanding.map(foto => ({ link: "http://petvetsanmiguel.com/", ...foto })),
            videoPlaying: false
        }
    }
    componentDidMount() {

    }
    obtenerPhotos = () => {
        return this.state.photosLanding.map((foto, index) => {
            return foto.video
                ? <section key={index} className="slogan home intro justify-content-center align-items-center d-flex " style={{ width: "100%", height: "100%", backgroundImage: foto.video ? "" : `url(${foto.url})` }} >
                    <video id={`myVideo${index}`} width="100%" controls >
                        <source src={foto.url} type="video/mp4" />
                        <source src={foto.url} type="video/webm" />
                        <source src={foto.url} type="video/ogg" />
                        Your browser does not support HTML video.
                </video>
                </section>
                : <section key={index} className="slogan home intro justify-content-center align-items-center d-flex " style={{ width: "100%", height: "100%", backgroundImage: `url(${foto.url})` }} >
                    <div className="container  ">
                        <div className="row text-light text-center justify-content-center">
                            {index === 0
                                ?
                                <div className="col-md-12 col-sm-11 col-9">
                                    <p className="h1 font-weight-bold mb-2 ">Un cirujano siempre contigo </p>
                                    <p className="mb-3 text">Prepárate para tu próxima cirugía con el mejor <strong>catálogo de videos</strong>, guías y toda nuestra <strong>experiencia y soporte </strong> para resolver tus dudas</p>
                                    {this.props.user == "Gest" && <a href="/registro" className="btn btn-light btn-round btn-lg ">Unirte ahora</a>}
                                </div>
                                :
                                <div className="col-md-12 col-sm-11 col-9 ">
                                    <p className="h1 font-weight-bold mb-2 " style={{ color: foto.colorTexto === "Blanco" ? "white" : "black" }}>{foto.title} </p>
                                    <p className="mb-3 text" style={{ color: foto.colorTexto === "Blanco" ? "white" : "black" }}>{foto.subtitle}</p>
                                </div>
                            }
                        </div>
                    </div>
                </section>
        })
    }

    render() {
        return <Carousel
            swipeable={true}
            draggable={false}
            showDots={false}
            responsive={responsive}
            infinite={true}
            autoPlay={true}
            autoPlaySpeed={this.state.photosLanding[0].tiempo ? (this.state.photosLanding[0].tiempo * 1000) : 6000}
            transitionDuration={2}
            arrows={true}
            containerClass="carousel-container"
            itemClass="carousel-item-padding-40-px"
            afterChange={(_, state) => {
                
                let actualPhotoIndex;
                const carouselInfinitePlayOffset = 2

                if(state.currentSlide > 1) 
                    actualPhotoIndex = state.currentSlide - carouselInfinitePlayOffset;
                else{
                    const lastPhotoIndex = this.state.photosLanding.length - 1
                    actualPhotoIndex = lastPhotoIndex;
                }
                
                if (this.state.photosLanding[actualPhotoIndex].video){
                    document.querySelector(`li[data-index='${state.currentSlide}'] section #myVideo${actualPhotoIndex}`).play()
                }

                for (let x = 0; x < this.state.photosLanding.length + 2; x++) {
                    let video = document.querySelector(`li[data-index='${x}'] section video`)
                    if (video && x !== state.currentSlide)
                        video.pause()
                }
            }}
        >
            {this.obtenerPhotos()}
        </Carousel>;
    }
}
export default SliderLanding;




