import firebase from 'firebase';

export function uploadFileToStorage(collection, docID, fileName, file, loadingText, setState) {
    return new Promise((resolve, reject) => {
        let storagePath = getStoragePath(collection, docID, fileName)
        let storageRef = firebase.storage().ref().child(storagePath)
        let uploadPreviewTask = storageRef.put(file)

        uploadPreviewTask.on("state_changed",
            (snapshot) => {
                const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                const fileStatus = { name: loadingText, state: progress };
                setState({ fileStatus, fileModalShow: true });
                console.log(`FILE STATUS ${loadingText}: ${fileStatus}`);
            },
            (error) => {
                console.log(`Error al subir ${loadingText}, a storage: ${JSON.stringify(error)}`);
                reject(error)
            },
            async () => {
                let url = await uploadPreviewTask.snapshot.ref.getDownloadURL()
                setState({ fileModalShow: false });
                resolve(url)
            }
        );
    })
}

function getStoragePath(coll, docID, fileName,) {
    if (storagePathContains_DocID(coll))
        return `${coll}/${docID}/${fileName}`
    return `${coll}/${fileName}`
}
const storagePathContains_DocID = (collection) => ["aulas", "diplomados", "organizations"].includes(collection)