import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import Alert from 'react-bootstrap/Alert';
import DataTableComponent from '../../core/DataTableComponent';
import firebase from 'firebase';
import MaskedFormControl from 'react-bootstrap-maskedinput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

var moment = require("moment");
moment.locale("es");

class AdministrarVideoModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            errors: []
        }
    }

    componentDidMount = () => {

    }

    checkForErrors = () => {
        let errors = []

        if (this.props.title === "")
            errors.push("title")
        if (this.props.description === "")
            errors.push("description")
        if (this.props.url === "")
            errors.push("video")

        this.props.times.forEach(time => {
            if(time.name === "" || time.time === "")
                errors.push("times")
        })
        this.props.pdfs.forEach(pdf => {
            if(pdf.title === "" || pdf.url === "")
                errors.push("pdfs")
        })
        this.props.images.forEach(image => {
            if(image.title === "" || image.url === "")
                errors.push("images")
        })

        if (errors.length === 0)
            this.props.saveVideo()

        this.setState({ errors })
    }

    handleChange = (event) => {
        this.setState({errors: []})
        this.props.handleInputChange(event)
    }
    handleFileChange = (event) => {
        this.setState({errors: []})
        this.props.handleFileInputChange(event)
    }

    render() {
        return (
            <Modal {...this.props} size="xl" className="progress-modal" centered >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <h5 className="modal-title" id="modalTerminoLabel">Administrar Nuevo Video</h5>
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div className="row justify-content-center mb-4">
                        <div className="col-md-10">
                            <h4>Información general</h4>
                            <div className="form-container">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label>Título</label>
                                            <input name="title" onChange={this.handleChange} value={this.props.title} type="text" className="form-control" />
                                            {this.state.errors.includes("title") && <Alert variant="danger"> Este campo es requerido.</Alert>}
                                        </div>
                                        <div className="form-group">
                                            <label>Descripción</label>
                                            <textarea name="description" onChange={this.handleChange} value={this.props.description} className="form-control" rows="3" />
                                            {this.state.errors.includes("description") && <Alert variant="danger"> Este campo es requerido.</Alert>}
                                        </div>

                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label >Video completo</label>
                                                    <video className="w-100 mb-2" controls src={this.props.url} type="video/mp4">
                                                    </video>
                                                    <input name="fileVideo" onChange={this.handleFileChange} type="file" className="form-control-file" accept="video/ogg,video/mp4,video/webm" />
                                                    {this.state.errors.includes("video") && <Alert variant="danger"> Este campo es requerido.</Alert>}
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label >Marcadores de video</label>
                                                    <table className="table" >
                                                        <thead>
                                                            <tr>
                                                                <th scope="">Nombre</th>
                                                                <th scope="">Tiempo</th>
                                                                <th scope=""></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody >
                                                            {Array.isArray(this.props.times) && this.props.times.map((time, index) => {
                                                                return (
                                                                    <tr key={index}>
                                                                        <td>
                                                                            <input name="name" onChange={(e) => {this.setState({errors: []})
                                                                            this.props.handleTimeChange(e, index)}} value={time.name} type="text" className="form-control" />
                                                                        </td>
                                                                        <td>
                                                                            <MaskedFormControl name="time" onChange={(e) => {this.setState({errors: []})
                                                                            this.props.handleTimeChange(e, index)}} value={time.time} type="text" className="form-control" placeholder="hh:mm:ss" mask='1:11:11' />
                                                                        </td>
                                                                        <td>
                                                                            <a className="text-light btn btn-primary" onClick={() => {this.setState({errors: []})
                                                                            this.props.deleteTime(index)}}>
                                                                                <FontAwesomeIcon icon={faTrash} />

                                                                            </a>
                                                                        </td>
                                                                    </tr>)
                                                            })
                                                            }
                                                        </tbody>
                                                    </table>
                                                    {this.state.errors.includes("times") && <Alert variant="danger">Por favor complete los nombres y horarios de cada marcador.</Alert>}
                                                    <button className="btn btn-primary btn-block" type="button" onClick={() => {this.setState({errors: []})
                                                                            this.props.addTime()}}>Agregar nuevo marcador</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="column">
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label >PDF's de video</label>
                                                    <table className="table" >
                                                        <thead>
                                                            <tr>
                                                                <th scope="">Título</th>
                                                                <th scope="">Preview</th>
                                                                <th scope="">Subir</th>
                                                                <th scope=""></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody >
                                                            {Array.isArray(this.props.pdfs) && this.props.pdfs.map((pdf, index) => {
                                                                return (
                                                                    <tr key={index}>
                                                                        <td>
                                                                            <input name="pdfs" onChange={(e) => {this.setState({errors: []})
                                                                                this.props.handleResourceTitleChange(e, index)}} value={pdf.title} type="text" className="form-control" />
                                                                        </td>
                                                                        <td>
                                                                            {pdf.url === "" && <div></div>}
                                                                            {pdf.url !== "" && <iframe width="100%" controls src={pdf.url} />}
                                                                        </td>
                                                                        <td>
                                                                            <input name="pdf" onChange={(e) => {this.setState({errors: []})
                                                                                this.props.handleFileInputChange(e, index)}} type="file" className="form-control-file" accept="application/pdf" />
                                                                        </td>
                                                                        <td>
                                                                            <a className="text-light btn btn-primary" onClick={() => {this.setState({errors: []})
                                                                                this.props.deleteResource(index, "pdfs")}}>
                                                                                <FontAwesomeIcon icon={faTrash} />

                                                                            </a>
                                                                        </td>
                                                                    </tr>)
                                                            })
                                                            }
                                                        </tbody>
                                                    </table>
                                                    {this.state.errors.includes("pdfs") && <Alert variant="danger">Complete el título y cargue un pdf</Alert>}
                                                    <button className="btn btn-primary btn-block" type="button" onClick={() => {this.setState({errors: []})
                                                        this.props.addResource("pdfs")}}>Agregar nuevo pdf</button>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label >Imagenes de video</label>
                                                    <table className="table" >
                                                        <thead>
                                                            <tr>
                                                                <th scope="">Título</th>
                                                                <th scope="">Preview</th>
                                                                <th scope="">Subir</th>
                                                                <th scope=""></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody >
                                                            {Array.isArray(this.props.images) && this.props.images.map((image, index) => {
                                                                return (
                                                                    <tr key={index}>
                                                                        <td>
                                                                            <input name="images" onChange={(e) => {this.props.handleResourceTitleChange(e, index)
                                                                            this.setState({errors: []})}} value={image.title} type="text" className="form-control" />
                                                                        </td>
                                                                        <td>
                                                                            {image.url === "" && <div></div>}
                                                                            {image.url !== "" && <img width={180} height={140} controls src={image.url} />}
                                                                        </td>
                                                                        <td>
                                                                            <input name="image" onChange={(e) => {this.props.handleFileInputChange(e, index)
                                                                            this.setState({errors: []})}} type="file" className="form-control-file" accept="image/jpeg,image/jpg,image/png" />
                                                                        </td>
                                                                        <td>
                                                                            <a className="text-light btn btn-primary" onClick={() => {this.props.deleteResource(index, "images")
                                                                        this.setState({errors: []})}}>
                                                                                <FontAwesomeIcon icon={faTrash} />

                                                                            </a>
                                                                        </td>
                                                                    </tr>)
                                                            })
                                                            }
                                                        </tbody>
                                                    </table>
                                                    {this.state.errors.includes("images") && <Alert variant="danger">Complete el título y cargue una imagen</Alert>}
                                                    <button className="btn btn-primary btn-block" type="button" onClick={() => {this.setState({errors: []})
                                                        this.props.addResource("images")}}>Agregar nueva imagen</button>
                                                </div>
                                            </div>

                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>

                <Modal.Footer >
                    <button type="button" className="btn btn-primary" onClick={this.props.cancel} >Cancelar</button>
                    <button type="button" className="btn btn-primary" onClick={this.checkForErrors}>Guardar</button>
                </Modal.Footer>
            </Modal>
        );
    }
}
export default AdministrarVideoModal;