import React, { Component } from 'react';
import firebase from 'firebase';
import Spinner from "../../commons/Spinner";
import DataTableComponent from '../core/DataTableComponent';
import CurrentUser from "../../public/core/PublicUserSessionContext";
import EliminarModal from './modals/EliminarModal'
import ActivadoModal from './modals/ActivadoModal'
import { getDateFormattedFirstDay } from '../../../service/functions';

class DiplomadosComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            diplomados: [],
            selectedDiplomado: { id: "" },
            eliminarModalOpen: false,
            activadoModalOpen: false,
            selectedDiplomado: "",
            selectedDiplomadoIndex: -1,
        }
    }

    componentDidMount = () => {
        const db = firebase.firestore();

        db.collection('diplomados').get().then((snapshot) => {
            let diplomados = snapshot.docs.map((doc) => { return { id: doc.id, ...doc.data() } });
            diplomados = diplomados.sort((a, b)=>b.created - a.created)
            this.setState({ diplomados, loading: false });

        }).catch(error => console.log(error));
    }

    getColumns = (activeText) => {
        return [
            { label: 'Título', field: 'title', sort: 'asc' },
            { label: 'External/CxVet', field: 'external', sort: 'asc' },
            { label: 'Editar', field: 'editar', sort: 'asc' },
            { label: "Cambiar Estatus", field: 'desactivar', sort: 'asc' },
            { label: "Ver vista previa", field: 'vista_previa', sort: 'asc' },
            { label: "Creación", field: 'creado', sort: 'asc' },
        ];
    }

    getData = () => {
        const rows = [];
        this.state.diplomados.map((diplomado, index) => {
            rows.push({
                title: diplomado.title,
                external: diplomado.external ? "External" : "CxVet",
                editar: <button type="button" className="btn btn-primary" onClick={() => this.props.history.push(`/admin/diplomados/diplomado/${diplomado.id}`)} >Editar</button>,
                desactivar: <button type="button" className="btn btn-primary" onClick={() => {
                    if (diplomado.active) {
                        this.setState({ selectedDiplomado: diplomado.id, selectedDiplomadoIndex: index, eliminarModalOpen: true, activadoModalOpen: false })
                    }
                    else {
                        this.setState({ selectedDiplomado: diplomado.id, selectedDiplomadoIndex: index, activadoModalOpen: true, eliminarModalOpen: false })
                    }
                    this.changeActiveStatus(diplomado.id, diplomado.active, index)

                }}>{diplomado.active ? "Desactivar" : "Activar"}</button>,
                vista_previa: <button type="button" className="btn btn-primary" target="_blank" onClick={() => window.open(`/admin/diplomados/diplomado-vista-previa/${diplomado.id}`, '_blank')}> Ver vista previa </button>,
                creado: getDateFormattedFirstDay(diplomado.created)
            });
        });
        return { columns: this.getColumns(), rows };

    }

    changeActiveStatus = (diplomadoId, active, index) => {
        const db = firebase.firestore();

        db.collection('diplomados').doc(diplomadoId).set({
            active: !active
        }, { merge: true }).then((dipl) => {

            let diplomadosCopy = this.state.diplomados
            diplomadosCopy[index].active = !active
            this.setState({ diplomados: diplomadosCopy })
        }).catch(error => console.log(error));
    }

    deleteDiplomado = () => {
        const db = firebase.firestore();
        db.collection('diplomados').doc(this.state.selectedDiplomado).delete().then((dipl) => {
            this.updateDiplomadoTable()
            this.deleteDiplomadoFiles()

        }).catch(error => {
            console.log(error)
        })
    }

    updateDiplomadoTable = () => {
        let diplomadosCopy = this.state.diplomados
        diplomadosCopy.splice(this.state.selectedDiplomadoIndex, 1);
        this.setState({ diplomados: diplomadosCopy })
    }


    deleteDiplomadoFiles = () => {
        const db = firebase.firestore();
        let storageRef = firebase.storage().ref()
        var imageRef = storageRef.child(`diplomados/${this.state.selectedDiplomado}/Preview-Image`);
        var pdfRef = storageRef.child(`diplomados/${this.state.selectedDiplomado}/Preview-pdf`);
        var listRef = storageRef.child(`diplomados/${this.state.selectedDiplomado}`);

        let promises = []
        promises.push(imageRef.delete())
        promises.push(pdfRef.delete())


        listRef.listAll().then((res) => {

            res.prefixes.forEach((folderRef) => {
                let folderPath = folderRef.toString().split("gs://cvtdev.appspot.com/")[1]

                storageRef.child(folderPath).listAll().then((subRes) => {

                    subRes.items.forEach((itemRef) => {
                        let itemPath = itemRef.toString().split("gs://cvtdev.appspot.com/")[1]
                        promises.push(storageRef.child(itemPath).delete())

                    })
                    Promise.all(promises).then(() => {
                        this.setState({ activadoModalOpen: false, eliminarModalOpen: false })
                        window.alert("El diplomado fue eliminado con éxito.")
                    }).catch((error) => {
                        console.log(error)
                    });

                })
            });

        }).catch((error) => {
            console.log(error)
        });
    }

    render() {
        return (
            <div className="content-wrapper p-5">
                <div className="row">
                    <div className="col-md-12">
                        <h1>Diplomados</h1>
                        <DataTableComponent data={this.getData()} />
                        <div className="btn-row"  >
                            <a role="button" href="/admin/diplomados/crear-diplomado" className="btn btn-primary">Crear nuevo diplomado</a>
                        </div>
                    </div>
                </div>

                {this.state.activadoModalOpen && <ActivadoModal show={this.state.activadoModalOpen} onHide={() => this.setState({ activadoModalOpen: false })} />}
                {this.state.eliminarModalOpen && <EliminarModal show={this.state.eliminarModalOpen} onHide={() => this.setState({ eliminarModalOpen: false })} deleteDiplomado={() => {
                    if (window.confirm("¿Estás seguro de que te gustaría eliminar este diplomado? Una vez que lo haga, no podrá deshacerlo."))
                        this.deleteDiplomado()
                }} />}
                <Spinner show={this.state.loading} />
            </div>
        );
    }
}
DiplomadosComponent.contextType = CurrentUser;
export default DiplomadosComponent;