import firebase from 'firebase';

export function getExamScore(exam) {
    let points = 0
    exam.questions.forEach(question => {
        points += question.userAnswer == question.correctAnswer ? 1 : 0
    })
    let percent = exam.questions.length > 0 ? parseFloat((parseFloat(points * 100) / parseFloat(exam.questions.length)).toFixed(2)) : 0.00

    return { points, percent, total: exam.questions.length }
}

export function examIsCompleted(exam) {
    let completed = true
    exam.questions.forEach(question => {
        if (question.userAnswer === -1)
            completed = false
    })

    return completed
}

export function examAllCorrect(exam) {
    let score = 0
    exam.questions.forEach(question => {
        score += question.userAnswer == question.correctAnswer ? 1 : 0
    })
    return exam.questions.length - score <= 0
}

export function hasPassedExam(exam, threshold) {
    return getExamScore(exam).percent >= threshold
}

export function saveUserExamProgress(exam, diplomadoId, currentUserId, hasPassedExam) {
    return new Promise(async (resolve, reject) => {
        for (let index = 0; index < exam.questions.length; index++) {
            let question = exam.questions[index]
            await firebase.firestore().collection("diplomados").doc(diplomadoId).collection("exams").doc(exam.id).collection("questions").doc(question.id).collection("userAnswers").doc(currentUserId).set({
                userAnswer: parseInt(question.userAnswer)
            }).catch(error => { reject(error) })
        }

        if (hasPassedExam) {
            await firebase.firestore().collection("diplomados").doc(diplomadoId).collection("exams").doc(exam.id).update({ hasPassed: firebase.firestore.FieldValue.arrayUnion(currentUserId) }).catch(error => { reject(error) })
        }
        resolve(true)
    })
}

export function resetExamProgress(exam, diplomadoId, currentUserId) {
    return new Promise(async (resolve, reject) => {
        for (let index = 0; index < exam.questions.length; index++) {
            await firebase.firestore().collection("diplomados").doc(diplomadoId).collection("exams").doc(exam.id).collection("questions").doc(exam.questions[index].id).collection("userAnswers").doc(currentUserId).set({ userAnswer: -1 }).catch(error => { reject(error) })
        }
        resolve(true)
    })
}

export function getAllExams(diplomadoId) {
    return new Promise(async (resolve, reject) => {
        let allExams = await firebase.firestore().collection("diplomados").doc(diplomadoId).collection("exams").orderBy("indexVideo","asc").get().catch(error => reject(error))
        resolve(allExams.docs.length > 0 ? allExams.docs.map(el => ({ id: el.id, ...el.data() })) : [])
    })
}

export function getAllExamsWithQuestions(diplomadoId) {
    return new Promise(async (resolve, reject) => {
        try {
            let allExams = await getAllExams(diplomadoId).catch(error => reject(error));
            for (let index = 0; index < allExams.length; index++) {
                let allQuestions = await firebase.firestore().collection("diplomados").doc(diplomadoId).collection("exams").doc(allExams[index].id).collection('questions').orderBy("originalOrder", "asc").get().catch(error => reject(error))
                allExams[index].questions = allQuestions.docs.map(el => ({ id: el.id, ...el.data() }))
            }
            resolve(allExams)
        }
        catch (err) {
            //window.alert(err)
            reject(err)
        }

    })
}

export function getExamUserAnswers(diplomadoId, exam, currentUserId, name = "") {

    return new Promise(async (resolve, reject) => {
        let examCopy = Object.assign({}, {...exam, questions: exam.questions.map(el => ({...el}))})//deep copy
        examCopy.userHasPassed = exam.hasPassed.includes(currentUserId)
        examCopy.userHasCompleted = true
        let one = ""
        let two = ""

        for (let index = 0; index < exam.questions.length; index++) {

            try {
                one = Date.now()
                let userAnswer = await firebase.firestore().collection("diplomados").doc(diplomadoId).collection("exams").doc(exam.id).collection('questions').doc(exam.questions[index].id).collection('userAnswers').doc(currentUserId).get()
                examCopy.questions[index].userAnswer = userAnswer.data().userAnswer
                two = Date.now()

                if (userAnswer.data().userAnswer === -1) {
                    examCopy.questions = examCopy.questions.map(el => ({...el, userAnswer:-1}))
                    examCopy.userHasCompleted = false
                    break
                }
            }
            catch (err) {
                examCopy.questions = examCopy.questions.map(el => ({...el, userAnswer:-1}))
                examCopy.userHasCompleted = false
                break
            }
        }

        resolve(examCopy)
    })
}

export function getUserExamData(diplomadoId, currentUserId) {
    return new Promise(async (resolve, reject) => {
        let exams = await getAllExamsWithQuestions(diplomadoId).catch(error => reject(error))
        for (let index = 0; index < exams.length; index++) {
            exams[index] = await getExamUserAnswers(diplomadoId, exams[index], currentUserId).catch(error => reject(error))
        }
        resolve(exams)
    })

}

export function getExamUserStatistics(exam, threshold) {

}
//////////delete for testing/////////////
/////////////////////////////////////////
export function deleteUserAnswers(diplomadoId, currentUserId) {
    return new Promise(async (resolve, reject) => {
        const db = firebase.firestore().collection('diplomados').doc(diplomadoId).collection("exams")
        let allExams = await getAllExamsWithQuestions(diplomadoId).catch(error => reject(error))

        for (let index = 0; index < allExams.length; index++) {
            let exam = allExams[index]
            for (let indexQ = 0; indexQ < exam.questions.length; indexQ++) {
                await db.doc(exam.id).collection('questions').doc(exam.questions[indexQ].id).collection('userAnswers').doc(currentUserId).delete().catch(error => reject(error))
            }

            await db.doc(exam.id).update({ hasPassed: firebase.firestore.FieldValue.arrayRemove(currentUserId) }).catch(error => reject(error))
        }
        resolve(true)
    })
}
//////////////END delete/////////////////
/////////////////////////////////////////