import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
var moment = require("moment");
moment.locale("es");

export const ActivadoModal = (props) => {
    return (
        <Modal {...props} size="lg" className="progress-modal" centered >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    <h5 className="modal-title" id="modalTerminoLabel">Aula activado</h5>
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <p className="text-center mb-0">Este aula se ha activado correctamente.</p>
            </Modal.Body>

            <Modal.Footer >
                <button role="button" className="btn btn-primary" onClick={props.onHide}>Cancelar</button>
            </Modal.Footer>
        </Modal>
    );
}

export default ActivadoModal;