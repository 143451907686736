import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';

class UploadFileAlertComponent extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <Modal {...this.props} size="lg" className="progress-modal" centered >
                <Modal.Body>
                    <p className="text-center mb-0">Subiendo {this.props.file.name}</p>
                    <p className="text-center h2 mb-3 "> {this.props.file.state}%</p>
                    <div className="progress">
                        <div className={"progress-bar bar-" + this.props.file.state} role="progressbar" ></div>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }
}
export default UploadFileAlertComponent;