import React, { Component } from 'react';
import firebase from 'firebase';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import ProgressBar from 'react-bootstrap/ProgressBar'
import DataTableComponent from '../../admin/core/DataTableComponent';
import CurrentUser from "../core/PublicUserSessionContext";
import CKEditor from '@ckeditor/ckeditor5-react';
import Spinner from "../../commons/Spinner";
import UploadFileAlertComponent from '../../admin/core/UploadFileAlertComponent';
import DatePicker from 'react-date-picker';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import Carousel from 'react-simply-carousel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlayCircle } from '@fortawesome/free-solid-svg-icons'
import VerifyingPurchaseModal from './VerifyingPurchaseModal'
import ErrorPurchaseModal from './ErrorPurchaseModal'
import SuccessModal from './SuccessModal'

import DiplomadoItemComponent from './DiplomadoItemComponent';
import ImagenPdf from '../../../resources/images/pdf-image.jpg';
import { registrar } from "../../../resources/js/eventos";
import { PayPalButton } from "react-paypal-button-v2";

class DiplomadoPreviewComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      currentUserId: "",
      diplomadoId: this.props.match.params.id,
      title: "",
      paypal: "",
      verifyingWebhook: false,
      showSuccessModal: false,
      showErrorModal: false
    }
  }


  componentDidMount = () => {
    const db = firebase.firestore();
    const diplomadoId = this.props.match.params.id
    let currentUser = this.context.currentUser
    registrar({ categoria: "DIPLOMADO_PREVIEW_PAGE", etiqueta: this.props.match.params.id, valor: 0, accion: this.context.currentUser.type });

    //get diplomado
    db.collection('diplomados').doc(diplomadoId).get().then((diplomado) => {
      if (!diplomado.data().active)
        this.props.history.push('/mis-diplomados')
      else {
        console.log("diplomado data: ", diplomado.data());
        this.setState({ ...diplomado.data(), currentUserId: currentUser.id, loading: false });
      }

    }).catch(error => {
      console.log(error)
      this.setState({ loading: false })
    });

  }

  waitForWebhook = async (details) => {
    firebase.firestore().collection("diplomados").doc(this.state.diplomadoId).collection("transactions").add({ user: this.state.currentUserId, transaction_data_frontend: JSON.stringify(details), timestamp: Date.now(firebase.firestore.FieldValue.serverTimestamp()) })

    firebase.firestore().collection('diplomados').doc(this.state.diplomadoId).onSnapshot(diplomado => {
      if (diplomado.data().users.includes(this.state.currentUserId)) {
        this.setState({showSuccessModal: true})
      }
    }, error => {
      console.log("Error: ", error);
    });

  }

  render() {
    let currentUserId = this.context.currentUser.id
    let costAmount = parseFloat(this.state.cost)
    return (
      <React.Fragment>
        <Spinner show={this.state.loading} />

        <div className="content-wrapper p-5">
          <h1 className="mb-5">{this.state.title}</h1>
          <div className="row" >



            <DiplomadoItemComponent key={this.state.diplomadoId} previewImageURL={this.state.previewImageURL} title={this.state.title} buy={"Para acceder a este diplomado, puede adquirirlo con el botón \"Comprar Ahora\". A continuación se muestra un pdf con información preliminar sobre este diplomado."} link={`/mis-diplomados/diplomado-preview/${this.state.diplomadoId}`} />

            <div name="guia ">
              <div className="text-center tarjeta-bold p-4">
                <p className="d-none h5 mb-3">Descarga la guía en PDF</p>
                <img className="mb-3 d-none" src={ImagenPdf} />
                <a className="  btn btn-secondary btn-lg btn-pdf" target="_blank" href={this.state.previewPdfURL}>
                  DESCARGA INFORMACIÓN PREVIA
               </a>
              </div>
            </div>
          </div>

          <PayPalButton
            shippingPreference="NO_SHIPPING"
            createOrder={(data, actions) => {
              return new Promise((resolve, reject) => {

                return actions.order.create({

                  purchase_units: [{
                    description: `${this.state.title}`,
                    reference_id: `${this.state.diplomadoId}-${this.state.currentUserId}`,
                    amount: {
                      currency_code: "USD",
                      value: `${costAmount}`,
                    },

                  }],


                }).then((d) => {
                  resolve(d)
                })

              })
            }}
            onSuccess={(details, data) => {
              this.setState({ verifyingWebhook: true })
              console.log(JSON.stringify(details))
              this.waitForWebhook(details)
            }}
            onError={(error) => {
              this.setState({ verifyingWebhook: false, showErrorModal: true })
              firebase.firestore().collection("diplomados").doc(this.state.diplomadoId).collection("transactions").add({ user: this.state.currentUserId, error_frontend: JSON.stringify(error), timestamp: Date.now(firebase.firestore.FieldValue.serverTimestamp()) })
            }}
          />
        </div>
        
        <VerifyingPurchaseModal show={this.state.verifyingWebhook} verifying={this.state.verifyingWebhook} />
        <SuccessModal show={this.state.showSuccessModal} goToDiplomado={() =>{this.state.external ? this.props.history.push(`/mis-diplomados/diplomado/${this.state.diplomadoId}`) : this.props.history.push(`/mis-diplomados/diplomado-CxVet/${this.state.diplomadoId}`)}} diplomado={this.state.title} onHide={() => {this.state.external ? this.props.history.push(`/mis-diplomados/diplomado/${this.state.diplomadoId}`) : this.props.history.push(`/mis-diplomados/diplomado-CxVet/${this.state.diplomadoId}`)}}/>
        <ErrorPurchaseModal show={this.state.showErrorModal} onHide={() => this.setState({ showErrorModal: false })}/>
      </React.Fragment >
    );
  }
}
DiplomadoPreviewComponent.contextType = CurrentUser;
export default DiplomadoPreviewComponent