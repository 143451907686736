import React, { Component } from "react";
import firebase from 'firebase';

import { Redirect } from 'react-router-dom';
import logoCxvet from '../../../resources/images/logo.svg';
import iconUser from '../../../resources/images/icon-user.svg';


class AdminNavigationComponent extends Component {
    constructor(props) {
        super(props);
        this.state = { logout: false, showMobileBar: false };
    }
    toggleMobileBar = () => {
        this.setState({ showMobileBar: !this.state.showMobileBar });
    }
    logout = () => {
        firebase.auth().signOut().then(() => {
            console.log('logout');
            this.setState({ logout: true });
        }).catch(function (error) {
            console.log(error);
        });
    }

    redirect = () => {
        this.setState({ logout: false });
        return <Redirect to="/" />;
    }

    render() {
        let currentUser = this.context.currentUser;

        if (this.state.logout) {
            console.log("AdminNAvComp: estoy redirigiendo al logout");
            return this.redirect();
        }

        return (
            <div className="row">
                <div className="col-md-12 p-0">
                    <nav className=" navbar navbar-expand-sm navbar-primary bg-primary ">
                        <div className="container-fluid home ">
                            <div className="order-1 order-md-0 ">

                                <ul className="navbar-nav mr-auto d-flex flex-row align-items-center">
                                    <li className="mr-2">
                                        <img width="30" src={iconUser} />
                                    </li>
                                    <li className="">

                                        <div className="text-light">Bienvenid@ Administrador</div>
                                    </li>
                                </ul>


                            </div>

                            <div className="  order-2 flex-row">


                                <ul className="navbar-nav ml-auto text-right">
                                    <li className="nav-item d-none d-sm-flex">
                                        <a className="nav-link text-light" href="/index">Regresar a CxVet</a>
                                    </li>

                                    <li className="nav-item">
                                        <a className="nav-link text-light " href="" onClick={this.logout}>Cerrar sesión</a>
                                    </li>
                                </ul>

                            </div>
                        </div>
                    </nav>
                    <nav className="navbar-admin navbar navbar-expand-md mx-3 bg-light navbar-light">
                        <div className="mx-auto order-0 justify-content-between d-flex w-100 ">
                            <a className="navbar-brand p-4 " href="/">
                                <img src={logoCxvet} />
                            </a>
                            <button type="button" data-toggle="collapse" data-target=".dual-collapse2"
                                onClick={this.toggleMobileBar}
                                className={`navbar-toggler ${this.state.showMobileBar ? "" : "collapsed"}`} >
                                <span className="navbar-toggler-icon"></span>
                            </button>
                        </div>
                        <div className={`navbar-collapse collapse w-100 order-2 dual-collapse2 ${this.state.showMobileBar ? "show" : ""} `}>
                            <ul className="navbar-nav ml-auto text-center w-100 justify-content-end ml-lg-0 d-lg-flex align-items-center">
                                {/*<li className="nav-item ">
                                    <a className="nav-link" href="estadisticas.html">Estadisticas</a>
                                </li>*/}
                                <li className="nav-item ">
                                    <a className="nav-link" href="/admin/diplomados">Diplomados</a>
                                </li>
                                <li className="nav-item ">
                                    <a className="nav-link" href="/admin/aulas">Aulas</a>
                                </li>
                                <li className="nav-item ">
                                    <a className="nav-link" href="/admin/organizations">Organizaciones</a>
                                </li>
                                <li className="nav-item ">
                                    <a className="nav-link" href="/admin/estadisticas">Estadísticas</a>
                                </li>
                                <li className="nav-item ">
                                    <a className="nav-link" href="/admin/mailing">Enviar<br /> correo</a>
                                </li>
                                <li className="nav-item ">
                                    <a className="nav-link" href="/admin/procedures">Cirugías</a>
                                </li>
                                <li className="nav-item ">
                                    <a className="nav-link" href="/admin/users">Usuarios</a>
                                </li>
                                <li className="nav-item ">
                                    <a className="nav-link" href="/admin/users/premium-manual">Premium Manual</a>
                                </li>
                                <li className="nav-item ">
                                    <a className="nav-link" href="/admin/events">Próximos <br />eventos</a>
                                </li>
                                <li className="nav-item ">
                                    <a className="nav-link" href="/admin/newsletter">Newsletter</a>
                                </li>


                            </ul>
                        </div>
                    </nav>
                </div>
            </div>
        );
    }
}
export default AdminNavigationComponent;