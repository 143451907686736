import React, { Component } from 'react';
import firebase from 'firebase';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import Chart from "react-apexcharts";

import DataTableComponent from '../core/DataTableComponent';
import CurrentUser from "../../public/core/PublicUserSessionContext";
import CKEditor from '@ckeditor/ckeditor5-react';
import Spinner from "../../commons/Spinner";
import UploadFileAlertComponent from '../core/UploadFileAlertComponent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import CirugiasModal from './modals/CirugiasModal'
import AdministrarVideoModal from './modals/AdministrarVideoModal'
import ExamModal from './modals/ExamModal'
import ExamEditModal from './modals/ExamEditModal'
import LoadingStatisticsModal from './modals/LoadingStatisticsModal'
import ProgressBar from 'react-bootstrap/ProgressBar'

import { getUserProgress, getDiplomadoVideoData, setDiplomadoVideoCompleted, getDiplomado, } from '../../../service/diplomados'
import { getExamScore, examIsCompleted, examAllCorrect, hasPassedExam, getExamUserAnswers, getExamUserStatistics, deleteUserAnswers } from '../../../service/exams'

import upArrow from '../../../resources/images/up-arrow.png'
import downArrow from '../../../resources/images/down-arrow.png'
const paletaColores = [
    "#44A026",
    "#62D60A",
    "#047bda",
    "#45abec",
    "#7C08C4",
    "#BA3FEB",
    "#FC66B7",
    "#D60074",
    "#FF3C3C",
    "#DB000E",
    "#E67E00",
    "#FCAD5A",
    "#FDE450",
    "#EBCA12",
    "#94A5A5",
    "#BAC3C3"
]

class DiplomadoFormComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            editing: false,
            diplomadoId: undefined,
            loading: true,
            fileModalShow: false,
            fileStatus: 0,
            errors: [],
            resourceErrors: { videos: [], pdfs: [], images: [] },
            active: false,
            organization: "",
            adminDiplomado: "",
            cost: "",
            threshold: 80.00,

            title: "",
            description: "",
            previewImageFile: undefined,
            previewImageURL: "",
            previewPdfFile: undefined,
            previewPdfURL: "",
            certificateFile: undefined,
            certificateURL: "",
            typeDiplomado: "",
            allVideos: [],
            videos: [],
            pdfs: [],
            images: [],
            exams: [],
            beganDiplomadoTimestamps: {},
            completedDiplomadoTimestamps: {},
            diplomadoUsersToRemove: [],
            diplomadoUsersToAdd: [],
            created: Date.now(),

            videosToDelete: [],
            questionsToDelete: [],
            examsToDelete: [],

            editingNewVideo: {
                title: "",
                description: "",
                times: [],
                pdfs: [],
                images: [],
                url: "",
            },
            editingVideo: false,
            editingPlatformVideos: false,
            indexEditing: -1,
            filterdTableData: [],

            editingExam: false,
            indexEditingExam: -1,

            organizations: [],
            allUsers: [],
            diplomadoUsers: [],
            search: "",
            statisticsProgress: 0,
            statisticsExamProgress: 0,

            showOnlyAssigned: false,
            optionsLine: {
                colors: paletaColores,
                chart: {
                    type: 'bar',
                    height: 750,
                    width: 750,
                    stacked: true,
                    stackType: "100%",
                    animations: {
                        enabled: false
                    }
                },
                dataLabels: {
                    colors: paletaColores,
                    enabled: true,
                    formatter: (val, opt) => {
                        // console.log("CUSTOM DATALABEL");
                        // console.log(val);
                        // console.log(opt);

                        //val = separadoComas(Math.round(val));
                        if (opt["w"]["config"]["chart"]["stackType"] === "100%") {
                            val = `${parseFloat(val).toFixed(2)} %`
                        }
                        return val;
                    }
                },
                responsive: [{
                    breakpoint: 480,
                    options: {
                        legend: {
                            position: 'bottom',
                            offsetX: -10,
                            offsetY: 0,
                            colors: paletaColores
                        }
                    }
                }],
                xaxis: {
                    categories: [],
                },
                yaxis: {
                    decimalsInFloat: 2
                },
                plotOptions: {
                    bar: {
                        horizontal: true,
                    }
                },
                fill: {
                    opacity: 1
                },
                legend: {
                    colors: paletaColores,
                    position: 'right',
                    offsetX: 0,
                    offsetY: 400,
                },
            },
            optionsPie: {

                colors: paletaColores,
                plotOptions: {
                    pie: {
                        dataLabels: {
                            minAngleToShowLabel: 0,
                        }
                    },
                },
                chart: {
                    width: 190,
                    type: 'pie',
                },
                labels: ["Exam1", "Exam2", "Exam3", "Exam4"],
                tooltip: {
                    y: {
                        title: "TITTLE"
                    }
                },
                responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 100
                        },
                        legend: {
                            position: 'bottom'
                        }
                    }
                }],

            },
        }
    }

    componentDidMount = () => {

        const db = firebase.firestore();
        const diplomadoId = this.props.match.params.id
        //get videos
        db.collection('procedures').get().then((proceduresColl) => {
            let allVideos = proceduresColl.docs.map((doc) => { return { id: doc.id, ...doc.data() } });
            this.setState({ allVideos })

        }).catch(error => console.log(error));

        //get organizations
        db.collection('organizations').get().then((organizationsColl) => {
            let organizations = organizationsColl.docs.map((doc) => { return { id: doc.id, ...doc.data() } });
            this.setState({ organizations })

        }).catch(error => console.log(error));

        //get users
        db.collection('users').orderBy("email", "asc").get().then((usersColl) => {
            let allUsers = usersColl.docs.map((doc) => { return { id: doc.id, ...doc.data() } });
            this.setState({ allUsers })
            this.getUserData()

        }).catch(error => console.log(error));

        //get tags
        db.collection('tags').get().then((tagsColl) => {
            let allTags = tagsColl.docs.map((doc) => { return { id: doc.id, ...doc.data() } });
            this.setState({ allTags })

        }).catch(error => console.log(error));

        if (diplomadoId) {
            //get diplomado information if editing
            db.collection('diplomados').doc(diplomadoId).get().then((diplomado) => {
                console.log("diplomado data: ", diplomado.data());

                db.collection('diplomados').doc(diplomadoId).collection('videos').orderBy("index", "asc").get().then((videosColl) => {
                    let videos = videosColl.docs.map((doc) => { return { id: doc.id, ...doc.data() } });
                    this.getInitialExamData(diplomadoId)
                    this.setState({
                        ...diplomado.data(), videos: this.setPlatformVideosData(videos), typeDiplomado: diplomado.data().external ? "external" : "CxVet",
                        diplomadoUsers: diplomado.data().users || [], diplomadoId, editing: true, loading: false
                    });

                })

            }).catch(error => console.log(error));
        }
        else
            this.setState({ diplomadoId: firebase.firestore().collection("diplomados").doc().id, loading: false })

    }

    getInitialExamData = (diplomadoId) => {
        let exams = []

        firebase.firestore().collection('diplomados').doc(diplomadoId).collection('exams').orderBy("indexVideo","asc").get().then((theExams) => {
            let noIndexExams = []
            let yesIndexExams = []
            theExams.docs.forEach(ex => {
                if(ex.data().indexVideo < 0)
                    noIndexExams.push(ex)
                else
                    yesIndexExams.push(ex)
            })
            let allExams = [...yesIndexExams, ...noIndexExams]
            allExams.forEach((exam, index) => {

                exams.push({ id: exam.id, title: exam.data().title, indexVideo: (exam.data().indexVideo + ""), questions: [], hasPassed: exam.data().hasPassed || [], active: !!exam.data().active })

                firebase.firestore().collection('diplomados').doc(diplomadoId).collection('exams').doc(exam.id).collection('questions').orderBy("originalOrder", "asc").get().then((allQuestions) => {
                    let questions = []
                    allQuestions.docs.forEach((question, indexQ) => {
                        questions.push({ id: question.id, ...question.data() })
                    })
                    exams[index].questions = questions
                    this.setState({ exams })

                }).catch(error => console.log(error));
            })

        }).catch(error => console.log(error));
    }

    setPlatformVideosData = (videos) => {
        if (!videos || videos.length === 0)
            return []

        let videosCopy = videos.map(video => {
            if (video.procedureId) {
                let platformVideo = this.state.allVideos.find(vid => vid.id === video.id)
                return { id: video.id, procedureId: video.procedureId, ...platformVideo, completed: video.completed || [], active: !!video.active }
            }
            else
                return video
        })

        return videosCopy
    }

    handleSelectAllCheck = (fullArray, typeArray) => {
        if (this.state[fullArray].length === this.state[typeArray].length) {
            this.setState({ [typeArray]: [] })
        }
        else {
            this.setState({ [typeArray]: this.state[fullArray].map((doc) => doc.id) })
        }
    }

    handleUsersCheck = (event) => {
        let diplomadoUsersCopy = this.state.diplomadoUsers
        const index = this.state.diplomadoUsers.indexOf(event.target.name);
        let toRemove = this.state.diplomadoUsersToRemove
        let toAdd = this.state.diplomadoUsersToAdd

        if (index > -1) {
            diplomadoUsersCopy.splice(index, 1);
            toRemove.push(event.target.name)
            const indexAdded = toAdd.indexOf(event.target.name);
            if (indexAdded > -1)
                toAdd.splice(indexAdded, 1);
        }
        else {
            diplomadoUsersCopy.push(event.target.name);
            toAdd.push(event.target.name)
            const indexRemoved = toRemove.indexOf(event.target.name);
            if (indexRemoved > -1)
                toRemove.splice(indexRemoved, 1);
        }
        this.setState({ diplomadoUsers: diplomadoUsersCopy, diplomadoUsersToRemove: toRemove, diplomadoUsersToAdd: toAdd })
    }
    getUserColumns = () => {
        return [
            { label: 'Agregar', field: 'select', sort: 'asc' },
            { label: 'Nombre', field: 'name', sort: 'asc' },
            { label: 'Correo', field: 'email', sort: 'asc' },
            { label: 'Membresía', field: 'type', sort: 'asc' },
        ];
    }
    getUserData = () => {
        const rows = [];

        this.state.allUsers.map(user => {

            if ((this.state.showOnlyAssigned && this.state.diplomadoUsers.includes(user.id) || !this.state.showOnlyAssigned)) {
                rows.push({
                    select: <div className="form-check form-check-inline">
                        <input name={user.id} checked={((this.state.diplomadoUsers.includes(user.id)) || this.state.showOnlyAssigned) ? true : false} className="form-check-input" onChange={this.handleUsersCheck} type="checkbox" />
                    </div>,
                    name: user.name || "",
                    email: user.email || "",
                    type: user.type || "",
                });
            }
        });
        //this.setState({rows: rows, rowsFiltered: rows})
        return { columns: this.getUserColumns(), rows: rows };
    }
    getUserExamColumns = () => {
        let userColumns = [
            { label: 'Nombre', field: 'name', sort: 'asc' },
            { label: 'Correo', field: 'email', sort: 'asc' }
        ]
        this.state.exams.forEach((exam, index) => {
            userColumns.push({ label: exam.title, field: `${exam.id}Score`, sort: 'asc' })
        })
        userColumns.push({ label: 'Promedio %', field: 'promedio', sort: 'asc' })
        userColumns.push({ label: 'Progreso diplomado', field: 'progreso', sort: 'asc' })

        return userColumns
    }
    getUserExamData = async () => {
        let rows = [];
        let obj = {}

        if (this.state.exams.length > 0 && this.state.diplomadoUsers.length > 0 && this.state.allUsers.length > 0 && !this.state.userColumns) {
            this.setState({ loadingStatistics: true, statisticsProgress: 0.01 })

            for (let x = 0; x < this.state.diplomadoUsers.length; x++) {
                let rowData = {}

                let user = this.state.diplomadoUsers[x]
                let found = this.state.allUsers.find(el => el.id === user)
                rowData.name = found.name
                rowData.email = found.email

                let average = 0.00
                let passedExams = 0
                let inProgress = 0
                for (let y = 0; y < this.state.exams.length; y++) {
                    let userExam = await getExamUserAnswers(this.state.diplomadoId, this.state.exams[y], user, rowData.name)
                    let score = userExam.userHasCompleted ? getExamScore(userExam).percent : 0.00
                    passedExams += score >= this.state.threshold
                    inProgress = userExam.userHasCompleted && score < this.state.threshold
                    average += score

                    rowData[`${userExam.id}Score`] = score + "%"
                    rowData[`${userExam.id}ScoreNum`] = score
                    rowData[`${userExam.id}`] = userExam
                    rowData[`${userExam.id}passed`] = score >= this.state.threshold
                    rowData[`${userExam.id}failed`] = (score < this.state.threshold && userExam.userHasCompleted)
                    rowData[`${userExam.id}completed`] = userExam.userHasCompleted
                    rowData[`${userExam.id}questions`] = rowData[`${userExam.id}`].questions
                }
                rowData.progreso = (parseFloat(passedExams * 100) / this.state.exams.length) >= 100 ? "Completado" : (parseFloat(passedExams * 100) / this.state.exams.length).toFixed(2) + "%"
                rowData.progresoNum = parseFloat(passedExams * 100) / this.state.exams.length
                rowData.inProgress = inProgress

                average = (parseFloat(average) / parseFloat(this.state.exams.length)).toFixed(2)
                rowData.promedio = average + "%"

                rows.push(rowData)
                this.setState({ statisticsProgress: x === 0 ? .01 : (parseFloat(x * 100) / parseFloat(this.state.diplomadoUsers.length)).toFixed(2) })
            }
            this.setState({ userRows: rows, loadingStatistics: false, statisticsProgress: 0 })
            this.getExamScoresData(rows)
        }
        this.setState({ userColumns: this.getUserExamColumns() })

    }
    getExamScoresColumns = () => {
        return [
            { label: 'Titulo', field: 'title', sort: 'asc' },
            { label: '# preguntas', field: 'questions', sort: 'asc' },
            { label: '# Completaron examen', field: 'completed', sort: 'asc' },
            { label: 'Promedio calificación', field: 'average', sort: 'asc' },
        ];
    }
    getExamScoresData = (userRows) => {
        const rows = [];

        this.state.exams.map((exam, index) => {
            let totalScore = 0
            let numCompleted = 0

            userRows.forEach(user => {
                if (user[`${exam.id}completed`]) {
                    numCompleted++
                    totalScore += parseFloat(user[`${exam.id}ScoreNum`])
                }
            })

            rows.push({
                title: exam.title || "",
                questions: exam.questions ? exam.questions.length : 0,
                completed: numCompleted,
                average: (parseFloat(totalScore) / parseFloat(numCompleted)).toFixed(2) + "%"
            });
        });

        this.setState({ examColumns: this.getExamScoresColumns(), examRows: rows })
    }
    getPieDiplomadoData = () => {
        let completed = 0
        let inProgress = 0
        let noProgress = 0

        let users = this.state.userRows || []
        users.forEach(user => {
            completed += user.progresoNum >= 100
            inProgress += user.inProgress
            noProgress += user.progresoNum == 0
        })
        return { completed, inProgress, noProgress }
    }
    getPieData = (exam) => {
        let passed = 0
        let failed = 0
        let completed = 0

        let users = this.state.userRows || []
        users.forEach(user => {

            passed += user[`${exam.id}passed`]
            failed += user[`${exam.id}failed`]
            completed += user[`${exam.id}completed`]

        })
        //window.alert(completed)
        return { passed, failed, completed, total: this.state.userRows ? this.state.userRows.length : 0 }
    }

    getLineData = (exam) => {
        let options = Object.assign({}, { ...this.state.optionsLine, xaxis: Object.assign({}, this.state.optionsLine.xaxis) })
        options.xaxis.categories = exam.questions.map(q => q.question)
        options.colors = ["#00cf6b", "#ff0000"]

        let users = this.state.userRows || []
        let dataCorrect = []
        let dataIncorrect = []

        exam.questions.forEach((question, index) => {
            let numCorrect = 0
            let numIncorrect = 0

            users.forEach(user => {
                let currExam = user[`${exam.id}`]
                let userAnsweredQuestion = currExam.questions[index].userAnswer >= 0
                if (userAnsweredQuestion && question.correctAnswer === currExam.questions[index].userAnswer) {
                    numCorrect++
                }
                else if (userAnsweredQuestion && question.correctAnswer !== currExam.questions[index].userAnswer)
                    numIncorrect++
            })
            dataCorrect.push((numCorrect + numIncorrect) === 0 ? -1 : numCorrect)
            dataIncorrect.push((numCorrect + numIncorrect) === 0 ? -1 : numIncorrect)

        })

        let series = [{ data: dataCorrect, name: 'Correcto' }, { data: dataIncorrect, name: 'Incorrecto' }]
        return { options, series: series }
    }

    handleInputChange = (event) => {
        this.setState({ [event.target.name]: event.target.value, errors: [] })
    }

    handleFileInputChange = (event, index = -1) => {
        let url = URL.createObjectURL(event.target.files[0])
        if (event.target.name === "pdf") {
            let pdfs = this.state.editingNewVideo.pdfs
            pdfs[index] = { title: pdfs[index].title, file: event.target.files[0], filename: event.target.files[0].name, url }
            this.setState({ editingNewVideo: { ...this.state.editingNewVideo, pdfs } })
        }
        else if (event.target.name === "image") {
            let images = this.state.editingNewVideo.images
            images[index] = { title: images[index].title, file: event.target.files[0], filename: event.target.files[0].name, url }
            this.setState({ editingNewVideo: { ...this.state.editingNewVideo, images } })
        }
        else {
            this.setState({ editingNewVideo: { ...this.state.editingNewVideo, file: event.target.files[0], filename: event.target.files[0].name, url: url } })
        }
    }

    handleDiplomadoPreviewImageChange = (event) => {
        let url = URL.createObjectURL(event.target.files[0])
        this.setState({ previewImageFile: event.target.files[0], previewImageURL: url, errors: [] })
    }

    handleDiplomadoPreviewPdfChange = (event) => {
        let url = URL.createObjectURL(event.target.files[0])
        this.setState({ previewPdfFile: event.target.files[0], previewPdfURL: url, errors: [] })
    }

    removeResource = (resourceType, index) => {
        let deleteArray = resourceType === "exams" ? this.state.examsToDelete : this.state.videosToDelete
        deleteArray.push(this.state[resourceType][index])
        if (resourceType === "exams") {
            let deletedQuestions = this.state.questionsToDelete
            this.state.exams[index].questions.forEach(question => {
                deletedQuestions.push({ examId: this.state.exams[index].id, ...question })
            })
        }
        let resources = this.state[resourceType]
        resources.splice(index, 1)
        this.setState({ [resourceType]: resources, errors: [] })
    }

    handleVideosCheck = (video) => {
        let videosCopy = this.state.videos
        const index = videosCopy.findIndex(vid => vid.procedureId === video.id);

        if (index > -1) {
            videosCopy.splice(index, 1);
        }
        else {
            let id = firebase.firestore().collection("diplomados").doc(this.state.diplomadoId).collection("videos").doc().id
            videosCopy.push({ id, procedureId: video.id, ...video, completed: [], new: true })
        }
        this.setState({ videos: videosCopy, errors: [] })
    }
    getVideoColumns = () => {
        return [
            { label: 'Agregar', field: 'select', sort: 'asc' },
            { label: 'Nombre', field: 'name', sort: 'asc' },
            { label: 'Etiquetas', field: 'tags', sort: 'asc' },
            { label: 'Especie', field: 'kynd', sort: 'asc' },
            { label: 'Tipo', field: 'type', sort: 'asc' },
        ];
    }
    getTags = (procedure) => {
        const tags = this.state.allTags;
        let nameTags = "";
        if (Array.isArray(tags)) {
            tags.forEach((tag, index) => {
                if (procedure.tags.hasOwnProperty(tag.id)) {
                    nameTags += `${tag.name}, `;
                }
            });
        }
        return nameTags.slice(0, -2);
    }
    handleSelectAllCheck = (fullArray, typeArray) => {
        if (this.state[fullArray].length === this.state[typeArray].length) {
            this.setState({ [typeArray]: [] })
        }
        else {
            this.setState({ [typeArray]: this.state[fullArray].map((doc) => doc.id) })
        }
    }
    getVideoData = () => {
        const rows = [];

        this.state.allVideos.map(video => {
            let videoChecked = this.state.videos.find(vid => vid.procedureId === video.id) ? true : false

            rows.push({
                select: <div className="form-check form-check-inline">
                    <input name={video.id} checked={videoChecked} className="form-check-input" onChange={() => this.handleVideosCheck(video)} type="checkbox" />
                </div>,
                name: video.name,
                tags: this.getTags(video),
                kynd: video.kynd,
                type: video.type,
            });
        });
        return { columns: this.getVideoColumns(), rows };
    }

    handleTimeChange = (event, index) => {
        const value = event.target.value;
        let element = event.target.name;
        const editingNewVideo = this.state.editingNewVideo;
        editingNewVideo.times[index][element] = value;
        this.setState({ editingNewVideo });
        console.log(this.state.editingNewVideo);
    }
    addTime = () => {
        console.log('agregando tiempo');
        const editingNewVideo = this.state.editingNewVideo;
        editingNewVideo.times.push({ 'name': "", 'time': "" });
        this.setState({ editingNewVideo });
    }
    deleteTime = (index) => {
        const editingNewVideo = this.state.editingNewVideo;
        editingNewVideo.times.splice(index, 1);
        this.setState({ editingNewVideo });
    }
    addResource = (resource) => {
        console.log('agregando ', resource);
        const editingNewVideo = this.state.editingNewVideo;
        editingNewVideo[resource].push({ title: "", url: "" });
        this.setState({ editingNewVideo });
    }
    deleteResource = (index, resource) => {
        const editingNewVideo = this.state.editingNewVideo;
        editingNewVideo[resource].splice(index, 1);
        this.setState({ editingNewVideo });
    }
    handleResourceTitleChange = (event, index) => {
        const editingNewVideo = this.state.editingNewVideo;
        editingNewVideo[event.target.name][index].title = event.target.value
        this.setState({ editingNewVideo, errors: [], resourceErrors: { videos: [], pdfs: [], images: [] } });
    }

    handleDiplomadoCertificateChange = (event) => {
        let url = URL.createObjectURL(event.target.files[0])
        this.setState({ certificateFile: event.target.files[0], certificateURL: url, errors: [] })
    }

    saveVideo = () => {
        if (this.state.indexEditing === -1) {
            let id = firebase.firestore().collection("diplomados").doc(this.state.diplomadoId).collection("videos").doc().id
            let videosCopy = this.state.videos
            videosCopy.push({ id, ...this.state.editingNewVideo, completed: [], new: true })
            this.setState({ videos: videosCopy })
        }
        else {
            let videosCopy = this.state.videos
            videosCopy[this.state.indexEditing] = this.state.editingNewVideo
            this.setState({ videos: videosCopy })
        }
        this.setState({ editingVideo: false, indexEditing: -1, editingNewVideo: { title: "", description: "", times: [], pdfs: [], images: [], url: "", } })
    }


    checkForErrors = () => {
        let errors = []
        let videoErrors = []
        let pdfErrors = []
        let imageErrors = []
        const required = ["title", "description", "previewImageURL", "typeDiplomado", "previewPdfURL", "cost"]

        required.forEach(field => {
            if (this.state[field] === "" || this.state[field] === undefined || this.state[field] === null)
                errors.push(field)
        })

        if (this.state.typeDiplomado === "external" && this.state.organization === "")
            errors.push("organization")
        if (!errors.includes("cost") && parseFloat(this.state.cost).toFixed(2) === "NaN")
            errors.push("costFormat")


        this.setState({ errors, resourceErrors: { videos: videoErrors, pdfs: pdfErrors, images: imageErrors } })

        return (errors.length > 0 || videoErrors.length > 0 || pdfErrors.length > 0 || imageErrors.length > 0)
    }

    submitDiplomado = async (event) => {
        event.preventDefault()
        const data = this.state

        if (!this.checkForErrors() && this.state.videos.length !== 0) {

            const db = firebase.firestore();
            this.setState({ loading: false })

            try {
                let previewImageURL = await this.uploadPreviewImage()
                let previewPdfURL = await this.uplloadMM()
                let certificateURL = await this.uploadCertificate()

                let videos = this.state.videos
                for (let i = 0; i < videos.length; i++) {
                    let isNew = videos[i].completed.length === 0

                    if (!videos[i].procedureId) {
                        let videoUpload = await this.uploadResourcesToStorage([{ file: videos[i].file, filename: videos[i].filename }], i)
                        videos[i].url = videoUpload[0].url || videos[i].url
                        videos[i].images = await this.uploadResourcesToStorage(videos[i].images, i)
                        videos[i].pdfs = await this.uploadResourcesToStorage(videos[i].pdfs, i)
                        delete videos[i].new
                        delete videos[i].file
                    }
                    else
                        videos[i] = { id: videos[i].id, procedureId: videos[i].procedureId, completed: videos[i].completed, active: !!videos[i].active }
                    if (!isNew) {
                        delete videos[i].completed
                    }
                    videos[i].index = i
                }

                await db.collection("diplomados").doc(data.diplomadoId).set({
                    title: data.title,
                    description: data.description,
                    previewImageURL: previewImageURL,
                    previewPdfURL: previewPdfURL,
                    certificateURL: certificateURL,
                    external: data.typeDiplomado === "external",
                    active: data.active,
                    organization: data.typeDiplomado === "external" ? data.organization : "CxVet",
                    adminDiplomado: data.adminDiplomado,
                    cost: parseFloat(this.state.cost).toFixed(2),
                    created: data.created
                }, { merge: true })
                if (this.state.diplomadoUsersToRemove.length > 0) {
                    await db.collection("diplomados").doc(data.diplomadoId).set({ users: firebase.firestore.FieldValue.arrayRemove(...this.state.diplomadoUsersToRemove) }, { merge: true })
                }
                if (this.state.diplomadoUsersToAdd.length > 0) {
                    for (let j = 0; j < this.state.diplomadoUsersToAdd.length; j++) {
                        await db.collection("diplomados").doc(data.diplomadoId).update({ [`beganDiplomadoTimestamps.${this.state.diplomadoUsersToAdd[j]}`]: Date.now() })
                    }
                    await db.collection("diplomados").doc(data.diplomadoId).set({ users: firebase.firestore.FieldValue.arrayUnion(...this.state.diplomadoUsersToAdd) }, { merge: true })
                }
                this.deleteResourcesfromDB()
                await this.saveVideos(videos)
                this.saveExamData()
            }
            catch (error) {
                this.setState({ loading: false })
                console.log(error)
                console.log("!!!!!!!!!!!!!")
            }

        }
        else {
            this.setState({ loading: false })
            if (this.state.videos.length === 0)
                window.alert("El diplomado requiere al menos un video para poder guardar.")
        }

    }

    deleteResourcesfromDB = async () => {
        let promises = []
        let db = firebase.firestore()
        if (this.state.questionsToDelete.length > 0) {
            this.state.questionsToDelete.forEach(question => {
                let examId = question.examId || "randomID"
                let id = question.id || "randomId"
                if (examId !== "") {
                    promises.push(db.collection("diplomados").doc(this.state.diplomadoId).collection("exams").doc(examId).collection("questions").doc(id).delete())
                }
            })
        }
        if (this.state.examsToDelete.length > 0) {
            this.state.examsToDelete.forEach(exam => {
                let id = exam.id || "randomID"
                promises.push(db.collection("diplomados").doc(this.state.diplomadoId).collection("exams").doc(id).delete())
            })
        }
        if (this.state.videosToDelete.length > 0) {
            this.state.videosToDelete.forEach(video => {
                let id = video.id || "randomID"
                promises.push(db.collection("diplomados").doc(this.state.diplomadoId).collection("videos").doc(id).delete())
            })
        }

        return Promise.all(promises)
    }
    uploadCertificate = async () => {
        if (this.state.certificateFile) {
            let storagePath = `diplomados/${this.state.diplomadoId}/Certificate`
            let storageRef = firebase.storage().ref().child(storagePath)
            let uploadPreviewTask = storageRef.put(this.state.certificateFile)

            return new Promise((resolve, reject) => {
                return uploadPreviewTask.on('state_changed',
                    (snapshot) => {
                        const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                        const fileStatus = { name: "Certificado", state: progress };
                        this.setState({ fileStatus, fileModalShow: true });
                        console.log(fileStatus);
                    },
                    (error) => {
                        console.log(error);
                        reject(error)
                    },
                    () => {
                        return uploadPreviewTask.snapshot.ref.getDownloadURL().then(url => {
                            this.setState({ fileModalShow: false });
                            resolve(url)
                        })
                    });

            })

        }
        else
            return this.state.certificateURL
    }
    uploadPreviewImage = async () => {
        if (this.state.previewImageFile) {
            let storagePath = `diplomados/${this.state.diplomadoId}/Preview-Image`
            let storageRef = firebase.storage().ref().child(storagePath)
            let uploadPreviewTask = storageRef.put(this.state.previewImageFile)

            return new Promise((resolve, reject) => {
                return uploadPreviewTask.on('state_changed',
                    (snapshot) => {
                        const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                        const fileStatus = { name: "Imagen vista previa", state: progress };
                        this.setState({ fileStatus, fileModalShow: true });
                        console.log(fileStatus);
                    },
                    (error) => {
                        console.log(error);
                        reject(error)
                    },
                    () => {
                        return uploadPreviewTask.snapshot.ref.getDownloadURL().then(url => {
                            this.setState({ fileModalShow: false });
                            resolve(url)
                        })
                    });

            })

        }
        else
            return this.state.previewImageURL
    }

    uplloadMM = async () => {
        if (this.state.previewPdfFile) {
            let storagePath = `diplomados/${this.state.diplomadoId}/Preview-pdf`
            let storageRef = firebase.storage().ref().child(storagePath)
            let uploadPreviewTask = storageRef.put(this.state.previewPdfFile)

            return new Promise((resolve, reject) => {
                return uploadPreviewTask.on('state_changed',
                    (snapshot) => {
                        const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                        const fileStatus = { name: "PDF previa", state: progress };
                        this.setState({ fileStatus, fileModalShow: true });
                        console.log(fileStatus);
                    },
                    (error) => {
                        console.log(error);
                        reject(error)
                    },
                    () => {
                        return uploadPreviewTask.snapshot.ref.getDownloadURL().then(url => {
                            this.setState({ fileModalShow: false });
                            resolve(url)
                        })
                    });

            })

        }
        else
            return this.state.previewPdfURL

    }

    uploadResourcesToStorage = async (resourceArray, resourceIndex) => {
        let promises = []
        let storagePath = undefined, storageRef = undefined;
        if (resourceArray.length === 0 || !resourceArray)
            return Promise.resolve([])

        resourceArray.forEach((resource, index) => {
            if (resource.file) {
                storagePath = `diplomados/${this.state.diplomadoId}/video${resourceIndex}/${Date.now()}_${resource.filename}`
                storageRef = firebase.storage().ref().child(storagePath)

                promises.push(new Promise(async (resolve, reject) => {
                    try {
                        let uploadResourceTask = storageRef.put(resource.file)
                        ////

                        uploadResourceTask.on('state_changed',
                            (snapshot) => {
                                const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                                const fileStatus = { name: resource.filename, state: progress };
                                this.setState({ fileStatus, fileModalShow: true });
                                console.log(fileStatus);
                            },
                            (error) => {
                                console.log(error);
                                reject(error)
                            },
                            () => {
                                return uploadResourceTask.snapshot.ref.getDownloadURL().then(url => {
                                    this.setState({ fileModalShow: false });
                                    resolve({ title: resource.title, filename: resource.filename, url })
                                })
                            });

                    } catch (error) {
                        reject(error)
                    }
                }))
            }
            else
                promises.push(Promise.resolve(resource))
        })

        return Promise.all(promises)
    }


    getDiplomadoVideosColumns = () => {
        return [
            { label: '', field: 'reorderUp', sort: 'disabled' },
            { label: '', field: 'reorderDown', sort: 'disabled' },
            { label: 'Título', field: 'title', sort: 'asc' },
            { label: 'Vista Previa', field: 'preview', sort: 'asc' },
            { label: 'Editar', field: 'edit', sort: 'asc' },
            { label: 'Cambiar estatus', field: 'active', sort: 'asc' },
            { label: 'Quitar', field: 'delete', sort: 'asc' },
        ];
    }
    getDiplomadoVideosData = () => {
        const rows = [];
        this.state.videos.map((video, index) => {
            rows.push({
                key: `key-${index}`,
                reorderUp: <button type="button" className="btn btn-primary" onClick={() => this.reorder(index, -1)}><img src={upArrow} height={32} width={32}></img> </button>,
                reorderDown: <button type="button" className="btn btn-primary" onClick={() => this.reorder(index, 1)}><img src={downArrow} height={32} width={32}></img> </button>,

                title: video.title || video.name,
                preview: <video width={240} controls src={video.url || video.video} />,
                edit: video.procedureId ? <label >Esto es un video de la plataforma, edítelo desde la sección de Cirugías</label> : <button type="button" className="btn btn-primary" onClick={() => this.setState({ editingVideo: true, indexEditing: index, editingNewVideo: video })} >Editar</button>,
                active: <button key={`vid-act${index}`} type="button" className="btn btn-primary" onClick={() => this.activateVideo(index)} >{video.active ? "Desactivar" : "Activar"}</button>,
                delete: <a className="text-light btn btn-primary" onClick={(e) => this.removeResource("videos", index)}>
                    <FontAwesomeIcon icon={faTrash} />
                </a>
            });
        });
        return { columns: this.getDiplomadoVideosColumns(), rows };
    }
    activateVideo = (index) => {
        const copyVideos = [...this.state.videos];
        copyVideos[index].active = !copyVideos[index].active;
        this.setState({videos: copyVideos})
    }
    activateExam = (index) => {
        const copyExams = [...this.state.exams];
        copyExams[index].active = !copyExams[index].active;
        this.setState({exams: copyExams})
    }

    getExamColumns = () => {
        return [
            { label: 'Título', field: 'title', sort: 'asc' },
            { label: 'Seleccionar Video', field: 'select', sort: 'asc' },
            { label: 'Editar', field: 'edit', sort: 'asc' },
            { label: 'Cambiar estatus', field: 'active', sort: 'asc' },
            { label: 'Quitar', field: 'delete', sort: 'asc' },
        ];
    }
    getExamData = () => {
        let selectedVideos = []
        this.state.exams.forEach((exam, index) => {
            if (exam.indexVideo)
                selectedVideos.push(exam.indexVideo)
        })
        const rows = [];
        this.state.exams.map((exam, index) => {

            rows.push({
                title: exam.title || "",
                select: <select name="video" value={exam.indexVideo} onChange={(e) => {
                    let eCopy = this.state.exams
                    eCopy[index].indexVideo = e.target.value
                    this.setState({ exams: eCopy })
                }} className="form-control">
                    <option value="">Sin asignar</option>
                    {this.state.videos.map((video, index) => {
                        if (!selectedVideos.includes(index + "") || exam.indexVideo === (index + ""))
                            return (<option value={index}> {index + " - " + (video.title || video.name)} </option>);
                    })}
                </select>,
                edit: <button type="button" className="btn btn-primary" onClick={() => this.setState({ editingExam: true, indexEditingExam: index })} >Editar</button>,
                active: <button key={`ex-act${index}`} type="button" className="btn btn-primary" onClick={() => this.activateExam(index)} >{exam.active ? "Desactivar" : "Activar"}</button>,
                delete: <a className="text-light btn btn-primary" onClick={(e) => this.removeResource("exams", index)}>
                    <FontAwesomeIcon icon={faTrash} />
                </a>
            });
        });
        return { columns: this.getExamColumns(), rows };
    }

    reorder = (index, direction) => {
        if (index === 0 && direction === -1) {
            //do nothing
        }
        else if (index === this.state.videos.length - 1 && direction === 1) {
            //do nothing
        }
        else if (this.state.videos.length === 1) {
            //do nothing
        }
        else {
            let videosCopy = this.state.videos
            let theVideoToMove = videosCopy[index]
            let previousVideo = videosCopy[index + direction]

            videosCopy[index] = previousVideo
            videosCopy[index + direction] = theVideoToMove
            this.setState({ videos: videosCopy })
        }
    }

    handleSearch = (event) => {
        let search = event.target.value
        let rowsCopy = this.state.rows
        let rowsFiltered = []

        rowsCopy.forEach((row) => {
            if (row.name.includes(search) || row.email.includes(search) || row.type.includes(search)) {
                rowsFiltered.push(row)
            }
        })

        this.setState({ search: search, rowsFiltered: rowsFiltered })
    }

    deleteDiplomado = () => {
        const db = firebase.firestore();

        db.collection('diplomados').doc(this.state.diplomadoId).delete().then((dipl) => {
            this.deleteDiplomadoFiles()
        }).catch(error => console.log(error));
    }

    deleteDiplomadoFiles = () => {
        const db = firebase.firestore();
        let storageRef = firebase.storage().ref()
        var imageRef = storageRef.child(`diplomados/${this.state.diplomadoId}/Preview-Image`);
        var pdfRef = storageRef.child(`diplomados/${this.state.diplomadoId}/Preview-pdf`);
        var listRef = storageRef.child(`diplomados/${this.state.diplomadoId}`);

        let promises = []
        promises.push(imageRef.delete())
        promises.push(pdfRef.delete())


        listRef.listAll().then((res) => {

            res.prefixes.forEach((folderRef) => {
                let folderPath = folderRef.toString().split("gs://cvtdev.appspot.com/")[1]

                storageRef.child(folderPath).listAll().then((subRes) => {

                    subRes.items.forEach((itemRef) => {
                        let itemPath = itemRef.toString().split("gs://cvtdev.appspot.com/")[1]
                        promises.push(storageRef.child(itemPath).delete())

                    })
                    Promise.all(promises).then(() => {
                        window.alert("El diplomado fue eliminado con éxito.")
                        this.props.history.push("/admin/diplomados")
                    }).catch((error) => {
                        console.log(error)
                    });

                })
            });

        }).catch((error) => {
            console.log(error)
        });
    }

    saveVideos = async (videos) => {
        let promises = []
        videos.forEach((video, index) => {
            let docId = video.id
            delete video.id
            console.log("VIDEO TO SAVE: ",video)
            promises.push(new Promise((resolve, reject) => {
                firebase.firestore().collection('diplomados').doc(this.state.diplomadoId).collection('videos').doc(docId).set(video, { merge: true }).then(() => {
                    resolve()
                }).catch(error => {
                    reject(error)
                })
            }))
        })
    }

    saveExamData = () => {
        this.state.exams.forEach((exam, indexE) => {
            let examId = exam.id || firebase.firestore().collection('diplomados').doc(this.state.diplomadoId).collection('exams').doc().id

            firebase.firestore().collection('diplomados').doc(this.state.diplomadoId).collection('exams').doc(examId).set({
                title: exam.title,
                indexVideo: parseInt(exam.indexVideo),
                hasPassed: exam.hasPassed,
                active: !!exam.active 
            }, { merge: true }).then((examDoc) => {

                exam.questions.forEach((question, indexQ) => {

                    let questionId = question.id || firebase.firestore().collection('diplomados').doc(this.state.diplomadoId).collection('exams').doc().id

                    firebase.firestore().collection('diplomados').doc(this.state.diplomadoId).collection('exams').doc(examId).collection('questions').doc(questionId).set({
                        question: question.question, answers: question.answers, correctAnswer: question.correctAnswer,
                        originalOrder: indexQ
                    }).then((examDoc) => {

                        if (indexQ === exam.questions.length - 1 && indexE === this.state.exams.length - 1) {
                            this.setState({ loading: false })
                            this.props.history.push("/admin/diplomados")
                        }

                    }).catch(error => {
                        console.log(error)
                        this.props.history.push("/admin/diplomados")
                    });


                })


            }).catch(error => {
                console.log(error)
                this.props.history.push("/admin/diplomados")
            });


        })
        if (this.state.exams.length === 0) {
            this.setState({ loading: false })
            this.props.history.push("/admin/diplomados")
        }

    }

    showOnlyAssigned = () => {
        this.setState({ showOnlyAssigned: !this.state.showOnlyAssigned })
    }

    render() {
        const errors = this.state.errors
        return (
            <React.Fragment>
                <div className="content-wrapper p-5">
                    <div className="row">

                        {!this.state.active && <div className="col-md-12" >
                            <button type="button" className="btn btn-primary float-right" onClick={() => {
                                if (window.confirm("¿Estás seguro de que te gustaría eliminar este diplomado? Una vez que lo haga, no podrá deshacerlo."))
                                    this.deleteDiplomado()
                            }}>Eliminar</button>
                        </div>}

                        <div className="col-md-12">
                            <h1 className="mb-0">Diplomado</h1>
                            <h3 className="mb-5">{this.state.editing ? "Editar diplomado" : "Nuevo diplomado"}</h3>

                            <Tabs defaultActiveKey="information" id="uncontrolled-tab-example">
                                <Tab eventKey="information" title="Información General">
                                    {<Form onSubmit={this.submitDiplomado} >

                                        <div className="row justify-content-center mb-4 mt-5">
                                            <div className="col-md-10" >
                                                <h3>Información general del diplomado</h3>
                                                <div className="form-container">
                                                    <div className="row">
                                                        <div className="col-md-12">

                                                            <div className="form-group">
                                                                <label>Título</label>
                                                                <input name="title" onChange={this.handleInputChange} value={this.state.title} type="text" className="form-control" />
                                                                {errors.includes("title") && <Alert variant="danger"> Este campo es requerido.</Alert>}
                                                            </div>

                                                            <div className="form-group">
                                                                <label>Detalle del evento</label>
                                                                <CKEditor editor={ClassicEditor}
                                                                    data={this.state.description}
                                                                    config={{ language: 'es', removePlugins: ['MediaEmbed', 'ImageUpload'] }}
                                                                    onChange={(event, editor) => { this.setState({ description: editor.getData(), errors: [] }) }}
                                                                />
                                                                {errors.includes("description") && <Alert variant="danger"> Este campo es requerido.</Alert>}
                                                            </div>

                                                            <div className="row ">
                                                                <div className="col-md-4">
                                                                    <div className="form-group ">
                                                                        <label >Imagen previa </label>
                                                                        <img className="img-fluid  mb-2 preview" src={this.state.previewImageURL} />
                                                                        <input name="filePreview" onChange={this.handleDiplomadoPreviewImageChange} type="file" className="form-control-file" accept="image/jpeg,image/jpg,image/png" />
                                                                        <small class="form-text text-muted">La imagen debe tener dimensiones mínimas de 1090px x 720px</small>
                                                                        {errors.includes("previewImageURL") && <Alert variant="danger"> Se requiere la imagen de vista previa.</Alert>}
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <div className="form-group ">
                                                                        <label >PDF previa </label>
                                                                        {this.state.previewPdfURL ?
                                                                            <object type="application/pdf"
                                                                                data={this.state.previewPdfURL} width="400" height="400"></object>
                                                                            :
                                                                            null}
                                                                        <input name="previewPdfURL" onChange={this.handleDiplomadoPreviewPdfChange} type="file" className="form-control-file" accept="application/pdf" />
                                                                        <small class="form-text text-muted">El pdf será descargado por el usuario si no ha sido agregado al diplomado.</small>
                                                                        {errors.includes("previewPdfURL") && <Alert variant="danger"> Se requiere la pdf de información previa.</Alert>}
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <div className="form-group ">
                                                                        <label >Certificado </label>
                                                                        <img className="img-fluid  mb-2 preview" src={this.state.certificateURL} />
                                                                        <input name="filePreview" onChange={this.handleDiplomadoCertificateChange} type="file" className="form-control-file" accept="image/jpeg,image/jpg,image/png" />
                                                                        <small class="form-text text-muted">La imagen debe tener dimensiones mínimas de 1090px x 720px</small>
                                                                        {errors.includes("certificateURL") && <Alert variant="danger"> Se requiere el certificado.</Alert>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="form-group">
                                                                <label>Costo del diplomado (USD)</label>
                                                                <input name="cost" onChange={this.handleInputChange} value={this.state.cost} type="text" className="form-control" />
                                                            </div>
                                                            {errors.includes("cost") && <Alert variant="danger"> Este campo es requerido, .</Alert>}
                                                            {errors.includes("costFormat") && <Alert variant="danger"> Debe ser un número válido redondeado a 2 dígitos</Alert>}

                                                            <div className="form-group">
                                                                <label>CxVet/External</label>
                                                                <select name="typeDiplomado" onChange={this.handleInputChange} value={this.state.typeDiplomado} className="form-control">
                                                                    <option value="">Sin asignar</option>
                                                                    <option value={"CxVet"}> CxVet </option>
                                                                    <option value={"external"}> External </option>
                                                                </select>
                                                                {errors.includes("typeDiplomado") && <Alert variant="danger"> Este campo es requerido.</Alert>}
                                                            </div>

                                                            {this.state.typeDiplomado === "external" && <div className="form-group">
                                                                <label>Organización</label>
                                                                <select name="organization" value={this.state.organization} onChange={this.handleInputChange} className="form-control">
                                                                    <option value="">Sin asignar</option>
                                                                    {this.state.organizations.map((organization, index) => {
                                                                        if (!organization.external)
                                                                            return (<option value={organization.id}> {organization.title} </option>);
                                                                    })}
                                                                </select>
                                                                {errors.includes("organization") && <Alert variant="danger"> Este campo es requerido.</Alert>}
                                                            </div>}

                                                            <div className="form-group">
                                                                <label>Administrador del diplomado</label>
                                                                <select name="adminDiplomado" onChange={(e) => { this.setState({ adminDiplomado: e.target.value }) }} value={this.state.adminDiplomado} className="form-control">
                                                                    <option value="">Sin asignar</option>
                                                                    {this.state.allUsers.map(user => {
                                                                        return <option value={user.id}> {user.email} </option>
                                                                    })}
                                                                </select>
                                                            </div>


                                                        </div>


                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="btn-row" >
                                            <button type="button" className="btn btn-primary" onClick={() => this.props.history.goBack()}>Cancelar</button>
                                            <button type="submit" className="btn btn-primary" onClick={this.submitDiplomado}>Guardar</button>
                                        </div>



                                    </Form>}
                                </Tab>

                                <Tab eventKey="videos" title="Agregar Videos">

                                    <div className="row justify-content-center mb-4 mt-5">
                                        <div className="col-md-10" >
                                            <h3>Cirugías</h3>
                                            <label>{this.state.videos.length}{this.state.videos.length === 1 ? " video seleccionado" : " videos seleccionados"}</label><br />
                                        </div>
                                        <div className="col-md-10" >
                                            <div className="border border-dark p-2">
                                                {this.state.videos.length > 0 ? <DataTableComponent data={this.getDiplomadoVideosData()} /> :
                                                    <div className="form-group" >
                                                        <h3 className="text-center my-5">Haga clic en 'Administrar nuevo video'</h3>
                                                        <h6 className="text-center mb-5">0</h6>
                                                        <h4 className="text-center mb-5">vincule un video desde la plataforma</h4>
                                                    </div>
                                                }

                                            </div>
                                        </div>

                                        <div className="col-md-10" >
                                            <button type="button" className="btn btn-primary resource-align resource-margin-small mb-5" onClick={() => this.setState({ editingPlatformVideos: true })}>Vincular video de la plataforma</button>
                                            <button type="button" className="btn btn-primary resource-align resource-margin-small mb-5" onClick={() => this.setState({ editingVideo: true })}>Administrar Nuevo Video</button>
                                        </div>
                                    </div>

                                    <AdministrarVideoModal show={this.state.editingVideo} onHide={() => this.setState({ editingVideo: false })}
                                        handleInputChange={(e) => this.setState({ editingNewVideo: { ...this.state.editingNewVideo, [e.target.name]: e.target.value } })}
                                        handleFileInputChange={this.handleFileInputChange}
                                        title={this.state.editingNewVideo.title}
                                        description={this.state.editingNewVideo.description}
                                        url={this.state.editingNewVideo.url}
                                        times={this.state.editingNewVideo.times}
                                        handleTimeChange={this.handleTimeChange}
                                        deleteTime={this.deleteTime}
                                        addTime={this.addTime}
                                        pdfs={this.state.editingNewVideo.pdfs}
                                        images={this.state.editingNewVideo.images}
                                        handleResourceTitleChange={this.handleResourceTitleChange}
                                        deleteResource={this.deleteResource}
                                        addResource={this.addResource}
                                        saveVideo={this.saveVideo}
                                        cancel={() => this.setState({ editingVideo: false, editingNewVideo: { title: "", description: "", times: [], pdfs: [], images: [], url: "", } })}
                                    />
                                    <CirugiasModal show={this.state.editingPlatformVideos} onHide={() => this.setState({ editingPlatformVideos: false })}
                                        videos={this.state.videos}
                                        getVideoData={this.getVideoData}
                                        saveVideo={this.saveVideo}
                                        cancel={() => this.setState({ editingPlatformVideos: false })}

                                    />

                                    <div className="btn-row" >
                                        <button type="button" className="btn btn-primary" onClick={() => this.props.history.goBack()}>Cancelar</button>
                                        <button type="submit" className="btn btn-primary" onClick={this.submitDiplomado}>Guardar</button>
                                    </div>
                                </Tab>



                                <Tab eventKey="users" title="Usuarios">
                                    <div className="row justify-content-center mb-4 mt-5">
                                        <div className="col-md-10" >
                                            <h3>Usuarios</h3>
                                            <div className="form-check form-check-inline">
                                                <input name="showOnlyAssigned" checked={this.state.showOnlyAssigned} className="form-check-input" onChange={(e) => { this.showOnlyAssigned() }} type="checkbox" />
                                            </div>
                                            <label>Mostrar solo usuarios en este diplomado</label>
                                            <br />
                                            <label>{this.state.diplomadoUsers.length}{this.state.diplomadoUsers.length === 1 ? " usuario seleccionado" : " usuarios seleccionados"}</label><br />

                                            {/* <div className="form-group">
                                            <label>Buscar</label>
                                            <input name="search" onChange={this.handleSearch} value={this.state.search} type="text" className="form-control" />
                                            {errors.includes("title") && <Alert variant="danger"> Este campo es requerido.</Alert>}
                                    </div>*/}

                                            <DataTableComponent data={this.getUserData()} />
                                        </div>

                                    </div>
                                    <div className="btn-row" >
                                        <button type="button" className="btn btn-primary" onClick={() => this.props.history.goBack()}>Cancelar</button>
                                        <button type="submit" name="submitButton" className="btn btn-primary" onClick={this.submitDiplomado}>Guardar</button>
                                    </div>
                                </Tab>


                                <Tab eventKey="exams" title="Exámenes">
                                    <div className="row justify-content-center mb-4 mt-5">
                                        <div className="col-md-10" >
                                            <h3>Exámenes</h3>
                                            {this.state.videos.length > 0 ? <DataTableComponent data={this.getExamData()} /> :
                                                <div className="form-group" >
                                                    <h3 className="text-center mt-5 mb-2">Debe subir al menos 1 video para crear un examen.</h3>
                                                </div>
                                            }
                                            {this.state.exams.length < this.state.videos.length && <button type="button" className="btn btn-primary resource-align resource-margin-small" onClick={() => this.setState({ editingExam: true, indexEditingExam: -1 })}>Agregar Exámen</button>}

                                            <ExamModal show={this.state.editingExam && this.state.indexEditingExam === -1} onHide={() => this.setState({ editingExam: false })}
                                                cancel={() => this.setState({ editingExam: false, indexEditingExam: -1 })} exams={this.state.exams} indexEditingExam={this.state.indexEditingExam}
                                                saveExam={(questions, title) => {
                                                    this.setState({ editingExam: false, exams: [...this.state.exams, { title: title, questions: questions, indexVideo: -1, hasPassed: [] }] })
                                                }}
                                            />

                                            <ExamEditModal show={this.state.editingExam && this.state.indexEditingExam !== -1} onHide={() => this.setState({ editingExam: false })}
                                                cancel={() => this.setState({ editingExam: false })} exam={this.state.exams[this.state.indexEditingExam]}
                                                handleChangeTitle={(e) => {
                                                    let eCopy = this.state.exams
                                                    eCopy[this.state.indexEditingExam].title = e.target.value
                                                    this.setState({ exams: eCopy })
                                                }}
                                                handleChangeQuestion={(e, index) => {
                                                    let eCopy = this.state.exams
                                                    let qCopy = eCopy[this.state.indexEditingExam].questions
                                                    qCopy[index].question = e.target.value
                                                    eCopy[this.state.indexEditingExam].questions = qCopy
                                                    this.setState({ exams: eCopy })
                                                }}
                                                addQuestion={() => {
                                                    let eCopy = this.state.exams
                                                    eCopy[this.state.indexEditingExam].questions = [...eCopy[this.state.indexEditingExam].questions, { question: "", answers: [], correctAnswer: 0, userAnswers: {} }]
                                                    this.setState({ exams: eCopy })
                                                }}
                                                deleteQuestion={(index) => {
                                                    let eCopy = this.state.exams
                                                    let deletedQuestions = this.state.questionsToDelete
                                                    deletedQuestions.push({ examId: eCopy[this.state.indexEditingExam].id, ...eCopy[this.state.indexEditingExam].questions[index] })
                                                    eCopy[this.state.indexEditingExam].questions.splice(index, 1)
                                                    this.setState({ exams: eCopy, questionsToDelete: deletedQuestions })
                                                }}
                                                deleteAnswer={(indexQ, indexA) => {
                                                    let eCopy = this.state.exams
                                                    eCopy[this.state.indexEditingExam].questions[indexQ].answers.splice(indexA, 1)
                                                    this.setState({ exams: eCopy })
                                                }}
                                                handleChangeAnswer={(e, index, indexA) => {
                                                    let eCopy = this.state.exams
                                                    let qCopy = eCopy[this.state.indexEditingExam].questions
                                                    let aCopy = qCopy[index].answers
                                                    aCopy[indexA] = e.target.value
                                                    qCopy[index].answers = aCopy
                                                    eCopy[this.state.indexEditingExam].questions = qCopy
                                                    this.setState({ exams: eCopy })
                                                }}
                                                addAnswer={(index) => {
                                                    let eCopy = this.state.exams
                                                    eCopy[this.state.indexEditingExam].questions[index].answers = [...eCopy[this.state.indexEditingExam].questions[index].answers, ""]
                                                    this.setState({ exams: eCopy })
                                                }}
                                                handleCheck={(index, indexA) => {
                                                    let eCopy = this.state.exams
                                                    let qCopy = eCopy[this.state.indexEditingExam].questions
                                                    qCopy[index].correctAnswer = indexA
                                                    eCopy[this.state.indexEditingExam].questions = qCopy
                                                    this.setState({ exams: eCopy })
                                                }}
                                                saveExam={(questions, title) => {
                                                    this.setState({ exams: [...this.state.exams, { title: title, questions: questions }] })
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="btn-row" >
                                        <button type="button" className="btn btn-primary" onClick={() => this.props.history.goBack()}>Cancelar</button>
                                        <button type="submit" name="submitButton" className="btn btn-primary" onClick={this.submitDiplomado}>Guardar</button>
                                    </div>
                                </Tab>

                                <Tab eventKey="statistics" title="Estadísticas de exámenes" onEnter={this.getUserExamData}>
                                    <div className="row justify-content-center mb-4 mt-5">
                                        <div className="col-md-10" >
                                            {this.state.statisticsProgress > 0 && <ProgressBar now={this.state.statisticsProgress} label={`${this.state.statisticsProgress}%`} />}
                                            {this.state.userColumns && this.state.userRows && <h3>Usuarios datos</h3>}
                                            {this.state.userColumns && this.state.userRows && <DataTableComponent data={{ columns: this.state.userColumns || [], rows: this.state.userRows || [] }} />}
                                        </div>
                                    </div>
                                    <div className="row justify-content-center mb-4 mt-5">
                                        <div className="col-md-10" >
                                            {this.state.userColumns && this.state.userRows && <h3>Proporciones de progreso del diplomado</h3>}
                                            {this.state.userColumns && this.state.userRows && <div className="m-5">
                                                <Chart options={{ ...this.state.optionsPie, colors: ["#00cf6b", "#2679ff", "#ff0000"], labels: ["Completado", "En Progreso", "Sin Inicio"] }} series={[this.getPieDiplomadoData().completed, this.getPieDiplomadoData().inProgress, this.getPieDiplomadoData().noProgress]} type="donut" width={450} height={450} />
                                            </div>}
                                        </div>
                                    </div>
                                    <div className="row justify-content-center mb-4 mt-5">
                                        <div className="col-md-10" >
                                            {this.state.userColumns && this.state.userRows && <h3>Exámenes datos</h3>}
                                            {this.state.userColumns && this.state.userRows && <DataTableComponent data={{ columns: this.state.examColumns || [], rows: this.state.examRows || [] }} />}
                                        </div>
                                    </div>
                                    {this.state.exams && this.state.userColumns && this.state.userRows &&
                                        <div className="row justify-content-center mb-4 mt-5">
                                            <div className="col-md-10" >
                                                {this.state.exams.map((exam) => {
                                                    let pieData = this.getPieData(exam)
                                                    let lineData = this.getLineData(exam)

                                                    return <div className="row justify-content-center mb-4 mt-5">
                                                        <h3>{exam.title}</h3>
                                                        <div className="m-5">
                                                            <h3>Completaron el examen</h3>
                                                            <Chart options={{ ...this.state.optionsPie, colors: ["#2679ff", "#ff0000"], labels: ["Completaron", "Sin terminar"] }} series={[pieData.completed, pieData.total - pieData.completed]} type="donut" width={375} height={375} />
                                                        </div>
                                                        <div className="m-5" >
                                                            <h3>Pasado Vs. Rechazado</h3>
                                                            <Chart options={{ ...this.state.optionsPie, colors: ["#00cf6b", "#ff0000"], labels: ["Pasado", "Rechazado"] }} series={[pieData.passed, pieData.failed]} type="donut" width={375} height={375} />
                                                        </div>
                                                        <div className="m-5" >
                                                            <h3>Estadísticas preguntas</h3>
                                                            <Chart options={lineData.options} series={lineData.series} type="bar" height={500} width={500} />
                                                        </div>
                                                    </div>
                                                })}
                                            </div>
                                        </div>}
                                </Tab>
                            </Tabs>
                        </div>
                    </div>
                </div>
                <Spinner show={this.state.loading} />
                {false && <LoadingStatisticsModal show={this.state.loadingStatistics} statisticsProgress={this.state.statisticsProgress} />}
                <UploadFileAlertComponent show={this.state.fileModalShow} file={this.state.fileStatus} />

            </React.Fragment >
        );
    }
}
DiplomadoFormComponent.contextType = CurrentUser;
export default DiplomadoFormComponent;