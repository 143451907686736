const faqs = [
    {
        pregunta: "¿Qué es CxVet?",
        respuesta: "Ofrecemos un servicio de educación continua y acceso a una comunidad que ayuda a médicos veterinarios de cualquier nivel, desde estudiantes universitarios hasta cirujanos experimentados, a resolver casos quirúrgicos ya sean básicos o lo más avanzado. Lo hacemos a través de una plataforma digital con videos, cursos y fascículos de alta calidad que detallan procedimientos quirúrgicos de principio a fin, integrados por expertos en la materia, haciéndote sentir que tienes un cirujano siempre contigo."
    },
    {
        pregunta: "¿En qué consiste la membresía Premium?",
        respuesta: "Con la membresía Premium tienes acceso sin restricciones a todos los vídeos de cirugía de CxVet, a la descarga de todas las guías en PDF y contarás con descuentos en cursos y talleres."
    },
    {
        pregunta: "¿En qué consiste la membresía Básica?",
        respuesta: "Con la membresía Básica tienes acceso a todos los vídeos de cirugía marcados como gratis."
    },
    {
        pregunta: "¿Puedo cambiar mi membresía a Premium si me registré con membresía Básica?",
        respuesta: "Puedes cambiar tu membresía de Básica a Premium, o viceversa en cualquier momento."
    },
    {
        pregunta: "Si cambio mi membresía de Premium a Básica antes de que llegue mi fecha de pago, ¿Pierdo el acceso a vídeos Premium?",
        respuesta: "Mantendrás el acceso Premium hasta el día de pago de tu suscripción, cuando se hará efectivo el cambio de cuenta."
    },
    {
        pregunta: "¿Porqué no puedo ver un vídeo premium si estoy registrado?",
        respuesta: "Si estas registrado en el plan básico debes migrar al plan Premium para poder ver todos los videos Premium."
    },
    {
        pregunta: "¿Cómo procesamos los pagos?",
        respuesta: "La captura de tu información de pago y los pagos recurrentes se manejan a través de Paypal (https://www.paypal.com/mx/)."
    },
    {
        pregunta: "¿Qué pasa si no recuerdo mi contraseña?",
        respuesta: `En la página de “Login” selecciona la opcion “Olvidé mi Contraseña” y recibirás un correo electrónico con las instrucciones para poder restablecer tu contraseña.`
    },
    {
        pregunta: "¿Qué son las guías en PDF?",
        respuesta: "Son documentos en PDF que resumen el vídeo paso a paso, además de proporcionar información importante."
    },

    //Nuevos preguntas (diplomados y aulas)
    /*    {
            pregunta:"¿Necesito tener una cuenta premium para usar los diplomados?",
            respuesta:"Sí, debe tener una cuenta premium para ver los diplomados"
        },
        {
            pregunta:"¿Puedo inscribirme en varios diplomados a la vez?",
            respuesta:"Sí, puedes inscribirte en tantos diplomados como quieras."
        },
        {
            pregunta:"Cuando selecciono un diplomado, descarga un pdf, ¿por qué?",
            respuesta:"Si se descarga un pdf, eso significa que actualmente no está inscrito en el diplomado."
        },
        {
            pregunta:"¿Cómo me inscribo en un diplomado?",
            respuesta:"La información está disponible para contactarnos y comprar acceso al diplomado en el pdf que se descarga si hace clic en diplomado."
        },
    
        {
            pregunta:"Si dejo de pagar una cuenta premium, ¿perderé el acceso a los diplomados por los que pagué?",
            respuesta:"Sí, una vez que su cuenta vuelva a la básica, ya no podrá ver los diplomados."
        },
        {
            pregunta:"¿Necesito tener una cuenta premium para usar las aulas virtuales?",
            respuesta:"No, alguien con un plan básico puede ver las aulas virtuales."
        },
        */
]
export default faqs;