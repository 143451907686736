import React,{Component} from 'react';
import Modal from 'react-bootstrap/Modal';
import Link from 'react-router-dom';

class TypeAlertComponent extends Component {
    constructor(props){
        super(props);
    }

    render() {
        return (
            <Modal {...this.props} size="md" aria-labelledby="contained-modal-title-vcenter" centered >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <h5 className="modal-title" id="modalTerminoLabel">Aviso</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    
                    <p className="mb-0">
                        Para poder descargar este procedimiento necesitas tener una membresía premium.

                    </p>
                </Modal.Body>
                <Modal.Footer>
                  <div className="btn-row">

                    <button onClick={this.props.onHide} className="btn btn-secondary" >Cancelar</button>
                    <button onClick={this.props.redirectFunction} className="btn btn-primary" >Obtener</button>
                    </div>
                </Modal.Footer>
            </Modal>
        );
    }
}
export default TypeAlertComponent;