import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import Alert from 'react-bootstrap/Alert';
import DataTableComponent from '../../core/DataTableComponent';
import firebase from 'firebase';

var moment = require("moment");
moment.locale("es");

class EliminarModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            users: [],
            diplomadoId: this.props.diplomadoId,
            users: [],
            diplomadoUsers: [],
            university: ""
        }
    }

    componentDidMount = () => {
       
    }


    render() {
        return (
            <Modal {...this.props} size="lg" className="progress-modal" centered >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <h5 className="modal-title" id="modalTerminoLabel">Eliminar Diplomado</h5>
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <p className=" mb-0">¿Quieres eliminar este diplomado?</p>
                </Modal.Body>

                <Modal.Footer>
                    <button role="button" className="btn btn-primary" onClick={this.props.onHide}>Cancelar</button>
                    <button role="button" className="btn btn-primary" onClick={this.props.deleteDiplomado}>Eliminar</button>
                </Modal.Footer>
            </Modal>
        );
    }
}
export default EliminarModal;