import React, { Component } from 'react';
import CurrentUser from "../../public/core/PublicUserSessionContext";
import { getAllUsers } from "../../../service/users"
import DataTableComponent from '../core/DataTableComponent'
import { getDateFormatted } from '../../../service/functions';

class UserComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            users: [],
            showOnlyPremiumManual: false
        };
    }

    componentDidMount() {
        this.getInitialDate()
    }
    getInitialDate = async () => {
        this.context.iniciaCarga()
        let users = await getAllUsers()
        this.setState({ users })
        this.context.terminaCarga()
    }
    getColumns = () => [
        { label: 'Nombre', field: 'name', sort: 'asc' },
        { label: 'Correo electrónico', field: 'email', sort: 'asc' },
        { label: 'Membresía', field: 'type', sort: 'asc' },
        { label: 'Fecha de registro', field: 'date', sort: 'asc' },
    ];
    getData = () => {
        const { users, showOnlyPremiumManual } = this.state
        let rows = users.map(user => ({
            ...user,
            type: user.hasPremiumAnual ? `${user.type} (Anual)` : user.type,
            date: getDateFormatted(user.date),
            clickEvent: (ev) => this.props.history.push(`/admin/Users/User/${user.id}`)
        }));

        if (showOnlyPremiumManual)//Filtrar solo premium manual cuando seleccionan el checkbox
            rows = rows.filter(user => user.subscriptionId === "MANUAL")

        return { columns: this.getColumns(), rows };
    }

    render() {
        const { showOnlyPremiumManual } = this.state
        const checkPremiumManual = () => this.setState({ showOnlyPremiumManual: !showOnlyPremiumManual })
        return (
            <div className="content-wrapper p-5">
                <div className="row">
                    <div className="col-md-12">
                        <h1>Usuarios</h1>
                        <div className="form-check form-check-inline">
                            <input name="showOnlyPremiumManual" checked={showOnlyPremiumManual} className="form-check-input" onChange={() => this.setState({ showOnlyPremiumManual: !showOnlyPremiumManual })} type="checkbox" />
                        </div>
                        <label>Mostrar solo usuarios con premium manual</label>

                        <DataTableComponent data={this.getData()} />

                        <div className="btn-row"  >
                            <button type="button" className="btn btn-primary" onClick={() => this.props.history.push("/admin/Users/User")}>Crear nuevo usuario</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
UserComponent.contextType = CurrentUser;
export default UserComponent;