import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';

class ModalRegistrar extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Modal {...this.props} size="lg" className="progress-modal" centered >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <h5 className="modal-title" id="modalTerminoLabel">Registrar Cuenta</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                        <p className="text-center mb-0">
                            <strong>
                                Asegúrese de haber completado su información correctamente. El registro de su cuenta puede tardar un momento, no cierre su navegador mientras se está cargando.
                            </strong>
                        </p>
                </Modal.Body>
                <Modal.Footer >
                <button role="button" className="btn btn-primary" onClick={this.props.closeModal}>Cerrar</button>
                    <button role="button" className="btn btn-primary" onClick={this.props.registrarCuenta}>Registrar</button>
                </Modal.Footer>
            </Modal>
        );
    }
}
export default ModalRegistrar;