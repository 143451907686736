export function getDateFormatted(date) {
    let dateObj = new Date(date);
    let formattedDate = `${dateObj.getFullYear()}/${dateObj.getMonth() + 1}/${dateObj.getDate()}`;
    return formattedDate;
}

export function getDateFormattedFirstDay(date) {
    let dateObj = new Date(date);
    let formattedDate = `${dateObj.getFullYear()}/${(dateObj.getMonth() + 1).toString().padStart(2,0)}/${dateObj.getDate().toString().padStart(2,0)}`;
    return formattedDate;
}