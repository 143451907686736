import React, { Component } from "react";
import fotoMain from '../../resources/images/fotos/equipo5.jpg';
import fotoAlma from '../../resources/images/fotos/alma.jpg';
import fotoMiguel from '../../resources/images/fotos/miguel.JPG';
import fotoSamantha from '../../resources/images/fotos/samantha.jpeg';
import fotoJesus from '../../resources/images/fotos/jesus.jpeg';

class PublicAboutComponent extends Component {
	render() {
		return (
			<React.Fragment>
				<section className="about-intro intro bg-secondary " style={{ backgroundImage: `url(${fotoMain})` }} />

				<section className="about-page">
					<div className="container">
						<div className="row text-dark  ">
							<div className="col-12 mt-sm-5 mb-5">
								<p className="h3 mb-4 ">Somos un equipo de <strong>Médicos Veterinarios especializados</strong> en cirugía de pequeñas especies y mascotas exóticas con más de 20 años de experiencia.</p>
								<p className="text">Cirugía Veterinaria surge de la necesidad de tener una <strong>herramienta de apoyo para el médico veterinario</strong> especialista en pequeñas especies que vive en lugares apartados de los centros educativos y especialistas, que se enfrenta continuamente a casos que requieren una <strong>terapia quirúrgica</strong> y no cuenta con la experiencia necesaria.</p>
								<p className="text">Hoy en dia CxVet ya es parte de las herramientas de muchas Universidades. Estamos avalados por especialistas de la Universidad Nacional autónoma de México y por el CONCERVET (Consejo nacional de certificación veterinaria)</p>
							</div>
						</div>
						<div className="row mb-5 justify-content-center">
							<div className="col-md-7 ">
								<div className="embed-responsive embed-responsive-16by9">
									<iframe width="560" height="315" src="https://www.youtube.com/embed/7fSRJuNgB5Q" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
								</div>
							</div>
						</div>
						<div className="row mb-5">
							<div className="col-md-6">
								<h2>Misión</h2>
								<p>Compartir el conocimiento de la cirugía en pequeñas especies y mascotas exóticas para mejorar el ejercicio profesional del médico veterinario especialista en este ramo y para todas aquellas personas interesadas en el tema, a través de un sitio web y videoconferencias de los más reconocidos especialistas, dispuesto al alcance de todos sin importar el lugar en donde se encuentre.</p>
							</div>
							<div className="col-md-6">
								<h2>Visión</h2>
								<p>Ser una empresa líder mundial, en la enseñanza y práctica de las mejores técnicas en la cirugía veterinaria, innovando día con día para mejorar el conocimiento de los médicos, así como la calidad de vida de las pequeñas especies y mascotas exóticas.</p>
							</div>
						</div>

						<div className="row mb-5">
							<div className="col-md-3 col-8">
								<span className="foto-bio mb-3" style={{ backgroundImage: `url(${fotoAlma})` }} />
							</div>
							<div className="col-md-9">
								<h2 className="mb-0">Alma Miranda</h2>
								<p className="font-weight-bold">Socia fundadora</p>
								<p>Egresada de la Facultad de Medicina Veterinaria de la Universidad Nacional Autónoma de México (UNAM).</p>
								<p>Es médico certificado con 25 años de experiencia en el ramo de pequeñas especies.</p>
								<p>Actualmente es directora general del centro médico veterinario “Pet Vet” localizado en la ciudad de San Miguel Allende, Guanajuato.</p>
								<p>Coautora del libro: manual práctico de cirugia del Sistema genital y urinario en pequeñas especies.</p>
							</div>
						</div>
						<div className="row ">
							<div className="col-md-3 col-8">
								<span className="foto-bio mb-3 " style={{ backgroundImage: `url(${fotoMiguel})` }} />
							</div>
							<div className="col-md-9">
								<h2 className="mb-0">Miguel Villalva</h2>
								<p className="font-weight-bold">Socio fundador</p>
								<p>Egresado de la Facultad de Medicina Veterinaria de la Universidad Nacional Autónoma de México (UNAM) en el año 1985-1989.</p>
								<p>Fue profesor adjunto del departamento de Cirugía Veterinaria en la UNAM 1989-l993. Realizó un diplomado en Cirugía y Ortopedia en pequeñas especies, es creador de la técnica “Férula tubular para corrección de hematoma auricular” con la cuál en el año 1997 fue ganador con el mejor trabajo en el Congreso Nacional de Medicina Cirugía y Zootecnia de AMMVEPE.</p>
								<p>Presidente fundador de la asociación de médicos veterinarios especialistas en pequeñas especies de Celaya; Gto. desde 2003. Es socio fundador de la Asociación Iberoamericana de Ortopedia en animales desde el año 2001. Es conferencista dentro de México, Centro y Sudamérica.</p>
								<p>Es autor de diversos artículos en el área de cirugía de tejidos blandos y ortopedia, así como en el área de diagnóstico y laboratorio clínico; también es promotor y autor de video-libros sobre cirugía de tejidos blandos, así como también de cirugía ortopédica y neurocirugía en pequeñas especies y mascotas exóticas.</p>
								<p>Es director general del hospital veterinario Ciudad Mascota en la ciudad de Celaya; Gto. Desde el año de 2003 ha sido asesor externo de los laboratorios IDEXX, líder mundial en equipo de diagnóstico.</p>
								<p>Socio fundador de cirugiaveterinaria.mx un espacio de educación continua para la vida profesional</p>
							</div>
						</div>

						<div className="row ">
							<div className="col-md-3 col-8">
								<span className="foto-bio mb-3 " style={{ backgroundImage: `url(${fotoSamantha})` }} />
							</div>
							<div className="col-md-9">
								<h2 className="mb-0">Dra. Samantha Maerker</h2>
								<p className="font-weight-bold">Socio fundador</p>
								<p>Egresada de la Facultad de Medicina Veterinaria y Zootecnia (FMVZ), UNAM 1992.</p>
								<p>Directora del área de cirugía de perros, gatos y animales no convencionales en el Centro Veterinario México, del cual también es socia fundadora.</p>
								<p>Diplomado en medicina, cirugía y zootecnia en perros y gatos en la FMVZ, UNAM.</p>
								<p>Autora del capítulo de “cirugía genitourinaria en pequeñas especies” del diplomado a distancia en medicina, cirugía y zootecnia en perros y gatos UNAM.</p>
								<p>Diplomado a distancia en traumatología y ortopedia en perros y gatos, obtuvo el 1er nivel de la preparación ortopédica de la AOVet trauma.</p>
								<p>Académica en la carrera de Medicina Veterinaria y Zootecnia de la Universidad del Valle de México.</p>
								<p>Ha impartido cursos y ponencias a sobre cirugía y urgencias en perros gatos y animales no convencionales.</p>
								<p>Socio fundador de cirugiaveterinaria.mx un espacio de educación continua para la vida profesional.</p>
							</div>
						</div>

						<div className="row ">
							<div className="col-md-3 col-8">
								<span className="foto-bio mb-3 " style={{ backgroundImage: `url(${fotoJesus})` }} />
							</div>
							<div className="col-md-9">
								<h2 className="mb-0">Dr. Jesús Paredes</h2>
								<p className="font-weight-bold">Socio fundador</p>
								<p>Egresado de la Universidad Michoacana de San Nicolás de Hidalgo 1977-1982.</p>
								<p>Internado en Medicina y Cirugía de Pequeñas Especies Hospital Veterinario de la Universidad Nacional Autónoma de México (UNAM) 1983-1984.</p>
								<p>Residencia en Medicina y Cirugía para Pequeñas Especies Hospital Veterinario de la UNAM 1985-1987.</p>
								<p>Profesor de la cátedra de Cirugía en la Facultad de Medicina Veterinaria y Zootecnia de la UNAM desde 1987.</p>
								<p>Cirujano adscrito al Hospital Veterinario de la UNAM desde 1987.</p>
								<p>Conferenciante en los Temas de Cirugía y Urgencias de Perros y Gatos, en México y otros países de Latinoamérica.</p>
								<p>Socio Fundador del Centro Veterinario México.</p>
								<p>Ex-presidente de la Sociedad Latinoamericana de Emergencias y Cuidados Críticos Veterinarios - LAVECCS.</p>
								<p>Socio fundador de cirugiaveterinaria.mx un espacio de educación continua para la vida profesional..</p>
							</div>
						</div>

					</div>
				</section>
			</React.Fragment>
		);
	}
}
export default PublicAboutComponent;