import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import Alert from 'react-bootstrap/Alert';
import DataTableComponent from '../../admin/core/DataTableComponent';
import firebase from 'firebase';

var moment = require("moment");
moment.locale("es");

class UsuariosModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            users: [],
            diplomadoId: this.props.diplomadoId,
            users: [],
            diplomadoUsers: this.props.diplomadoUsers,
        }
    }

    componentDidMount = () => {
        const db = firebase.firestore();

        //get users
        db.collection('users').get().then((usersColl) => {
            let users = usersColl.docs.map((doc) => { return { id: doc.id, ...doc.data() } });
            this.setState({ users, })

        }).catch(error => console.log(error));

    }

    handleUsersCheck = (event) => {
        let diplomadoUsersCopy = this.state.diplomadoUsers
        const index = diplomadoUsersCopy.indexOf(event.target.name);

        if (index > -1) {
            diplomadoUsersCopy.splice(index, 1);
        }
        else {
            diplomadoUsersCopy.push(event.target.name)
        }
        this.setState({ diplomadoUsers: diplomadoUsersCopy })
    }

    getUserColumns = () => {
        if (window.innerWidth < 600)
            return [
                { label: '+', field: 'select', sort: 'asc' },
                { label: 'Nombre y Correo', field: 'info', sort: 'asc' },
                { label: 'Correo', field: 'email', sort: 'asc' },
                { label: 'Membresía', field: 'type', sort: 'asc' },
            ];
        else
            return [
                { label: 'Agregar', field: 'select', sort: 'asc' },
                { label: 'Nombre', field: 'name', sort: 'asc' },
                { label: 'Correo', field: 'email', sort: 'asc' },
                { label: 'Membresía', field: 'type', sort: 'asc' },
            ];
    }
    getUserData = (diplomadoUsers) => {
        const rows = [];

        this.state.users.map(user => {
            if (user.type === "Premium" || user.type === "Admin") {
                if (window.innerWidth < 600) {
                    rows.push({
                        select: <div className="form-check form-check-inline">
                            <input name={user.id} disabled={user.type === "Gratis"} checked={(this.state.diplomadoUsers.includes(user.id)) ? true : false} className="form-check-input" onChange={this.handleUsersCheck} type="checkbox" />
                        </div>,
                        info:<div className="dispositivo-column"><span > {user.name}</span><span > {user.email}</span></div>,
                        email: user.email,
                        type: user.type,
                    });
                }
                else {
                    rows.push({
                        select: <div className="form-check form-check-inline">
                            <input name={user.id} disabled={user.type === "Gratis"} checked={(this.state.diplomadoUsers.includes(user.id)) ? true : false} className="form-check-input" onChange={this.handleUsersCheck} type="checkbox" />
                        </div>,
                        name: user.name,
                        email: user.email,
                        type: user.type,
                    });
                }
            }
        });
        return { columns: this.getUserColumns(), rows };
    }

    saveUsers = () => {
        const db = firebase.firestore();

        db.collection("diplomados").doc(this.state.diplomadoId).set({
            users: this.state.diplomadoUsers,
        }, { merge: true }).then(() => {
            this.props.onHide()
        })
    }

    render() {

        return (
            <Modal  {...this.props} size="lg" className="progress-modal" centered >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <h5 className="modal-title" id="modalTerminoLabel">Administrar Usuarios</h5>
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <p className="text-center mb-0">Usuarios</p>
                    <DataTableComponent  data={this.getUserData(this.props.diplomadoUsers)} />
                </Modal.Body>

                <Modal.Footer >
                    <button role="button" className="btn btn-primary" onClick={this.props.onHide}>Cerrar</button>
                    <button role="button" className="btn btn-primary" onClick={this.saveUsers}>Guardar</button>
                </Modal.Footer>
            </Modal>
        );
    }
}
export default UsuariosModal;