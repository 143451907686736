import React, {Component} from 'react';

class TagItemComponent extends Component{
    constructor(props){
        super(props);
    }

    render(){
        const tag = this.props.tag;
        const url = `/procedimientos/tag/${tag.id}`;
        return(
            <li className=""><a href={url}>{tag.name}</a></li>
        )
    }
}

export default TagItemComponent;