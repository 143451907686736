import React from "react";

const TableComponent = (props) =>
    <table className="table">
        <tbody>
            <tr>
                <th className="col">{props.headerOne}</th>
                <th className="col">{props.headerTwo}</th>
            </tr>
            {props.rows.map((row, index) =>
                <tr key={index}>
                    <td>{row.name}</td>
                    <td>{row.amount}</td>
                </tr>
            )}
        </tbody>
    </table>

const TablaSimple = (props) => (
    <div className="col-md-6 mt-3">
        <h3 className="">{props.tableName}</h3>
        {props.rows.length === 0
            ? <div>No hay información para mostrar</div>
            : <TableComponent {...props} />}
    </div>
)
export default TablaSimple;