import React, { Component } from "react";
import firebase from 'firebase';
import { Redirect } from 'react-router-dom';


import logo from '../../../resources/images/logo.svg';
import logoTwitter from '../../../resources/images/icon-twitter.svg';
import logoFacebook from '../../../resources/images/icon-facebook.svg';
import logoYoutube from '../../../resources/images/icon-youtube.svg';
import logoInstagram from '../../../resources/images/icon-instagram.svg';
import iconUser from '../../../resources/images/icon-user.svg';
import iconUserPremium from '../../../resources/images/icon-user-premium.svg';

import logoTwitter2 from '../../../resources/images/icon-twitter-2.svg';
import logoFacebook2 from '../../../resources/images/icon-facebook-2.svg';
import logoYoutube2 from '../../../resources/images/icon-youtube-2.svg';
import logoInstagram2 from '../../../resources/images/icon-instagram-2.svg';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboardCheck, faSignInAlt, faSignOutAlt, faUserCog, faIdBadge } from '@fortawesome/free-solid-svg-icons';

import CurrentUser from "../core/PublicUserSessionContext";

class PublicNavigationComponent extends Component {
    constructor(props) {
        super(props);
        this.state = { logout: false, showMobileBar: false };
        console.log("NavComp.context (constructor): ", this.context);
    }
    componentWillMount() {
        console.log("NavComp.context (cwm): ", this.context);
    }
    componentDidMount() {
        console.log("NavComp.context (cdm): ", this.context);
    }

    logout = () => {
        console.log("NavComp.logout() ");
        firebase.auth().signOut().then(() => {
            this.setState({ logout: true });
        }).catch(function (error) {
            this.context.mostrarError("No se pudo cerrar sesión, intenta de nuevo más tarde", "ERROR CERRAR SESIÓN")
            console.log(error);
        });
    }
    toggleMobileBar = () => {
        this.setState({ showMobileBar: !this.state.showMobileBar });
    }
    redirect = () => {
        this.setState({ logout: false });
        return <Redirect to="/" />;
    }

    render() {
        let currentUser = this.context.currentUser;
        console.log("NavComp.currentUser (render): ", currentUser);
        //console.log("NavComp.state.currentUser (render): ",this.state.currentUser);
        return (
            <React.Fragment>
                {this.state.logout && this.redirect()}
                <div className={`navs ${this.props.showHeader ? "" : "scrollDown"} ${this.props.scrolling ? "" : "noscroll"}
                ${currentUser.type === "Gratis" ? "gratis" : "premium"}`}>

                    <nav className=" navbar navbar-expand-sm navbar-primary bg-primary ">
                        <div className="container-fluid home ">
                            <div className="order-1 order-md-0 ">
                                {currentUser.type === "Gest" ?
                                    null
                                    :
                                    <ul className="navbar-nav mr-auto d-none d-sm-flex flex-row align-items-center saludo">
                                        <li className="mr-2 iconito">
                                            ${currentUser.type === "Gratis" ?
                                                <img width="30" src={iconUser} />
                                                :
                                                <img width="30" src={iconUserPremium} />
                                            }

                                        </li>
                                        <li className="">

                                            <div className="text-light">Bienvenid@ {currentUser.name}</div>
                                        </li>
                                    </ul>
                                }

                            </div>

                            <div className="  order-2 flex-row">

                                {currentUser.type === "Gest" ?
                                    <ul className="navbar-nav ml-auto text-right d-flex flex-row">
                                        <li className="nav-item ">
                                            <a className="nav-link p-2 p-sm-1 text-light" href="/registro"><FontAwesomeIcon icon={faClipboardCheck} /> Unirte ahora</a>
                                        </li>
                                        <li className="nav-item ml-2">
                                            <a className="nav-link p-2 p-sm-1 text-light" href="/login"><FontAwesomeIcon icon={faSignInAlt} /> Iniciar sesión</a>
                                        </li>
                                    </ul>
                                    :
                                    <ul className="navbar-nav ml-auto text-right d-flex flex-row">
                                        {currentUser.type === "Admin" ?
                                            <>
                                                <li className="nav-item ">
                                                    <a className="nav-link p-2 p-sm-1 text-light" href="/admin/landing/fotos"> <FontAwesomeIcon icon={faUserCog} /> Fotos landing</a>
                                                </li>
                                                <li className="nav-item ">
                                                    <a className="nav-link p-2 p-sm-1 text-light" href="/admin"> <FontAwesomeIcon icon={faUserCog} /> Administrador</a>
                                                </li>
                                            </>
                                            :
                                            <li className="nav-item ">
                                                <a className="nav-link p-2 p-sm-1 text-light" href="/perfil"> <FontAwesomeIcon icon={faIdBadge} /> Mi CxVet</a>
                                            </li>
                                        }
                                        <li className="nav-item  ml-2">
                                            <a className="nav-link p-2 p-sm-1 text-light " href="" onClick={this.logout}>  <FontAwesomeIcon icon={faSignOutAlt} /> Cerrar sesión</a>
                                        </li>
                                    </ul>
                                }
                            </div>
                        </div>
                    </nav>
                    <nav className="main-nav navbar navbar-expand-md navbar-light bg-white">
                        <div className="container-fluid home">
                            <div className="mx-auto order-0 justify-content-between d-flex w-100 ">
                                <a className="navbar-brand " href="/index">
                                    <img src={logo} />
                                </a>
                                <button onClick={this.toggleMobileBar}
                                    className={`navbar-toggler ${this.state.showMobileBar ? "" : "collapsed"}`}
                                    type="button" data-toggle="collapse" data-target=".dual-collapse2">
                                    <span className="navbar-toggler-icon"></span>
                                </button>
                            </div>
                            <div className={`navbar-collapse justify-content-end collapse w-100 order-2 dual-collapse2 ${this.state.showMobileBar ? "show" : ""} `}>
                                <div className="d-flex flex-column align-items-md-end">
                                    <ul className="navbar-nav nav-link d-none d-md-flex flex-row">
                                        <li className="nav-item">
                                            <a className="ml-2 text-light" target="_blank" href="https://www.facebook.com/cirugiaveterinaria.mx/"><img width="30" src={logoFacebook2} /></a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="ml-2 text-light" target="_blank" href="https://twitter.com/CirugiaVet"><img width="30" src={logoTwitter2} /></a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="ml-2 text-light" target="_blank" href="https://www.youtube.com/user/cirugiaveterinariamx/videos"><img width="30" src={logoYoutube2} /></a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="ml-2 text-light" target="_blank" href="https://www.instagram.com/cirugiaveterinaria.mx/?hl=es-la"><img width="30" src={logoInstagram2} /></a>
                                        </li>
                                    </ul>
                                    <ul className="navbar-nav ml-auto text-center w-100 justify-content-end ml-lg-0 d-lg-flex align-items-center">

                                        {currentUser.adminAula && <li className="nav-item ">
                                            <a className="nav-link" href="/administrar-aula">Administrar Aula</a>
                                        </li>}
                                        {currentUser.adminDiplomado && <li className="nav-item ">
                                            <a className="nav-link" href="/administrar-diplomado">Administrar Diplomado</a>
                                        </li>}
                                        {(currentUser.type === "Premium" || currentUser.type === "Admin") && <li className="nav-item ">
                                            <a className="nav-link" href="/mis-diplomados">Cursos y diplomados</a>
                                        </li>}
                                        {currentUser.id && <li className="nav-item ">
                                            <a className="nav-link" href="/mis-aulas">Aulas Virtuales</a>
                                        </li>}

                                        <li className="nav-item ">
                                            <a className="nav-link" href="/index">Home</a>
                                        </li>
                                        <li className="nav-item ">
                                            <a className="nav-link" href="/procedimientos">Cirugías</a>
                                        </li>
                                        <li className="nav-item ">
                                            <a className="nav-link" href="/FAQ">FAQ</a>
                                        </li>
                                        <li className="nav-item ">
                                            <a className="nav-link" href="/eventos">Eventos</a>
                                        </li>
                                        <li className="nav-item ">
                                            <a className="nav-link" href="/about">Acerca de</a>
                                        </li>
                                        <li className="nav-item ">
                                            <a className="nav-link" href="/contacto">Contacto</a>
                                        </li>


                                    </ul>

                                    {currentUser.type === "Gest" ?
                                        <ul className="border-top mt-4 pt-4 d-sm-none navbar-nav text-center w-100 justify-content-between align-items-center">
                                            <li className="nav-item ">
                                                <a className="nav-link" href="/registro">Unirte ahora</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" href="/login">Iniciar sesión</a>
                                            </li>
                                        </ul>
                                        :
                                        <ul className="border-top mt-4 pt-4 d-sm-none navbar-nav text-center w-100 justify-content-between align-items-center">
                                            {currentUser.type === "Admin" ?
                                                <li className="nav-item ">
                                                    <a className="nav-link" href="/admin">Consola de administrador</a>
                                                </li>
                                                :
                                                <li className="nav-item ">
                                                    <a className="nav-link" href="/perfil">Mi CxVet</a>
                                                </li>
                                            }
                                            <li className="nav-item">
                                                <a className="nav-link " href="" onClick={this.logout}>Cerrar sesión</a>
                                            </li>
                                        </ul>
                                    }


                                    <ul className=" mt-4 pt-4  d-md-none d-flex list-unstyled justify-content-center">
                                        <li className="nav-item">
                                            <a className="ml-2 text-light" target="_blank" href="https://www.facebook.com/cirugiaveterinaria.mx/"><img width="30" src={logoFacebook2} /></a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="ml-2 text-light" target="_blank" href="https://twitter.com/CirugiaVet"><img width="30" src={logoTwitter2} /></a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="ml-2 text-light" target="_blank" href="https://www.youtube.com/user/cirugiaveterinariamx/videos"><img width="30" src={logoYoutube2} /></a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="ml-2 text-light" target="_blank" href="https://www.instagram.com/cirugiaveterinaria.mx/?hl=es-la"><img width="30" src={logoInstagram2} /></a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </nav>
                </div>
            </React.Fragment>


        );
    }
}
PublicNavigationComponent.contextType = CurrentUser;

export default PublicNavigationComponent;