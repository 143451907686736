import React,{Component} from 'react';
import Modal from 'react-bootstrap/Modal';

class InvalidTokenComponent extends Component {
    constructor(props){
        super(props);
    }

    render() {
        return (
            <Modal {...this.props} size="md" aria-labelledby="contained-modal-title-vcenter" >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <h5 className="modal-title" id="modalTerminoLabel">Alerta de uso de cuenta</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Detectamos que se ha ingresado con esta cuenta en otro dispositivo.</p>
                    <p>Segun nuestras politicas de uso solo puedes utilizar tu cuenta en un dispositivo a la vez.</p>
                   
                </Modal.Body>
                <Modal.Footer>
                  <button type="button" onClick ={(e)=>{this.props.setNewToken()}} className="btn btn-secondary btn-block">
                        Usar en este dispositivo
                  </button>
                </Modal.Footer>

            </Modal>
        );
    }
}
export default InvalidTokenComponent;