import React, { Component } from 'react';
import firebase from 'firebase';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import UploadFileAlertComponent from '../core/UploadFileAlertComponent';
import CurrentUser from "../../public/core/PublicUserSessionContext";
import DatePicker from 'react-date-picker';
import es from '@ckeditor/ckeditor5-build-classic/build/translations/es';
import Spinner from "../../commons/Spinner";
import { getOrganization, saveOrganizationData, uploadLogoImage, uploadPromoBannerImage } from '../../../service/organizations';
import { ORGANIZATION_TYPES } from '../../../service/constants';


class OrganizationFormComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            organizationID: this.props.match.params.id,
            editing: this.props.match.params.id ? true : false,
            loading: true,
            fileModalShow: false,
            fileStatus: 0,
            errors: {},

            organization: {
                title: "",
                logoImageURL: "",
                type: "",
                promoImageURL: "",
                promoLink: "",
            }
        }
    }

    componentDidMount() {
        this.getInitialData()
    }

    getInitialData = async () => {
        const { organizationID, editing } = this.state

        if (editing) {
            let organization = await getOrganization(organizationID)
            this.setState({ organization });
        }

        this.setState({ loading: false })
    }

    handleInputChange = (event) => {
        let { name, value } = event.target
        let organization = { ...this.state.organization, [name]: value }
        if (name === "type" && value === "Universidad") {
            organization.promoImageURL = ""
            organization.promoLink = ""
        }
        this.setState({ organization, errors: {} })
    }
    handleOrganizationFileChange = (event) => {
        const [file, fileType, urlType] = [event.target.files[0], `${event.target.name}File`, `${event.target.name}URL`]
        let url = URL.createObjectURL(file)
        let organization = { ...this.state.organization, [urlType]: url }
        this.setState({ organization, [fileType]: file, errors: {} })
    }

    submitOrganization = async (event) => {
        event.preventDefault()
        if (this.validateErrors()) {
            const { organizationID, logoImageFile, promoImageFile, organization, editing } = this.state
            this.setState({ loading: true })

            let logoImageURL = logoImageFile
                ? await uploadLogoImage(organizationID, logoImageFile, (data) => this.setState(data))
                : organization.logoImageURL
            let promoImageURL = (promoImageFile && organization.type === "Empresa")
                ? await uploadPromoBannerImage(organizationID, promoImageFile, (data) => this.setState(data))
                : organization.promoImageURL

            let data = { ...organization, logoImageURL, promoImageURL,  }
            if (!editing)
                data.date_created = Date.now()

            await saveOrganizationData(organizationID, data)
            this.setState({ loading: false })
            this.props.history.push("/admin/organizations")
        }
    }
    validateErrors = () => {
        let errors = {}
        const required = ["title", "logoImageURL", "type"]

        required.forEach(field => {
            if (!this.state.organization[field])
                errors[field] = true
        })
        if (this.state.organization.type === "Empresa" && !this.state.organization.promoImageURL)
            errors.promoImageURL = true
        if (this.state.organization.type === "Empresa" && !this.state.organization.promoLink)
            errors.promoLink = true

        this.setState({ errors })
        return Object.keys(errors).length === 0
    }

    render() {
        const errors = this.state.errors
        const { organizationID, organization } = this.state
        return (
            <Form onSubmit={this.submitOrganization}>
                <div className="content-wrapper p-5">
                    <div className="row">
                        <div className="col-md-12">
                            <h1 className="mb-0">Organización</h1>
                            <h3 className="mb-5">{organizationID ? "Editar Organización" : "Nueva Organización"}</h3>
                            <div className="row justify-content-center mb-4">
                                <div className="col-md-10">
                                    <h4>Información general del organización</h4>
                                    <div className="form-container">
                                        <div className="row">
                                            <div className="col-md-12">

                                                <div className="form-group">
                                                    <label>Título</label>
                                                    <input name="title" onChange={this.handleInputChange} value={organization.title} type="text" className="form-control" />
                                                    {errors.title && <Alert variant="danger"> Este campo es requerido.</Alert>}
                                                </div>

                                                <div className="col-md-4 col-sm-6">
                                                    <div className="form-group">
                                                        <label >Subir Logo </label>
                                                        <img className="img-fluid  mb-2 preview" src={organization.logoImageURL} />
                                                        <input name="logoImage" onChange={this.handleOrganizationFileChange} type="file" className="form-control-file" accept="image/jpeg,image/jpg,image/png" />
                                                        <small class="form-text text-muted">La imagen debe tener dimensiones mínimas de 1090px x 720px</small>
                                                        {errors.logoImageURL && <Alert variant="danger"> Se requiere la imagen del logo.</Alert>}
                                                    </div>
                                                </div>

                                                <div className="form-group">
                                                    <label>Universidad/Empresa</label>
                                                    <select name="type" onChange={this.handleInputChange} value={organization.type} className="form-control">
                                                        <option key="0" value="">Sin asignar</option>
                                                        {ORGANIZATION_TYPES.map(type => <option key={type} value={type}> {type} </option>)}
                                                    </select>
                                                    {errors.type && <Alert variant="danger"> Este campo es requerido.</Alert>}
                                                </div>

                                                {organization.type === "Empresa" &&
                                                    <React.Fragment>
                                                        <div className="col-md-4 col-sm-6">
                                                            <div className="form-group">
                                                                <label >Subir Promo </label>
                                                                <img className="img-fluid  mb-2 preview" src={organization.promoImageURL} />
                                                                <input name="promoImage" onChange={this.handleOrganizationFileChange} type="file" className="form-control-file" accept="image/jpeg,image/jpg,image/png" />
                                                                <small class="form-text text-muted">La imagen debe tener dimensiones mínimas de 1024px x 210px</small>
                                                                {errors.promoImageURL && <Alert variant="danger"> Se requiere la imagen del promo.</Alert>}
                                                            </div>
                                                        </div>

                                                        <div className="form-group">
                                                            <label>URL del promo</label>
                                                            <div className="row">
                                                                <div className="col-auto pr-1">
                                                                    <p className="mt-2 mb-0">https://</p>
                                                                </div>
                                                                <div className="col pl-1">
                                                                    <input name="promoLink" onChange={this.handleInputChange} value={organization.promoLink} type="text" className="form-control" />
                                                                </div>
                                                            </div>
                                                            {errors.promoLink && <Alert variant="danger"> Este campo es requerido.</Alert>}
                                                        </div>
                                                    </React.Fragment>}

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="btn-row" >
                                <button type="submit" className="btn btn-primary">Guardar</button>
                            </div>

                            <Spinner show={this.state.loading} />
                            <UploadFileAlertComponent show={this.state.fileModalShow} file={this.state.fileStatus} />

                        </div>
                    </div>
                </div>
            </Form>
        );
    }
}
OrganizationFormComponent.contextType = CurrentUser;
export default OrganizationFormComponent;