/** Firebase Config */
import firebase from "firebase";
import FIREBASE_CONFIG from "./keys";

  // Initialize Firebase
  const initApp = () => {
    firebase.initializeApp(FIREBASE_CONFIG)
    console.log('firebase config');
  };

  export default initApp;