import React, { Component } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import pat1 from '../../resources/images/logos/partner1.jpg';
import pat2 from '../../resources/images/logos/partner2.jpg';
import pat3 from '../../resources/images/logos/partner3.jpg';
import pat4 from '../../resources/images/logos/partner4.jpg';
import pat5 from '../../resources/images/logos/partner5.jpg';
import pat6 from '../../resources/images/logos/partner6.jpg';
import pat7 from '../../resources/images/logos/partner7.jpg';

const patrocinadores = [
    { link: "http://petvetsanmiguel.com/", foto: pat2 },
    { link: "https://maico.lat/", foto: pat4 },
    { link: "https://squenda.com.mx/", foto: pat1 },
    { link: "https://www.petfoodinstitute.org/", foto: pat5 },
    { link: "http://remevet.com/", foto: pat3 },
    { link: "https://www.facebook.com/cirugiaveterinaria.mavs", foto: pat7 },
    { link: "http://centroveterinariomexico.mx", foto: pat6 }];
const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 768 },
        items: 4,
        slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
        breakpoint: { max: 768, min: 464 },
        items: 2,
        slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        slidesToSlide: 1, // optional, default to 1.
    },
};
class Slider extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    componentDidMount() {
    }
    obtenerFotos = () => {
        return patrocinadores.map((patrocinador, index) => {
            return <div className="carousel-item col-12 active" key={index}>
                <a href={patrocinador.link} target="_blank"><img src={patrocinador.foto} className="img-fluid mx-auto d-block" alt="logo" /></a>
            </div>
        })
    }
    render() {
        return <Carousel
            swipeable={false}
            draggable={false}
            showDots={false}
            responsive={responsive}
            infinite={true}
            autoPlay={true}
            autoPlaySpeed={2500}
            arrows={true}
            transitionDuration={200}
            containerClass="carousel-container"
            itemClass="carousel-item-padding-40-px"
        >
            {this.obtenerFotos()}
        </Carousel>;
    }
}
export default Slider;