import Modal from "react-bootstrap/Modal";
import ClipLoader from "react-spinners/ClipLoader";
import React,{Component} from "react";
import { css } from '@emotion/core';
const override = css`
    display: block;
    margin: 0 auto;
    border-color: white;
`;
class ModalSpinner extends Component{
    render(){
        return <Modal className='spinner' show = {this.props.show}>
            
                <ClipLoader
                    css={override}
                    sizeUnit={"px"}
                    size={150}
                    color={'#ffffff'}
                    loading={true}
            />
            
        </Modal>
    }
}
export default ModalSpinner;

