import React, { Component } from 'react';
import ReactDOM from 'react-dom'
import firebase from 'firebase';
import ProgressBar from 'react-bootstrap/ProgressBar'
import CurrentUser from "../core/PublicUserSessionContext";
import Spinner from "../../commons/Spinner";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import { getUserExamData, hasPassedExam, saveUserExamProgress, resetExamProgress, deleteUserAnswers } from '../../../service/exams'
import { getUserProgress, getDiplomadoVideoData, setDiplomadoVideoCompleted, getDiplomado, } from '../../../service/diplomados'

import ExamComponent from './ExamComponent'
import VideoComponent from "./VideoComponent";
import CertificateModal from './CertificateModal'
import CompletedExamModal from './CompletedExamModal'

class DiplomadoInternoComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      videos: [],
      pdfs: [],
      images: [],
      active: { title: "", images: [], pdfs: [], index: 0 },
      activeType: "video",
      exams: [],
      certificateModalOpen: false,
      completedExamModalOpen: false,
      beganDiplomadoTimestamps: {},
      completedDiplomadoTimestamps: {},
      constanciaTimestamps: {},
      userProgress: 0.00,
      hasPendingExams: true,
      pendingExams: 0,
      certificateURL: "",

      selectedExam: 0,
      index: 0,
      diplomadoId: this.props.match.params.id,
      currentUserId: "",

      threshold: 80.00,
    }
    this.componentTitleRef = React.createRef();
  }

  componentDidMount = () => {
    this.getInitialDiplomadoData(this.context.currentUser.id)
  }

  getInitialDiplomadoData = async (currentUserId) => {
    let diplomado = await getDiplomado(this.state.diplomadoId).catch(error => {
      console.log(error)
      this.props.history.push('/mis-diplomados')
    });

    if (!diplomado.active)
      this.props.history.push('/mis-diplomados')
    else {
      //console.log("DIPLOMADO::::: ", diplomado)
      let videos = await getDiplomadoVideoData(this.state.diplomadoId, currentUserId)
      let exams = await getUserExamData(this.state.diplomadoId, currentUserId)
      let userProgress = getUserProgress(exams)
      this.checkPendingExams(exams)

      const firstTime = diplomado.completedDiplomadoTimestamps && !diplomado.completedDiplomadoTimestamps[currentUserId];
      this.setState({ ...diplomado, active: { title: "", images: [], pdfs: [], index: 0 }, exams, videos, active: videos[0], currentUserId, userProgress, certificateModalOpen: (userProgress >= 100 && firstTime), loading: false })
    }
  }

  checkPendingExams = (exams) => {
    let hasPendingExams = false
    let pendingExams = 0

    exams.forEach(exam => {
      if (exam.indexVideo < 0) {
        hasPendingExams = true
        pendingExams++
      }
    })

    this.setState({ hasPendingExams, pendingExams })
  }

  setUserProgress = (exams) => {
    let userProgress = getUserProgress(exams)
    this.setState({ userProgress })

    if (userProgress >= 100) {
      let completedTimestamp = Date.now(firebase.firestore.FieldValue.serverTimestamp())
      firebase.firestore().collection('diplomados').doc(this.state.diplomadoId).update({
        ["completedDiplomadoTimestamps." + this.state.currentUserId]: completedTimestamp
      }).then(() => { }).catch(error => console.log(error))
      this.setState({ completedDiplomadoTimestamps: { ...this.state.completedDiplomadoTimestamps, [this.state.currentUserId]: completedTimestamp }, certificateModalOpen: true })
    }
  }
  getConstancia = () => {
    let generatedTimestamp = Date.now(firebase.firestore.FieldValue.serverTimestamp());
    const constanciaTimestamps = { ...this.state.constanciaTimestamps, [this.state.currentUserId]: generatedTimestamp };
    console.log("getConstancia ", {generatedTimestamp, constanciaTimestamps})

    let timestampsArray = Object.entries(constanciaTimestamps);
    console.log("getConstancia timestampsArray: ", timestampsArray)
    timestampsArray.sort(([llaveA, timestampA], [llaveB, timestampB]) => timestampA > timestampB ? 1 : -1);
    console.log("getConstancia timestampsArray SORTED: ", timestampsArray)

    let consistenciaIndex = timestampsArray.findIndex(([userId, timestamp]) => {
      //console.log("FIND INDEX: ", [userId, timestamp])
      return userId === this.state.currentUserId
    });
    console.log("FOUND INDEX: ", consistenciaIndex)
    let str = "000000000" + `${consistenciaIndex + 1}`;
    let folio = str.substr(str.length - 3);

    firebase.firestore().collection('diplomados').doc(this.state.diplomadoId).update({
      ["constanciaTimestamps." + this.state.currentUserId]: generatedTimestamp,
      ["completedDiplomadoTimestamps." + this.state.currentUserId]: generatedTimestamp
    }).then(() => {
      this.setState({ constanciaTimestamps });

      firebase.firestore().collection('users').doc(this.state.currentUserId).update({
        completedDiplomados: firebase.firestore.FieldValue.arrayUnion({
          diplomadoId: this.state.diplomadoId,
          name: this.state.title,
          timestamp: generatedTimestamp,
          folio: folio,
          certificateURL: this.state.certificateURL,
        })
      }).then(() => {
        this.setState({ constanciaTimestamps });
      }).catch(error => console.log(error));

    }).catch(error => console.log(error));

    return folio;
  }

  changeAnswer = (indexQuestion, userAnswer) => {
    let examsCopy = this.state.exams.map(el => ({ ...el }))//deep copy
    examsCopy[this.state.selectedExam].questions[indexQuestion].userAnswer = userAnswer
    this.setState({ exams: examsCopy })
  }

  saveExam = async () => {
    this.setState({ loading: true })
    let examsCopy = this.state.exams.map(el => ({ ...el }))//deep copy
    examsCopy[this.state.selectedExam].userHasCompleted = true
    examsCopy[this.state.selectedExam].userHasPassed = hasPassedExam(examsCopy[this.state.selectedExam], this.state.threshold)

    await saveUserExamProgress(examsCopy[this.state.selectedExam], this.state.diplomadoId, this.state.currentUserId, examsCopy[this.state.selectedExam].userHasPassed)
    await this.setUserProgress(examsCopy)
    this.setState({ exams: examsCopy, completedExamModalOpen: true, loading: false })
  }

  retakeExam = async () => {
    let examsCopy = this.state.exams.map(el => ({ ...el }))//deep copy
    examsCopy[this.state.selectedExam].userHasCompleted = false
    examsCopy[this.state.selectedExam].userHasPassed = false

    //Reset all user answers to -1
    examsCopy[this.state.selectedExam].questions = examsCopy[this.state.selectedExam].questions.map(q => ({ ...q, userAnswer: -1 }))
    await resetExamProgress(examsCopy[this.state.selectedExam], this.state.diplomadoId, this.state.currentUserId)
    this.setState({ exams: examsCopy })
  }

  combineVideosAndExams = () => {
    let videosAndExams = []
    if (this.state.videos.length > 0) {
      videosAndExams = [...this.state.videos]
      this.state.exams.forEach((exam, index) => {
        if (exam.indexVideo >= 0) {
          let ind = videosAndExams.findIndex(vid => vid.index == exam.indexVideo)
          videosAndExams.splice(ind + 1, 0, { ...exam, type: "exam", examIndex: index })
        }
      })
    }
    videosAndExams.forEach(res => {
      console.log(res.index || res.title)
    })
    return videosAndExams
  }

  setActiveVideoOrExam = (index, component, activeType) => {
    if (activeType === "exam")
      this.setState({ selectedExam: index, activeType })
    else
      this.setState({ active: { ...component, index: index }, activeType })

    const winScrollPositionTop = document.body.scrollTop || document.documentElement.scrollTop
    const componentTitlePositionTop = this.componentTitleRef.current.getBoundingClientRect().y

    window.scrollTo({
      top: winScrollPositionTop + componentTitlePositionTop - 25,
      left: 0,
      behavior: 'smooth'
    })
  }

  render() {
    let videosAndExams = this.combineVideosAndExams()
    return (
      <React.Fragment>
        <Spinner show={this.state.loading} />

        <section className="about-intro intro bg-secondary " style={{ backgroundImage: `url(${this.state.previewImageURL})` }} >
        </section>

        <section className="about-page">
          <div className="container">
            <div className="row text-dark  ">
              <div className="col-12 mt-sm-5 mb-5">
                <h1 className="text-center mb-5">{this.state.title} </h1>
                <h4 >{ReactHtmlParser(this.state.description)} </h4>
              </div>
            </div>

            <div className="filter-sidebar mb-5" >
              <h1 className="main-title">Videos y Exámenes</h1>
              <ul className="list-unstyled pl-3">
                {videosAndExams.map((component, index) => {
                  let videoTitle = component.procedureId ? component.name : component.title
                  let active = true;
                  const isActivated = !!component.active;

                  return component.type === "exam" ? <><input disabled={!isActivated} checked={component.userHasPassed} className="form-check-input" type="checkbox" />
                    <li className={"Gratis active"}><button disabled={!active || !isActivated} className={`bg-white border-0  ${(active && isActivated) ? 'text-dark' : 'text-muted'}`} onClick={() => this.setActiveVideoOrExam(component.examIndex, component, "exam")}>{component.title}</button></li></>
                    :
                    <li className={"Gratis active"}><button disabled={!isActivated}  className={`bg-white border-0  ${(active && isActivated) ? 'text-dark' : 'text-muted'}`} onClick={() => this.setActiveVideoOrExam(component.index, component, "video")}>{videoTitle}</button></li>
                })}
              </ul>
              {this.state.exams.length > 0 && <><p className="main-title">Progreso del diplomado:</p>
                <ProgressBar now={this.state.userProgress} label={`${this.state.userProgress}%`} /></>}
            </div>
          </div>

          <div ref={this.componentTitleRef} />
          {this.state.activeType === "video" ?
            <VideoComponent video={this.state.active} setVideoCompleted={(videoId, index) => setDiplomadoVideoCompleted(this.state.diplomadoId, this.state.currentUserId, videoId)} />
            :
            <ExamComponent exam={this.state.exams[this.state.selectedExam]} currentUserId={this.state.currentUserId} threshold={this.state.threshold}
              changeAnswer={this.changeAnswer} saveExam={this.saveExam} retakeExam={this.retakeExam} />
          }
        </section>

        <CertificateModal getConstancia={this.getConstancia} show={this.state.certificateModalOpen && !this.state.completedExamModalOpen} onHide={() => this.setState({ certificateModalOpen: false })}
          timestamps={[this.state.beganDiplomadoTimestamps[this.state.currentUserId], this.state.completedDiplomadoTimestamps[this.state.currentUserId]]}
          name={this.context.currentUser.name} diplomadoTitle={this.state.title} certificateURL={this.state.certificateURL} />
        <CompletedExamModal show={this.state.completedExamModalOpen} onHide={() => this.setState({ completedExamModalOpen: false })} exam={this.state.exams[this.state.selectedExam] || { questions: [] }} threshold={this.state.threshold} />

      </React.Fragment>
    );
  }
}
DiplomadoInternoComponent.contextType = CurrentUser;
export default DiplomadoInternoComponent