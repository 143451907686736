import React, { Component } from 'react';
import firebase from 'firebase';
import ProgressBar from 'react-bootstrap/ProgressBar'
import CurrentUser from "../core/PublicUserSessionContext";
import Spinner from "../../commons/Spinner";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';

import ExamComponent from './ExamComponent'
import VideoComponent from "./VideoComponent";
import CertificateModal from './CertificateModal'

class DiplomadoPageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      videos: [],
      pdfs: [],
      images: [],
      active: { title: "", images: [], pdfs: [], index: 0 },
      activeType: "video",
      exams: [],
      certificateModalOpen: false,
      beganDiplomadoTimestamps: {},
      completedDiplomadoTimestamps: {},

      selectedExam: 0,
      index: 0,
      activeSlideIndex: 0,
      diplomadoId: "",
      currentUserId: "",

      userProgress: 0,
    }
  }

  componentDidMount = () => {
    this.getInitialDiplomadoData()
  }

  getInitialDiplomadoData = async () => {
    const diplomadoId = this.props.match.params.id
    let currentUserId = this.context.currentUser.id

    //get diplomado
    let diplomado = await firebase.firestore().collection('diplomados').doc(diplomadoId).get().catch(error => {
      console.log(error)
      this.setState({ loading: false })
    });

    if (!diplomado.data().active)
      this.props.history.push('/mis-diplomados')
    else {
      this.setState({ ...diplomado.data(), active: { title: "", images: [], pdfs: [], index: 0 }, diplomadoId, currentUserId });

      console.log("diplomado data: ", diplomado.data());
      await this.getVideoData(diplomadoId, currentUserId)
      await this.getInitialExamData(diplomadoId, currentUserId)

      this.setState({ loading: false })
    }
  }

  getInitialExamData = async (diplomadoId, currentUserId) => {
    const db = firebase.firestore().collection('diplomados').doc(diplomadoId).collection('exams')
    let exams = []

    let allExams = await db.get().catch(error => console.log(error));

    for (let index = 0; index < allExams.docs.length; index++) {
      let exam = allExams.docs[index]
      exams.push({ id: exam.id, title: exam.data().title, indexVideo: (exam.data().indexVideo + ""), questions: [], userHasCompleted: true })

      let allQuestions = await db.doc(exam.id).collection('questions').orderBy("originalOrder", "asc").get().catch(error => console.log(error));
      let questions = []

      for (let indexQ = 0; indexQ < allQuestions.docs.length; indexQ++) {
        let question = allQuestions.docs[indexQ]

        try {
          let userAnswer = await db.doc(exam.id).collection('questions').doc(question.id).collection('userAnswers').doc(currentUserId).get()
          questions.push({ id: question.id, ...question.data(), userAnswer: userAnswer.data().userAnswer })
          if (userAnswer.data().userAnswer === -1)
            exams[index].hasRetakenExam = true
        }
        catch (err) {
          questions.push({ id: question.id, ...question.data(), userAnswer: -1 })
          exams[index].userHasCompleted = false
          console.log(err)
        }
      }
      exams[index].questions = questions

    }
    this.setState({ exams })
    this.setUserProgress({ exams: exams, videos: this.state.videos })
  }

  getVideoData = async (diplomadoId, currentUserId) => {
    let allProcedures = await firebase.firestore().collection('procedures').get()
    let diplomadoVideos = await firebase.firestore().collection('diplomados').doc(diplomadoId).collection('videos').orderBy('index', 'asc').get()

    let videos = []
    diplomadoVideos.docs.forEach(video => {
      if (video.data().procedureId) {
        let procedure = allProcedures.docs.find(doc => doc.id === video.data().procedureId)
        if (procedure)
          videos.push({ id: video.id, ...video.data(), ...procedure.data(), userHasCompleted: video.data().completed.includes(currentUserId) })
      }
      else
        videos.push({ id: video.id, ...video.data(), userHasCompleted: video.data().completed.includes(currentUserId) })
    })
    this.setState({ videos, active: videos[0] })
  }

  setVideoCompleted = (videoId, index) => {
    let vCopy = [...this.state.videos]

    if (!vCopy[index].userHasCompleted) {
      vCopy[index].completed.push(this.state.currentUserId)
      vCopy[index].userHasCompleted = true
      this.setState({ videos: vCopy })

      firebase.firestore().collection("diplomados").doc(this.state.diplomadoId).collection("videos").doc(videoId).update({
        completed: firebase.firestore.FieldValue.arrayUnion(this.state.currentUserId)
      }).then(() => {
        this.setUserProgress({ exams: this.state.exams, videos: vCopy })
      }).catch(error => console.log("Error setting video completed: " + error))
    }
  }

  checkCompletedExam = (examIndex) => {
    let eCopy = [...this.state.exams]
    let exam = eCopy[examIndex]
    let done = true

    exam.questions.forEach((question, index) => {
      //(question.question + " - " + question.userAnswer)
      if (question.userAnswer === "-1" || question.userAnswer == -1)
        done = false

    })
    return done
  }

  setUserProgress = (data) => {
    let userProgress = 0
    let total = data.videos.length + data.exams.length
    let currentUserId = this.context.currentUser.id

    data.videos.forEach((video) => {
      if (video.completed && video.completed.includes(currentUserId))
        userProgress++
    })
    data.exams.forEach((exam, index) => {
      userProgress = userProgress + (exam.userHasCompleted ? 1 : 0)
    })

    if (userProgress >= total) {
      let completedTimestamp = Date.now(firebase.firestore.FieldValue.serverTimestamp())
      firebase.firestore().collection('diplomados').doc(this.state.diplomadoId).update({
        ["completedDiplomadoTimestamps." + currentUserId]: completedTimestamp
      }).then(() => { }).catch(error => console.log(error))
      this.setState({ completedDiplomadoTimestamps: { ...this.state.completedDiplomadoTimestamps, [currentUserId]: completedTimestamp }, certificateModalOpen: true })
    }

    this.setState({ userProgress: userProgress / total })
  }


  saveExam = () => {
    let examsCopy = [...this.state.exams]
    examsCopy[this.state.selectedExam].userHasCompleted = true
    let selectedExam = examsCopy[this.state.selectedExam]
    let currentUserId = this.context.currentUser.id
    this.setState({ exams: examsCopy })
    selectedExam.questions.forEach((question, index) => {

      firebase.firestore().collection("diplomados").doc(this.props.match.params.id).collection("exams").doc(selectedExam.id).collection("questions").doc(question.id).collection("userAnswers").doc(currentUserId).set({
        userAnswer: parseInt(question.userAnswer)
      }).then(() => {
        if (index === selectedExam.questions.length - 1) {
          console.log("done")
          this.setState({ activeType: "video" })
          this.setUserProgress({ exams: examsCopy, videos: this.state.videos })
        }
      }).catch(error => {
        console.log("Error: ", error)
        this.setState({ activeType: "video", exams: examsCopy, })
      })

    })

  }

  retakeExam = () => {
    let eCopy = [...this.state.exams]
    let selectedExam = eCopy[this.state.selectedExam]
    let currentUserId = this.context.currentUser.id
    eCopy[this.state.selectedExam].hasRetakenExam = true

    selectedExam.questions.forEach((question, index) => {
      selectedExam.questions[index].userAnswer = -1

      firebase.firestore().collection("diplomados").doc(this.props.match.params.id).collection("exams").doc(selectedExam.id).collection("questions").doc(question.id).collection("userAnswers").doc(currentUserId).set({
        userAnswer: -1
      }).then(() => {
        console.log("done")

      }).catch(error => {
        console.log("Error: ", error)
      })

    })

    this.setState({ exams: eCopy })
    this.setUserProgress({ exams: eCopy, videos: this.state.videos })
  }

  combineVideosAndExams = () => {
    let videosAndExams = []
    if (this.state.videos.length > 0) {
      videosAndExams = [...this.state.videos]
      this.state.exams.forEach((exam, index) => {
        let ind = videosAndExams.findIndex(vid => vid.index == exam.indexVideo)
        videosAndExams.splice(ind + 1, 0, { ...exam, type: "exam", examIndex: index })
      })
    }
    videosAndExams.forEach(res => {
      console.log(res.index || res.title)
    })
    return videosAndExams
  }

  setActiveVideoOrExam = (index, component, activeType) => {
    if (activeType === "exam")
      this.setState({ selectedExam: index, activeType })
    else
      this.setState({ active: { ...component, index: index }, activeType })

    window.scrollTo({
      top: window.innerWidth > 600 ? 2400 : 3000,
      left: 0,
      behavior: 'smooth'
    })
  }

  render() {
    let currentUserId = this.context.currentUser.id
    //let prevExam = undefined
    let videosAndExams = this.combineVideosAndExams()
    return (
      <React.Fragment>
        <Spinner show={this.state.loading} />

        <section className="about-intro intro bg-secondary " style={{ backgroundImage: `url(${this.state.previewImageURL})` }} >
        </section>

        <section className="about-page">
          <div className="container">
            <div className="row text-dark  ">
              <div className="col-12 mt-sm-5 mb-5">
                <h1 className="text-center mb-5">{this.state.title} </h1>
                <h4 >{ReactHtmlParser(this.state.description)} </h4>
              </div>
            </div>

            <div className="filter-sidebar mb-5" >
              <h1 className="main-title">Videos y Exámenes</h1>
              <ul className="list-unstyled pl-3">
                {videosAndExams.map((component, index) => {
                  let videoTitle = component.procedureId ? component.name : component.title
                  let activeTitle = this.state.active.name ? this.state.active.name : this.state.active.title
                  let active = index > 0 ? videosAndExams[(index - 1)].userHasCompleted : true

                  return component.type === "exam" ? <><input checked={component.userHasCompleted} className="form-check-input" type="checkbox" />
                    <li className={"Gratis active"}><button disabled={!active} className={`bg-white border-0  ${active ? 'text-dark' : 'text-muted'}`} onClick={() => this.setActiveVideoOrExam(component.examIndex, component, "exam")}>{component.title}</button></li></>
                    :
                    <><input checked={component.userHasCompleted} className="form-check-input" type="checkbox" />
                      <li className={"Gratis active"}><button disabled={!active} className={`bg-white border-0  ${active ? 'text-dark' : 'text-muted'}`} onClick={() => this.setActiveVideoOrExam(component.index, component, "video")}>{videoTitle}</button></li>
                    </>
                })}
              </ul>
              <p className="main-title">Progreso del diplomado:</p>
              <ProgressBar now={(this.state.userProgress * 100).toFixed(2)} label={`${(this.state.userProgress * 100).toFixed(2)}%`} />
            </div>
          </div>


          {this.state.activeType === "video" ?
            <VideoComponent video={this.state.active} setVideoCompleted={this.setVideoCompleted} />
            :
            <ExamComponent show={this.state.activeType === "exam"} exam={this.state.exams[this.state.selectedExam]} currentUserId={this.state.currentUserId}
              changeAnswer={(checked, indexQ, indexA) => {
                let eCopy = [...this.state.exams]
                let currentExam = eCopy[this.state.selectedExam]

                currentExam.questions[indexQ].userAnswer = indexA

                eCopy[this.state.selectedExam] = currentExam
                this.setState({ exams: eCopy })
              }}
              saveExam={this.saveExam}
              retakeExam={this.retakeExam} />
          }
        </section>

        <CertificateModal show={this.state.certificateModalOpen} onHide={() => this.setState({ certificateModalOpen: false })} name={this.context.currentUser.name} diplomadoTitle={this.state.title} timestamps={[this.state.beganDiplomadoTimestamps[currentUserId], this.state.completedDiplomadoTimestamps[currentUserId]]} />

      </React.Fragment>
    );
  }
}
DiplomadoPageComponent.contextType = CurrentUser;
export default DiplomadoPageComponent