import React,{Component} from 'react';
import CurrentUser from '../core/PublicUserSessionContext';
import WelcomeModalComponent from './WelcomeModalComponent';

class WelcomeComponent extends Component{
    constructor(props){
        super(props);
    }

    render(){
        let currentUser = this.context.currentUser;
        console.log("WelcomeComponent.render.context",currentUser);
        if(this.props.location.type === "Premium")
            currentUser = {...currentUser, type:"Premium"}
        return (
            <WelcomeModalComponent show={true} user={currentUser} />
        );
    }

}
WelcomeComponent.contextType = CurrentUser;

export default WelcomeComponent;