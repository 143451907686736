import firebase from 'firebase';
import { UNIVERSIDAD } from './constants';
import { uploadFileToStorage } from './storage';

export async function getOrganizations() {
    let response = await firebase.firestore().collection("organizations").get().catch(error => console.log(`Error obtener orgs: ${JSON.stringify(error)}`));
    let organizations = response.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    return organizations
}
export async function getOrganization(organizationID) {
    let organization = await firebase.firestore().collection("organizations").doc(organizationID).get()
    return organization.data()
}

export async function getUniversities() {
    let response = await firebase.firestore().collection("organizations").where("type", "==", UNIVERSIDAD).get().catch(error => console.log(`Error obtener unis: ${JSON.stringify(error)}`));
    let universities = response.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    return universities
}

export async function saveOrganizationData(organizationID, data) {
    organizationID = organizationID || firebase.firestore().collection("organizations").doc().id
    await firebase.firestore().collection("organizations").doc(organizationID).set(data, { merge: true }).catch(error => console.log(`Error al guardar organización: ${JSON.stringify(error)}`));
}

export async function uploadLogoImage(organizationID, logoImageFile, setState) {
    return await uploadFileToStorage("organizations", organizationID, "logo-image", logoImageFile, "Logo", setState)
}
export async function uploadPromoBannerImage(organizationID, promoImageFile, setState) {
    return await uploadFileToStorage("organizations", organizationID, "promo-banner-image", promoImageFile, "Banner", setState)
}