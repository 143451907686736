import React, { Component } from 'react';
import Dotdotdot from 'react-dotdotdot';

class DiplomadoItemComponent extends Component {
    constructor(props) {
        super(props);
    }

    render() {

        return (
            <div className="col-lg-4 col-md-6 video-container d-flex">

                <div className={"flex-fill video"} >
                    <a href={this.props.link}>
                        <div className="video-thumb" style={{ backgroundImage: `url(${this.props.previewImageURL})` }}>
                        </div>

                        <div className="video-content">
                            <h3>
                                {this.props.title}
                            </h3>
                            <p className="procedure_detail">
                                {this.props.buy ? <p>{this.props.buy}</p>
                                :<Dotdotdot clamp={3}>
                                    Ingrese a este diplomado para ver los recursos...
                            </Dotdotdot>}
                            </p>
                        </div>
                    </a>
                </div>

            </div>
        );
    }
}
export default DiplomadoItemComponent;