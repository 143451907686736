import React,{Component} from 'react';
import DataTableComponent from '../core/DataTableComponent'
var moment = require("moment");
moment.locale("es");

class UserListComponent extends Component{
    constructor(props){
        super(props);
        this.state = {users : this.props.users}
    }

    getColumns = () => {
        return  [
         { label: 'Nombre', field: 'name',sort:'asc' },
         { label: 'Correo electrónico', field: 'email',sort:'asc' },
         { label: 'Membresía', field: 'type',sort:'asc' },
         //{ label: 'Fecha de registro', field: 'date',sort:'asc'},
         { label: 'Fecha de pago', field: 'paymentDay',sort:'asc'},
         { label: 'Fecha de vigencia', field: 'terminationDate',sort:'asc'},
       ]; 
     }
    getData = () => {
        const rows = [];
        this.props.users.map(user => {
            const pathEdit = `/admin/Users/User/${user.id}`;
            rows.push({
                name: user.name,
                email: user.email,
                type: user.hasPremiumAnual ? "Anual" : "Mensual",
                paymentDay: moment(user.paymentDay).format("LL"),
                terminationDate: user.hasPremiumAnual ? moment(user.paymentDay).add(1, "year").format("LL") : moment(user.paymentDay).add(1, "month").format("LL")
              });
        });     
        return  {columns: this.getColumns(),rows};                  
    }

    render(){
        return(
            <DataTableComponent data={this.getData()} />
            
        );
    }
}
export default UserListComponent;