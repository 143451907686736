import React,{Component} from 'react';
import {Redirect,Link} from 'react-router-dom';
import CurrentUser from '../public/core/PublicUserSessionContext';

class HomeComponent extends Component{
    
    constructor(props){
        super(props);
        this.state = {
        }
    }

    render(){
        let currentUser = this.context;
        let url = "/";
        if(currentUser.type === "Admin"){
            url = "/admin";
        }
        return <Redirect to={url} />
    }
}
HomeComponent.contextType = CurrentUser;
export default HomeComponent;