import React, { Component } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import firebase from 'firebase';
import ProcedureComponent from './ProcedureComponent';
import ProcedureFromComponent from './ProcedureComponent/ProcedureFormComponent';
import AdminNavigationComponent from './core/AdminNavigationComponent';
import TagsListComponent from './ProcedureComponent/TagsListComponent';
import TagFormComponent from './ProcedureComponent/TagFormComponent';
import EventComponent from './EventComponent';
import EventFromComponent from './EventComponent/EventFormComponent';
import UserComponent from './UserComponent';
import UserFromComponent from './UserComponent/UserFormComponent';
import BulkMailComponent from './BulkMailComponent';
import StatisticsComponent from './StatisticsComponent';
import NewsletterComponent from "./NewsletterComponent";
import AdminPhotosLanding from "./AdminPhotosLanding"


import OrganizationsComponent from "./OrganizationsComponent";
import OrganizationFormComponent from "./OrganizationsComponent/OrganizationFormComponent";
import AulasComponent from "./AulasComponent";
import AulaFormComponent from "./AulasComponent/AulaFormComponent";
import DiplomadosComponent from "./DiplomadosComponent";
import DiplomadoFormComponent from "./DiplomadosComponent/DiplomadoFormComponent";
import DiplomadoVistaPrevia from "./DiplomadosComponent/DiplomadoVistaPrevia";
import PremiumManual from "./PremiumManual/PremiumManual";
import DowngradeManual from "./PremiumManual/DowngradeManual";
import UsersManual from "./PremiumManual";


class AdminRouterComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isAutenticated: false,
            user: null
        }
    }

    componentDidMount() {
        firebase.auth().onAuthStateChanged(user => {
            this.setState({ user });
            console.log(`ADMIN router auth state changed: ${user}`);
        });
    }

    validState = () => {
        console.log(firebase.auth().currentUser);
        return firebase.auth().currentUser;
    }

    render() {
        return (
            <Switch>
                {this.validState ?
                    <React.Fragment>
                        <AdminNavigationComponent />
                        <Route exact path="/admin/procedures" component={ProcedureComponent} />
                        <Route exact path="/admin/procedures/procedure/:id?" component={ProcedureFromComponent} />
                        <Route exact path="/admin/tags" component={TagsListComponent} />
                        <Route exact path="/admin/tags/tag/:id?" component={TagFormComponent} />
                        <Route exact path="/admin/events" component={EventComponent} />
                        <Route exact path="/admin/events/event/:id?" component={EventFromComponent} />
                        <Route exact path="/admin/users" component={UserComponent} />
                        <Route exact path="/admin/users/user/:id?" component={UserFromComponent} />
                        <Route exact path="/admin/mailing" component={BulkMailComponent} />
                        <Route exact path="/admin/home" component={ProcedureComponent} />
                        <Route exact path="/admin/estadisticas" component={StatisticsComponent} />
                        <Route exact path="/admin/newsletter" component={NewsletterComponent} />
                        <Route exact path="/admin" component={ProcedureComponent} />

                        <Route exact path="/admin/organizations" component={OrganizationsComponent} />
                        <Route exact path="/admin/organizations/crear-organization" component={OrganizationFormComponent} />
                        <Route exact path="/admin/organizations/organization/:id" component={OrganizationFormComponent} />

                        <Route exact path="/admin/aulas" component={AulasComponent} />
                        <Route exact path="/admin/aulas/crear-aula" component={AulaFormComponent} />
                        <Route exact path="/admin/aulas/aula/:id" component={AulaFormComponent} />

                        <Route exact path="/admin/diplomados" component={DiplomadosComponent} />
                        <Route exact path="/admin/diplomados/crear-diplomado" component={DiplomadoFormComponent} />
                        <Route exact path="/admin/diplomados/diplomado/:id" component={DiplomadoFormComponent} />
                        <Route exact path="/admin/diplomados/diplomado-vista-previa/:id" component={DiplomadoVistaPrevia} />

                        <Route exact path="/admin/users/premium-manual" component={UsersManual} />
                        <Route exact path="/admin/users/actualizar-premium-manual" component={PremiumManual} />
                        <Route exact path="/admin/users/downgrade-premium-manual" component={DowngradeManual} />

                        <Route exact path="/admin/landing/fotos" component={AdminPhotosLanding} />
                    </React.Fragment>
                    :
                    <Redirect to="/admin/login" />
                }
            </Switch>
        );
    }

}
export default AdminRouterComponent;