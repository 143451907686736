import React, { Component } from 'react';
import firebase from 'firebase';
import CurrentUser from "../../public/core/PublicUserSessionContext";
import Spinner from "../../commons/Spinner";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import VideoComponent from "../DiplomadosComponent/VideoComponent";
import UsuariosModal from './UsuariosModal'

class DiplomadoPageAdministrar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      videos: [],
      pdfs: [],
      images: [],
      active: { title: "", images: [], pdfs: [], index: 0 },
      activeType: "video",
      exams: [],
      certificateModalOpen: false,
      beganDiplomadoTimestamps: {},
      completedDiplomadoTimestamps: {},
      modalOpen: false,

      selectedExam: {
        title: "",
        questions: [{ question: "", answers: [], correctAnswer: 0 }],
      },
      index: 0,
      activeSlideIndex: 0,
      currentUserId: "",

      userProgress: 0,
    }
  }


  componentDidMount = () => {
    const db = firebase.firestore();
    const diplomadoId = this.props.match.params.id
    let currentUser = this.context.currentUser

    //get diplomado
    db.collection('diplomados').doc(diplomadoId).get().then((diplomado) => {

      this.setState({ ...diplomado.data(), active: { title: "", images: [], pdfs: [], index: 0 }, diplomadoId, currentUserId: currentUser.id, loading: false });

      this.getInitialExamData(diplomadoId)
      this.getVideoData(diplomadoId)

    }).catch(error => {
      console.log(error)
      this.setState({ loading: false })
    });

  }

  getInitialExamData = (diplomadoId) => {
    let exams = []
    let currentUserId = this.context.currentUser.id

    firebase.firestore().collection('diplomados').doc(diplomadoId).collection('exams').get().then((allExams) => {

      allExams.docs.forEach((exam, index) => {

        exams.push({ id: exam.id, title: exam.data().title, indexVideo: (exam.data().indexVideo + ""), questions: [], previouslyCompleted: true })

        firebase.firestore().collection('diplomados').doc(diplomadoId).collection('exams').doc(exam.id).collection('questions').orderBy("originalOrder", "asc").get().then((allQuestions) => {
          let questions = []
          //let hasCompleted = true
          allQuestions.docs.forEach((question, indexQ) => {

            firebase.firestore().collection('diplomados').doc(diplomadoId).collection('exams').doc(exam.id).collection('questions').doc(question.id).collection('userAnswers').doc(currentUserId).get().then((userAnswer) => {
              let answer = userAnswer.data().userAnswer
              questions.push({ id: question.id, ...question.data(), userAnswer: answer })
              exams[index].questions = questions

              if (index === allExams.docs.length - 1 && indexQ === allQuestions.docs.length - 1) {
                this.setState({ exams, loading: false })
              }

            }).catch(error => {

              questions.push({ id: question.id, ...question.data(), userAnswer: -1 })
              exams[index].questions = questions
              exams[index].previouslyCompleted = false

              if (index === allExams.docs.length - 1 && indexQ === allQuestions.docs.length - 1) {
                this.setState({ exams, loading: false })
              }
              console.log(error)
            });

          })



        }).catch(error => console.log(error));
      })

    }).catch(error => console.log(error));
  }

  getVideoData = async (diplomadoId) => {
    let allProcedures = await firebase.firestore().collection('procedures').get()
    let diplomadoVideos = await firebase.firestore().collection('diplomados').doc(diplomadoId).collection('videos').orderBy('index', 'asc').get()

    let videos = []
    diplomadoVideos.docs.forEach(vid => {
      if (vid.data().procedureId) {
        let procedure = allProcedures.docs.find(doc => doc.id === vid.data().procedureId)
        if (procedure)
          videos.push({ id: vid.id, ...vid.data(), ...procedure.data() })
      }
      else
        videos.push({ id: vid.id, ...vid.data() })
    })
    this.setState({ videos, active: videos[0] })
  }

  combineVideosAndExams = () => {
    let videosAndExams = []
    if (this.state.videos.length > 0) {
      videosAndExams = [...this.state.videos]
      this.state.exams.forEach((exam, index) => {
        let ind = videosAndExams.findIndex(vid => vid.index == exam.indexVideo)
        videosAndExams.splice(ind + 1, 0, { ...exam, type: "exam", examIndex: index })
      })
    }
    videosAndExams.forEach(res => {
      console.log(res.index || res.title)
    })
    return videosAndExams
  }


  render() {
    const errors = this.state.errors
    let currentUserId = this.context.currentUser.id
    let prevExam = undefined
    let videosAndExams = this.combineVideosAndExams()

    return (
      <React.Fragment>
        <Spinner loading={this.state.loading} />

        <section className="about-intro intro bg-secondary " style={{ backgroundImage: `url(${this.state.previewImageURL})` }} >
        </section>
        <section className="about-page">

          <div className="row mt-4">
            <div className="col-md-4 col-sm-6"> </div>
            <div className="col-md-4 col-sm-6"> </div>
            <div className="col-md-4 col-sm-6" >
              <button onClick={() => this.setState({ modalOpen: true })} className="btn btn-primary height-button-administrar" type="button">
                Administrar Usuarios
            </button>
            </div>
          </div>

          <div className="container">
            <div className="row text-dark  ">
              <div className="col-12 mt-sm-5 mb-5">
                <h1 className="text-center mb-5">{this.state.title} </h1>
                <h4 >{ReactHtmlParser(this.state.description)} </h4>
              </div>
            </div>



            <div className=" filter-sidebar mb-5" >
              <h1 className="main-title">Videos y Exámenes</h1>
              <ul className="list-unstyled pl-3">
                {videosAndExams.map((video, index) => {

                  let examIndex = -1

                  let videoTitle = video.procedureId ? video.name : video.title
                  let activeTitle = this.state.active.name ? this.state.active.name : this.state.active.title
                  let disabled = false
                  let active = true
                  if (index > 0) {
                    let previousItem = videosAndExams[(index - 1)]
                    if (previousItem.type === "exam" && !previousItem.previouslyCompleted)
                      active = false
                    else if (previousItem.type !== "exam" && !previousItem.completed.includes(currentUserId))
                      active = false
                  }

                  if (video.type !== "exam") {
                    return <li className={"Gratis"}><button className="bg-white text-dark border-0" onClick={() => {
                      this.setState({ active: { ...video, index: index }, activeType: "video" })
                      let topAmount = window.innerWidth > 600 ? 2400 : 3000
                      window.scrollTo({
                        top: topAmount,
                        left: 0,
                        behavior: 'smooth'
                      });
                    }
                    }>{videoTitle}</button></li>
                  }
                  else {
                    return <li className={"Gratis"}><button className="bg-white text-dark border-0" onClick={() => this.setState({ selectedExam: video.examIndex, activeType: "exam" })}>{video.title}</button></li>
                  }
                })}
              </ul>
            </div>
          </div>




          {this.state.activeType === "video" ?
            <VideoComponent video={this.state.active} setVideoCompleted={() => { }} />
            :
            <section className="video-page">
              <div className="container ">
                <div name="video ">
                  <div className="container-fluid">

                    <div className="col-md-12">
                      <div className="main-title my-3">
                        <h1 className="video-title mb-0">
                          <p className="h4 text-center" >{this.state.exams[this.state.selectedExam].title}</p>
                        </h1>
                      </div>
                    </div>

                    {this.state.exams[this.state.selectedExam].questions.map((question, indexQ) => {
                      return <div className="ml-2 mb-3">
                        <h5>{question.question}</h5>
                        {question.answers.map((answer, indexA) => {
                          return <div className="ml-5 mb-1">
                            <input disabled className="form-check-input" type="checkbox" />
                            <p >{answer}</p>
                          </div>
                        })
                        }
                      </div>
                    })}
                    <div className="row justify-content-center" >
                      <button disabled type="button" className="btn btn-primary" >Guardar</button>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          }



        </section>

        {!this.state.loading && window.innerWidth > 500 && <UsuariosModal show={this.state.modalOpen} onHide={() => this.setState({ modalOpen: false })} diplomadoId={this.state.diplomadoId} diplomadoUsers={this.state.users} />
        }
        {!this.state.loading && window.innerWidth <= 500 && <UsuariosModal show={this.state.modalOpen} onHide={() => this.setState({ modalOpen: false })} diplomadoId={this.state.diplomadoId} diplomadoUsers={this.state.users} />
        }
      </React.Fragment>
    );
  }
}
DiplomadoPageAdministrar.contextType = CurrentUser;
export default DiplomadoPageAdministrar