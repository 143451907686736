import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';

import Alert from 'react-bootstrap/Alert';
import firebase from 'firebase';
import MaskedFormControl from 'react-bootstrap-maskedinput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

var moment = require("moment");
moment.locale("es");

class ErrorPurchaseModal extends Component {
    constructor(props) {
        super(props);

        this.state = {

        }
    }

    componentDidMount = () => {
    }


    render() {

        return (
            <Modal {...this.props} size="lg" className="progress-modal" centered >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <h5 className="modal-title" id="modalTerminoLabel">Error</h5>

                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    Lo sentimos, se produjo un error durante el proceso de transacción. Verifica tu información bancaria o contacta con un administrador de Cirugia Veterinaria para que te ayude.
                </Modal.Body>

                <Modal.Footer>

                </Modal.Footer>
            </Modal>
        )
    }
}
export default ErrorPurchaseModal;