import React, { Component } from "react";
import squares from '../../../resources/images/footer-squares.png';
import logo from '../../../resources/images/logo-blanco.png';

class PublicFooterComponent extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <React.Fragment>
                <footer className="bg-primary footer-new home text-light">
                    <div className="container">
                        <div className="row text-center text-sm-left ">
                            <div className="d-flex align-items-center col-md-auto bg-primary py-4 pr-4">
                                <div className="row">
                                    <div className="col-md-auto my-2">
                                        <ul className="list-unstyled">
                                            <li>
                                                <p className="h5">CxVet</p>
                                            </li>
                                            <li className="list-item">
                                                <a className="text-light" href="/about">Conócenos</a>
                                            </li>
                                            <li className="list-item">
                                                <a className="text-light" href="/eventos">Próximos eventos</a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className=" col-md-auto my-2">
                                        <ul className="list-unstyled">
                                            <li>
                                                <p className="h5">Ayuda</p>
                                            </li>
                                            <li className="list-item">
                                                <a className="text-light" href="/FAQ">Preguntas frecuentes</a>
                                            </li>
                                            <li className="list-item">
                                                <a className="text-light" href="/contacto">Contacto</a>
                                            </li>
                                            <li className="list-item">
                                                <a className="text-light" href="/tos">Términos y condiciones</a>
                                            </li>
                                            <li className="list-item">
                                                <a className="text-light" href="/aviso_privacidad">Aviso de privacidad</a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className=" col-md-auto my-2">
                                        <ul className="list-unstyled">
                                            <li>
                                                <p className="h5">Contacto</p>
                                            </li>
                                            <li className="list-item">
                                                <a className="text-light" href="mailto:contacto@cirugiaveterinaria.mx">contacto@cirugiaveterinaria.mx</a>
                                            </li>
                                            <li className="list-item ">
                                                <a className="text-light" href="tel:+524616104010">+(52) 1 4616104010</a>
                                            </li>
                                            <li className="list-item">
                                                México
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="bg-light col d-none d-xl-block text-left p-0">
                                <img src={squares} className="squares" />
                            </div>
                            <div className="bg-light col-auto d-none d-xl-flex align-items-center align-items-center justify-content-end justify-md-content-end mt-3 mb-3">
                                <a href="/index"><img src={logo} /></a>
                            </div>
                        </div>
                    </div>
                </footer>
                <div className="bg-light logo-footer p-4  d-block d-xl-none">
                    <div className="container ">
                        <div className="row text-center text-sm-left ">
                            <div className="col-12  d-flex align-items-center justify-content-center">
                                <a href="/index"><img src={logo} /></a>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
export default PublicFooterComponent;