import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
//import 'bootstrap/dist/css/bootstrap.min.css'
var moment = require("moment");
moment.locale("es");

class EliminarModal extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    deleteAula = () => {
        if (window.confirm("¿Estás seguro de que te gustaría eliminar este Aula? Una vez que lo haga, no podrá deshacerlo."))
            this.props.deleteAula()
    }

    render() {
        return (
            <Modal {...this.props} size="lg" className="progress-modal" centered >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <h5 className="modal-title" id="modalTerminoLabel">Eliminar aula</h5>
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <p className="text-center mb-0">¿Quieres eliminar este aula?</p>
                </Modal.Body>

                <Modal.Footer >
                    <button role="button" className="btn btn-primary" onClick={this.props.onHide}>Cancel</button>
                    <button role="button" className="btn btn-primary" onClick={this.deleteAula}>Eliminar</button>
                </Modal.Footer>
            </Modal>
        );
    }
}
export default EliminarModal;