import React, { Component } from "react";
import { getProcedures, getProceduresOrderByDate, getProcedureTagNames, getTags } from "../../../service/procedures";
import DataTableComponent from '../core/DataTableComponent';
import { getDateFormatted } from "../../../service/functions";

class ProcedureComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tags: [],
            procedures: []
        };
    }

    componentDidMount = () => {
        this.getInitialData()
    }
    getInitialData = async () => {
        let procedures = await getProceduresOrderByDate()
        let tags = await getTags()
        this.setState({ procedures, tags });
    }

    getColumns = () => [
        { label: 'Fecha de creación', field: 'date', sort: 'asc' },
        { label: 'Nombre', field: 'name', sort: 'asc' },
        { label: 'Tipo de usuario', field: 'type', sort: 'asc' },
        { label: 'Especie', field: 'kynd', sort: 'asc' },
        { label: 'Etiquetas', field: 'tags', sort: 'asc' }
    ];
    getProceduresData = () => {
        let rows = this.state.procedures.map(procedure => ({
            date: getDateFormatted(procedure.date),
            name: procedure.name,
            type: procedure.type,
            kynd: procedure.kynd,
            tags: getProcedureTagNames(procedure, this.state.tags),
            clickEvent: (event) => this.props.history.push(`/admin/procedures/procedure/${procedure.id}`)
        }));
        return { columns: this.getColumns(), rows };
    }

    render() {
        return (
            <div className="content-wrapper p-5">
                <div className="row">
                    <div className="col-md-12">
                        <h1>Cirugías</h1>
                        <DataTableComponent data={this.getProceduresData()} />
                        <div className="btn-row"  >
                            <a role="button" href="/admin/tags/" className="btn btn-primary">Administrar etiquetas</a>
                            <a role="button" href="/admin/procedures/procedure" className="btn btn-primary">Crear nueva cirugía</a>
                        </div>
                    </div>
                </div>
            </div>
        );
    };
}
export default ProcedureComponent;