import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import ProgressBar from 'react-bootstrap/ProgressBar'

var moment = require("moment");
moment.locale("es");

class LoadingStatisticsModal extends Component {
    constructor(props) {
        super(props);

        this.state = {

        }
    }

    componentDidMount = () => {
    }


    render() {

        return (
            <Modal {...this.props} size="lg" className="progress-modal" centered >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <h5 className="modal-title" id="modalTerminoLabel">Cargando Estadísticas...</h5>

                    </Modal.Title>
                </Modal.Header>

                <Modal.Body className="justify-content-center">
                    <Spinner animation="border" role="status" />
                    <ProgressBar now={this.props.statisticsProgress} label={`${this.props.statisticsProgress}%`} />
                </Modal.Body>

                <Modal.Footer className="justify-content-center">

                </Modal.Footer>
            </Modal>
        )
    }
}
export default LoadingStatisticsModal;