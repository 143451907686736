import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';


class ErrorModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
        }
    }

    componentDidMount = () => {
       
    }

    render() {
        return (
            <Modal {...this.props} size="lg" className="progress-modal" centered >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <h5 className="modal-title" id="modalTerminoLabel">Error</h5>
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <p  className="mb-0">Ha ocurrido un error. Por favor intente de nuevo.</p>
                </Modal.Body>

                <Modal.Footer >
                    <button role="button" className="btn btn-primary" onClick={this.props.onHide}>Cerrar</button>
                </Modal.Footer>
            </Modal>
        );
    }
}
export default ErrorModal;