import React, { Component } from 'react';
import firebase from 'firebase';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';

import DataTableComponent from '../../admin/core/DataTableComponent';
import CurrentUser from "../core/PublicUserSessionContext";
import CKEditor from '@ckeditor/ckeditor5-react';
import Spinner from "../../commons/Spinner";
import UploadFileAlertComponent from '../../admin/core/UploadFileAlertComponent';
import DatePicker from 'react-date-picker';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import Carousel from 'react-simply-carousel';
import AulaItemComponent from './AulaItemComponent';

import logoBlanco from '../../../resources/images/logo-blanco.png'

class AulaGridComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      allDiplomados: [],
      allOrganizations: [{ aulas: [] }],
      active: { title: "", aulas: [], private: false, promoImageURL: "" },
      hasCxVet: false,
      loading: true,

      allAulas: [],
    }
  }


  componentDidMount = () => {
    const db = firebase.firestore();
    let currentUser = this.context.currentUser;
    let allAulas = []
    let allAulaIDs = []
    let allOrganizations = []
    let aulaCollection = []

    db.collection('aulas').where("active", "==", true).get().then((aulaColl) => {
      aulaCollection = aulaColl
      let allAulas = aulaCollection.docs.map((doc) => { return { id: doc.id, ...doc.data() } });

      db.collection('organizations').get().then((organizationsColl) => {

        organizationsColl.docs.forEach((organization) => {
          let orgAulas = []

          allAulas.forEach((aula) => {
            if (aula.organization === organization.id && aula.dateExpiration > Date.now()) {
              orgAulas.push(aula)
            }
          })

          if (orgAulas.length !== 0)
            allOrganizations.push({ id: organization.id, ...organization.data(), aulas: orgAulas })
        })

        this.setState({ allOrganizations, active: allOrganizations[0] || this.state.active, loading: false })

      }).catch(error => {
        console.log(error)
        this.setState({ loading: false })
      })

    }).catch(error => {
      console.log(error)
      this.setState({ loading: false })
    })

  }


  render() {
    let orgTitle = ""
    orgTitle = this.state.active ? this.state.active.title : ""
    let currentUser = this.context.currentUser;


    return (
      <section className="video-grid">
        <div className="container">

          {this.state.active.private && <a href={"https://" + this.state.active.promoLink} target="_blank"> <section className="about-intro intro bg-secondary aula-banner" style={{  backgroundImage: `url(${this.state.active.promoImageURL})` }} >
          </section></a>}

          <div className="row ">
            <div className="col-md-12">

              <div className="video-title-bar d-md-flex justify-content-between">

                <h1 className="main-title">



                  Mis Aulas
                </h1>

                <div>
                  <div className="mb-3 p-0 row no-gutters align-items-center">
                    <div className="col">
                      {//<input onChange={this.searchByName} className="form-control form-control-borderless" type="search" placeholder="Buscar en catálogo" />
                      }
                    </div>
                    <div className="col-auto pl-3 pr-3">
                      <a>
                        {//<FontAwesomeIcon icon={faSearch} />
                        }
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-3 col-md-4 col-sm-5 col-12" >

                  <img className="img-fluid  mb-2 preview" src={this.state.active.logoImageURL} />
                  <h1 className="main-title">
                    {orgTitle}
                  </h1>
                  <div className=" filter-sidebar">

                    <span className="h4">Organizaciones</span>
                    <p  >Todas</p>

                    <ul className="list-unstyled pl-3">
                      {this.state.allOrganizations.map(organization => {
                        return <li className={organization.id === this.state.active.id ? "Gratis active" : "Gratis"}><button onClick={() => this.setState({ active: organization })}>{organization.title}</button></li>
                      })}
                    </ul>

                  </div>
                </div>
                <div className="col-lg-9 col-md-8 col-sm-7 col-12" >
                  <div className="row">
                    {
                      this.state.active.aulas.map(aula => {
                        return <AulaItemComponent key={aula.id} previewImageURL={aula.previewImageURL} title={aula.title} description={aula.description} link={aula.users.includes(currentUser.id) ? `/mis-aulas/aula/${aula.id}` : aula.previewPdfURL} />
                      })
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Spinner show={this.state.loading} />
      </section>
    );
  }
}
AulaGridComponent.contextType = CurrentUser;
export default AulaGridComponent