import React,{Component} from "react";
import {Link} from "react-router-dom";
import firebase from "firebase";
import Modal from "react-bootstrap/Modal";

import CurrentUser from "../public/core/PublicUserSessionContext";
import Alert from "react-bootstrap/Alert";
class RecuperarPasswordComponent extends Component{
    constructor(props){
        super(props);
        this.state = {email:"",error:""}
    }
    resetPassword = () => {
        firebase.auth().sendPasswordResetEmail(this.state.email)
            .then(() => {
                this.setState({success:true,error:""});
            }).catch( error => {
                console.log(error);
                if(error.code){
                    if(error.code === "auth/invalid-email"){
                        this.setState({error:"Dirección de correo inválida."});
                    }
                    else if(error.code==="auth/user-not-found"){
                        this.setState({error:"Usuario no encontrado"});                        
                    }
                    else{
                        this.context.mostrarError("Ocurrió un error, intente más tarde","ERROR RECUPERAR CONTRASEÑA",);
                    }
                }
                else{
                    this.context.mostrarError("Ocurrió un error, intente más tarde","ERROR RECUPERAR CONTRASEÑA",);
                }
            });
    }
    irIniciarSesion = ()=>{
        console.log("IR INICIAR SESION");
        console.log(this.props);
        this.props.history.push("/login");
    }
    render(){
        return <section className="register-page">
			
        <div className="container">
        <Modal show = {this.state.success} onHide={this.irIniciarSesion}>
            <Modal.Header closeButton>
                <Modal.Title>Solicitud enviada</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <p>Recibirás un correo electrónico para que puedas asignar una nueva contraseña</p>
            </Modal.Body>

            <Modal.Footer>
                <button onClick = {this.irIniciarSesion} variant="primary">Aceptar</button>
            </Modal.Footer>
        </Modal>
            <div className="row">
                
                <div className="container">
                    <h1 className="mb-5">
                        Recuperar contraseña
                    </h1>
                    
                    <div className="row justify-content-center ">
                        <div className="col-md-4 ">
                            
                                                    
                            <div className="form-group">
                                <label htmlFor="contact_email">Correo Electrónico</label>
                                <input type="email" value = {this.state.email} onChange = {(ev)=>this.setState({email:ev.target.value})}
                                    id="contact_email" className="form-control" required=""/>
                                
                            </div>
                            <Alert variant = "danger" show={this.state.error!==""}>{this.state.error}</Alert>
                            <div className="form-group">
                                <button onClick = {this.resetPassword} className="btn btn-primary btn-block">
                                    Recuperar 
                                </button>
                                <Link to="/login" className=" text-center btn-block">
                                    Iniciar sesión
                                </Link>
                            </div>
                        </div>
                        
                    </div>
                </div>
                
                
            </div>
        </div>
        
    </section>
    }
}
RecuperarPasswordComponent.contextType = CurrentUser;
export default RecuperarPasswordComponent;