import React, { Component } from 'react';
import firebase from 'firebase';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import CurrentUser from "../../public/core/PublicUserSessionContext";
class BulkMailComponent extends Component {
    constructor(props) {
        super(props);
        this.fileInput = React.createRef();
        this.state = {
            mail: { subject: "", title: "", message: "" },
            isSending: false,
            isSended: false,
            errors: {},
        };
    }

    handleInputChange = (event) => {
        let { value, name } = event.target
        const mail = { ...this.state.mail, [name]: value };
        this.setState({ mail, errors: {}, isSended: false });
    }

    handleFormSubmit = (event) => {
        event.preventDefault();
        if (this.validateErrors())
            this.addBulkMail();
    }
    saveWithImage = () => {
        this.context.iniciaCarga();
        const storage = firebase.storage().ref('bulk_email_imgs');
        const file = this.state.imageFile;
        const time = Date.now().toString();
        const name = time.concat(file.name);
        storage.child(name).put(file).then(snapshot => {
            storage.child(name).getDownloadURL().then(url => {
                const mail = this.state.mail;
                mail["imageUrl"] = url;
                const db = firebase.firestore();

                db.collection("mails").add({
                    subject: mail.subject,
                    message: mail.message,
                    title: mail.title,
                    imageUrl: url,
                    date: Date.now(),
                })
                    .then((docRef) => {
                        const bulkEmailFunction = firebase.functions().httpsCallable('bulkEmail');
                        bulkEmailFunction(mail).then((result) => {
                            document.getElementById("previewImg").setAttribute("src", "");
                            document.getElementById("inputImg").value = "";
                            this.setState({ isSending: false, isSended: true, error: false, mail: { subject: "", message: "", title: "" }, imageFile: false });
                        }).catch((error) => {
                            console.log(error);
                            this.setState({ error });
                        });
                    })
                    .catch((error) => {
                        console.log(error);
                        this.setState({ isSending: false, error });
                    });
            })
        }).catch(err => {
            console.log(err => {
                console.log(err);
            })
        }).finally(r => this.context.terminaCarga());
    }
    validateErrors() {
        let errors = {};
        let required = ["subject", "title", "message"]

        required.forEach(field => {
            if (!this.state.mail[field])
                errors[field] = "Debes llenar este campo."
        })

        if (!this.state.mail.message && !this.state.imageFile) {
            errors.contenido = "Debes agregar un mensaje o seleccionar una imagen";
        }
        this.setState({ errors });
        return Object.keys(errors).length === 0
    }
    addBulkMail = () => {
        const mail = this.state.mail;
        const db = firebase.firestore();
        if (this.state.imageFile)
            this.saveWithImage();
        else {
            this.context.iniciaCarga();
            db.collection("mails").add({
                subject: mail.subject,
                message: mail.message,
                title: mail.title,
                date: Date.now(),
            }).then((docRef) => {
                    this.setState({ isSended: false, isSending: true, error: false });
                    const bulkEmailFunction = firebase.functions().httpsCallable('bulkEmail');
                    bulkEmailFunction(mail).then((result) => {

                        this.setState({ isSending: false, isSended: true, error: false, mail: { subject: "", message: "", title: "" }, imageFile: false });
                    }).catch((error) => {
                        console.log(error);
                        this.setState({ isSending: false, error });
                    });
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({ isSending: false, error });
                }).finally(r => this.context.terminaCarga());
        }

    }
    handleFileInput = (e) => {
        let imageFile = e.target.files[0];
        let img = new FileReader();
        let that = this;
        img.onload = function (e) {
            let preview = document.getElementById("previewImg");
            preview.setAttribute("src", e.target.result);
            that.setState({ imageFile });
        }
        img.readAsDataURL(imageFile);
    }
    render() {
        const errors = this.state.errors;
        return (
            <React.Fragment>
                <div className="content-wrapper p-5">
                    <div className="row">
                        <div className="col-md-12">
                            <h1 className="mb-5">Enviar correo electrónico masivo</h1>
                            <Form onSubmit={this.handleFormSubmit}>
                                <div className="row justify-content-center mb-4">

                                    <div className="col-md-8">
                                        {this.state.isSending && <Alert variant="primary"> Enviando mensaje, espere por favor...</Alert>}
                                        {this.state.isSended && <Alert variant="success"> Mensaje enviado correctamente.</Alert>}
                                        {this.state.error && <Alert variant="danger"> Ocurrio un error.</Alert>}
                                        <div className="form-container">

                                            <div className="form-group">
                                                <label>Asunto de correo</label>
                                                <input type="text" id="subject" name="subject" value={this.state.mail.subject} onChange={this.handleInputChange} className="form-control" />
                                                {errors.subject && <Alert variant="danger"> {errors.subject}</Alert>}
                                            </div>
                                            <div className="form-group">
                                                <label>Título</label>
                                                <input type="text" id="subject" name="title" value={this.state.mail.title} onChange={this.handleInputChange} className="form-control" />
                                                {errors.title && <Alert variant="danger"> {errors.title}</Alert>}
                                            </div>
                                            <div className="form-group">
                                                {this.state.imageFile && <label className="d-block">Imagen principal </label>}

                                                <img className="img-fluid  mb-2 preview" src="" id="previewImg" />
                                                <input name="filePreview" type="file" className="form-control-file" id="inputImg" accept="image/jpeg,image/jpg,image/png" ref={this.fileInput}
                                                    onChange={this.handleFileInput} />

                                            </div>
                                            <div className="form-group">
                                                <label>Mensaje</label>
                                                <textarea id="message" name="message" onChange={this.handleInputChange} value={this.state.mail.message} className="form-control" rows="8"  ></textarea>
                                                {errors.message && <Alert variant="danger"> {errors.message}</Alert>}
                                            </div>
                                            {errors.contenido && <Alert variant="danger"> {errors.contenido}</Alert>}
                                            <p>Este correo se enviará a todos los usuarios registrados que tengan habilitado la opción de recibir correos promocionales</p>
                                        </div>
                                        <button id="contact-submit" type="submit" className="btn btn-primary btn-block">
                                            Enviar
                                        </button>

                                    </div>

                                </div>
                            </Form>

                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
BulkMailComponent.contextType = CurrentUser;
export default BulkMailComponent;