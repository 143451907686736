import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import Alert from 'react-bootstrap/Alert';
import DataTableComponent from '../../core/DataTableComponent';
import firebase from 'firebase';
import MaskedFormControl from 'react-bootstrap-maskedinput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

var moment = require("moment");
moment.locale("es");

class ExamModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            title: "",
            questions: [{ question: "", answers: [], correctAnswer: 0, userAnswers: {} }],
        }
    }

    componentDidMount = () => {

    }

    render() {

        return (
            <Modal {...this.props} size="lg" className="progress-modal" centered >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <h5 className="modal-title" id="modalTerminoLabel">Administrar Exámen</h5>
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>

                    <div className="form-group">
                        <h1>Exámen</h1>
                        <input className="w-50 mb-5" placeholder="Título" onChange={(e) => {
                            this.setState({ title: e.target.value })
                        }} value={this.state.title} />

                        <div className="mb-3">
                            {this.state.questions.map((question, index) => {
                                return <div >

                                    <div className="mb-3">
                                        <input placeholder={`Pregunta ` + (index+1)} className="height-pregunta width-exam-modal" onChange={(e) => {
                                            let qCopy = this.state.questions
                                            qCopy[index].question = e.target.value

                                            this.setState({ questions: qCopy })
                                        }} value={question.question}>

                                        </input>
                                        <button type="button" className="btn btn-primary ml-3" onClick={() => {
                                            let q = this.state.questions
                                            q[index].answers.push("")
                                            this.setState({ questions: q })
                                        }} >+ Agregar Respuesta</button>
                                        <a className="text-light btn btn-primary ml-3 " onClick={(e) => {
                                            let qCopy = this.state.questions
                                            qCopy.splice(index, 1)
                                            this.setState({ questions: qCopy })
                                        }}>
                                            <FontAwesomeIcon icon={faTrash} />

                                        </a>

                                    </div>


                                    <div >
                                        {question.answers.map((answer, indexA) => {
                                            return <div className="mb-2 ml-4 ">
                                                <input checked={question.correctAnswer === indexA} onChange={() => {
                                                    let qCopy = this.state.questions
                                                    qCopy[index].correctAnswer = indexA
                                                    this.setState({ questions: qCopy })
                                                }} className="form-check-input" type="checkbox" />

                                                <input placeholder={`Respuesta ` + (indexA+1)} className="width-exam-respuesta" onChange={(e) => {
                                                    let qCopy = this.state.questions
                                                    qCopy[index].answers[indexA] = e.target.value
                                                    this.setState({ questions: qCopy })
                                                }} value={answer}/>

                                                <a className="text-light btn btn-primary ml-3" onClick={(e) => {
                                                    let qCopy = this.state.questions
                                                    let aCopy = qCopy[index].answers
                                                    aCopy.splice(indexA, 1)
                                                    if (qCopy[index].correctAnswer > aCopy.length - 1 && qCopy[index].correctAnswer !== 0)
                                                        qCopy[index].correctAnswer = qCopy[index].correctAnswer - 1
                                                    qCopy[index].answers = aCopy
                                                    this.setState({ questions: qCopy })
                                                }}>
                                                    <FontAwesomeIcon icon={faTrash} />
                                                </a>
                                            </div>
                                        })}


                                    </div>

                                    <br /> <br />
                                </div>
                            })}
                        </div>

                        <button type="button" className="btn btn-primary" onClick={() => {
                            let q = this.state.questions
                            q.push({ question: "", answers: [], correctAnswer: 0, userAnswers: {} })
                            this.setState({ questions: q })
                        }} >Agregar Pregunta</button>

                    </div>

                </Modal.Body>

                <Modal.Footer >
                    <button type="button" className="btn btn-primary" onClick={() => {
                        this.setState({
                            title: "",
                            questions: [{ question: "", answers: [], correctAnswer: 0, userAnswers: {} }],
                        })
                        this.props.cancel()
                    }} >Cancelar</button>
                    <button type="button" className="btn btn-primary" onClick={() => {
                        this.setState({
                            title: "",
                            questions: [{ question: "", answers: [], correctAnswer: 0, userAnswers: {} }],
                        })
                        this.props.saveExam(this.state.questions, this.state.title)
                    }}>Guardar</button>
                </Modal.Footer>
            </Modal>
        );
    }
}
export default ExamModal;