import React, { Component } from 'react';
import firebase from 'firebase';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import ProgressBar from 'react-bootstrap/ProgressBar'
import DataTableComponent from '../../admin/core/DataTableComponent';
import CurrentUser from "../core/PublicUserSessionContext";
import CKEditor from '@ckeditor/ckeditor5-react';
import Spinner from "../../commons/Spinner";
import UploadFileAlertComponent from '../../admin/core/UploadFileAlertComponent';
import DatePicker from 'react-date-picker';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import Carousel from 'react-simply-carousel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlayCircle } from '@fortawesome/free-solid-svg-icons'
import CertificateModal from './CertificateModal'
import DiplomadoItemComponent from './DiplomadoItemComponent';
import ImagenPdf from '../../../resources/images/pdf-image.jpg';
import SuccessModal from './SuccessModal'

class DiplomadoSuccessComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      currentUserId: "",
      diplomadoId: this.props.match.params.id,
      title: "",
      done: false
    }
  }


  componentDidMount = () => {
    const db = firebase.firestore();
    const diplomadoId = this.props.match.params.id
    let currentUser = this.context.currentUser

    //get diplomado
    db.collection('diplomados').doc(diplomadoId).get().then((diplomado) => {

      let users = diplomado.data().users
      users.push(currentUser.id)

      db.collection('diplomados').doc(diplomadoId).update({
        users: users
      }).then((diplomado) => {
        this.props.history.push(`/mis-diplomados/diplomado/${diplomadoId}`)
      })
        .catch(error => {
          console.log(error)
          this.setState({ loading: false })
        })

    }).catch(error => {
      console.log(error)
      this.setState({ loading: false })
    });

  }

  render() {
    const errors = this.state.errors
    let currentUserId = this.context.currentUser.id

    return (
      <React.Fragment>
        <Spinner loading={this.state.loading} />

      </React.Fragment >
    );
  }
}
DiplomadoSuccessComponent.contextType = CurrentUser;
export default DiplomadoSuccessComponent