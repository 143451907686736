import firebase from 'firebase';
import { uploadFileToStorage } from './storage';

export async function getNewsletterImage() {
    let newsletter = await firebase.firestore().collection("newsletter").doc("newsletter").get().catch(error => console.log(`Error obtener imagen newsletter: ${JSON.stringify(error)}`));
    return newsletter.exists ? newsletter.data() : {}
}
export async function saveNewsletterData(data) {
    return await firebase.firestore().collection("newsletter").doc("newsletter").set(data, { merge: true }).catch(error => console.log(`Error al guardar newsletter: ${JSON.stringify(error)}`));
}

export async function uploadNewsletterImage(imageFile) {
    return await uploadFileToStorage("newsletter", "newsletter", getFileUploadName(imageFile), imageFile, "imagen newsletter", () => { })
}

function getFileUploadName(file) {
    const time = Date.now().toString();
    const name = time.concat(file.name);
    return name
}