import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import AdminRouterComponent from '../admin/AdminRouterComponent';
import PublicRouterComponent from '../public/PublicRouterComponent';
import NotFoundComponent from './NotFoundComponent';
import firebase from 'firebase';
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Spinner from "./Spinner";
import CurrentUser, { defaultUser, defaultError } from '../public/core/PublicUserSessionContext';
import UsoCookies from "./UsoCookies.js";

class RouterComponent extends Component {
    constructor(props) {
        super(props);
        this.state = { error: defaultError, cargando: false };
    }

    userAuthListener = new Promise((resolve, reject) => {
        console.log("!!!CHECKING 1!!!")
        return new Promise((resolve, reject) => {
            firebase.auth().onAuthStateChanged((currentUser) => {
                console.log("!!!CHECKING 2!!!")
                //console.log("se esta ejecutando la funcion userAuth",currentUser);
                if (currentUser) {
                    console.log("!!!CHECKING 3!!!")
                    this.iniciaCarga();
                    let checkMembershipFunction = firebase.functions().httpsCallable('checkAccountMembership');
                    checkMembershipFunction({ uid: currentUser.uid }).then(resp => {

                        if (resp.data) {

                            this.mostrarError("AVISO CAMBIO DE CUENTA", "Se ha efectuado el cambio programado de cuenta");
                        }
                        console.log("CHECKED MEMBERSHIP");
                        //console.log(resp);
                        firebase.firestore().collection("users").doc(currentUser.uid).get()
                            .then((doc) => {
                                console.log("!!!CHECKING 4!!! : ", doc.exists)
                                if (doc.exists) {
                                    ///////////////Verifying that the user belongs to a diplomado///////////////
                                    const db = firebase.firestore();
                                    let adminDiplomado = false
                                    let adminAula = false
                                    let universityTitle = ""
                                    let aulaData = []

                                    if ((doc.data().university && doc.data().university !== "")) {

                                        db.collection('organizations').doc(doc.data().university).get().then(university => {
                                            universityTitle = university.data().title
                                            this.setState({ currentUser: { id: doc.id, adminDiplomado, adminAula, universityTitle, aulaData, ...doc.data() } });
                                            resolve("usuario autentificado");
                                        }).catch(error => {
                                            this.setState({ currentUser: { id: doc.id, adminDiplomado, adminAula, universityTitle, aulaData, ...doc.data() } });
                                            resolve("usuario autentificado");
                                        })
                                    }

                                    db.collection('aulas').get().then(aulas => {

                                        aulas.docs.forEach(aula => {
                                            if (aula.data().adminDiplomado === currentUser.uid && aula.data().active)
                                                adminAula = true
                                            aulaData.push({users:aula.data().users, videos:aula.data().videos})
                                        })
                                        this.setState({ currentUser: { id: doc.id, adminDiplomado, adminAula, universityTitle, aulaData, ...doc.data() } });
                                        resolve("usuario autentificado");
                                    }).catch(error => {
                                        this.setState({ currentUser: { id: doc.id, adminDiplomado, adminAula, universityTitle, aulaData, ...doc.data() } });
                                        resolve("usuario autentificado");
                                    })

                                    db.collection('diplomados').get().then(diplomados => {

                                        diplomados.docs.forEach(diplomado => {
                                            if (diplomado.data().adminDiplomado === currentUser.uid && diplomado.data().active)
                                                adminDiplomado = true
                                        })
                                        this.setState({ currentUser: { id: doc.id, adminDiplomado, adminAula, universityTitle, aulaData, ...doc.data() } });
                                        resolve("usuario autentificado");
                                    }).catch(error => {
                                        this.setState({ currentUser: { id: doc.id, adminDiplomado, adminAula, universityTitle, aulaData, ...doc.data() } });
                                        resolve("usuario autentificado");
                                    })

                                    ////////////////////////////////////////////////////////////

                                } else {
                                    this.setState({ currentUser: defaultUser });
                                    reject({ message: "No se pudo recuperar el usuario." });
                                }
                            }).catch(error => {
                                this.setState({ currentUser: defaultUser });
                                reject({ message: "No se pudo recuperar el usuario.", error: error });
                            })
                    }).catch(err => {
                        console.log("COULDNT CHECK MEMBERSHIP");
                        console.log(err);
                    }).finally(resp => {
                        this.terminaCarga();
                    })

                } else {
                    this.setState({ currentUser: defaultUser });
                    reject({ message: "Usuario invitado." });
                }
                //console.log("RouterComp.userAuthListener:",this.state.currentUser);
            })
        });
    });


    adminCheck = () => {
        if (this.state.currentUser.type == "Admin") {
            return <Route path="/admin" component={AdminRouterComponent} />
        }
    }

    mostrarError = (mensaje, titulo) => {
        this.setState({ error: { mensaje: mensaje, titulo: titulo, mostrar: true } })
    }
    cerrarError = () => {
        this.setState({ error: { mensaje: "", titulo: "", mostrar: false } })
    }
    iniciaCarga = () => {
        this.setState({ cargando: true });
    }
    terminaCarga = () => {
        this.setState({ cargando: false })
    }
    render() {
        //console.log("RouterComp.render:",this.state.currentUser);
        if (!this.state.currentUser) return null;
        return (
            <CurrentUser.Provider value={
                {
                    currentUser: this.state.currentUser,
                    error: this.state.error,
                    mostrarError: this.mostrarError,
                    cerrarError: this.cerrarError,
                    cargando: this.state.cargando,
                    iniciaCarga: this.iniciaCarga,
                    terminaCarga: this.terminaCarga
                }
            }>
                <UsoCookies />
                <Spinner show={this.state.cargando} />
                <Modal show={this.state.error.mostrar} onHide={this.cerrarError}>
                    <Modal.Header closeButton>
                        <Modal.Title>{this.state.error.titulo}</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <p>{this.state.error.mensaje}</p>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="primary" onClick={this.cerrarError}>ACEPTAR</Button>
                    </Modal.Footer>
                </Modal>

                <Router >

                    <Switch>
                        {this.adminCheck()}
                        <Route path="/" component={PublicRouterComponent} />
                        <Route component={NotFoundComponent} />
                    </Switch>
                </Router>
            </CurrentUser.Provider>
        );
    }
}

export default RouterComponent;