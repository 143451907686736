import React, { Component } from 'react';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import DataTableComponent from '../core/DataTableComponent';
import CurrentUser from "../../public/core/PublicUserSessionContext";
import DatePicker from 'react-date-picker';
import Spinner from "../../commons/Spinner";
import UploadFileAlertComponent from '../core/UploadFileAlertComponent';
import { getOrganization, getOrganizations } from '../../../service/organizations';
import { deleteAula, getAulaVirtual, saveAulaData, uploadPreviewImage, uploadPreviewPdf } from '../../../service/aulas';
import { getAllUsers } from '../../../service/users';
import { getProcedures, getTags, getProcedureTagNames } from '../../../service/procedures';
import { ORGANIZATION_TYPES } from '../../../service/constants';

class AulaFormComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            aulaID: this.props.match.params.id,
            editing: this.props.match.params.id ? true : false,
            loading: true,
            fileModalShow: false,
            fileStatus: 0,
            errors: {},

            aula: {
                title: "",
                organization: "",
                active: true,
                adminAula: "",
                previewPdfURL: "",
                previewImageURL: "",
            },
            typeOrganization: "",
            organizations: [],
            allTags: [],
            allUsers: [],
            aulaUsers: [],
            allVideos: [],
            aulaVideos: [],
        }
    }

    componentDidMount() {
        this.getInitialData()
    }

    getInitialData = async () => {
        const { aulaID, editing } = this.state

        if (editing) {
            let aula = await getAulaVirtual(aulaID)
            let organization = await getOrganization(aula.organization)
            this.setState({ aula, aulaUsers: aula.users, aulaVideos: aula.videos, typeOrganization: organization.type });
        }

        let organizations = await getOrganizations()
        let allUsers = await getAllUsers()
        let allVideos = await getProcedures()
        let allTags = await getTags()
        this.setState({ organizations, allUsers, allVideos, allTags, loading: false })
    }

    handleInputChange = (event) => {
        let { name, value } = event.target
        let changingType = name === "typeOrganization"
        let changingOrg = (name === "typeOrganization" || name === "organization")

        let organization = changingType ? "" : this.state.organization
        let aulaUsers = changingOrg ? [] : this.state.aulaUsers
        let aula = { ...this.state.aula, organization, [name]: value }
        this.setState({ aula, aulaUsers, errors: {} })
    }
    handleFileChange = (event) => {
        const [file, fileType, urlType] = [event.target.files[0], `${event.target.name}File`, `${event.target.name}URL`]
        let url = URL.createObjectURL(file)
        let aula = { ...this.state.aula, [urlType]: url }
        this.setState({ aula, [fileType]: file, errors: {} })
    }
    handleInputDate = (date) => {
        let date_expiration = date.getTime()
        let aula = { ...this.state.aula, date_expiration }
        this.setState({ aula, errors: {} })
    }
    organizationsFiltered = () => this.state.organizations.filter(organization => organization.type === this.state.typeOrganization)

    handleInputCheck = (event) => {
        const [itemID, aulaArray] = [event.target.id, event.target.name]
        let arrayCopy = this.state[aulaArray]
        const index = arrayCopy.indexOf(itemID);

        if (index > -1)
            arrayCopy.splice(index, 1);
        else
            arrayCopy.push(itemID)
        this.setState({ [aulaArray]: arrayCopy, errors: {} })
    }
    handleSelectAllCheck = (fullArray, aulaArray) => {
        let allChecked = this.state[fullArray].length === this.state[aulaArray].length
        let newArray = allChecked ? [] : this.state[fullArray].map(doc => doc.id)
        this.setState({ [aulaArray]: newArray })
    }
    arrayIsChecked = (arr1, arr2) => this.state[arr1].length === this.state[arr2].length

    getVideoColumns = () => [
        { label: 'Agregar', field: 'select', sort: 'asc' },
        { label: 'Nombre', field: 'name', sort: 'asc' },
        { label: 'Etiquetas', field: 'tags', sort: 'asc' },
        { label: 'Especie', field: 'kynd', sort: 'asc' },
        { label: 'Tipo', field: 'type', sort: 'asc' },
    ];
    getVideoData = () => {
        const rows = [];
        rows.push({ select: <div className="form-check form-check-inline"><input checked={this.arrayIsChecked("aulaVideos", "allVideos")} onChange={() => this.handleSelectAllCheck("allVideos", "aulaVideos")} className="form-check-input" type="checkbox" /></div>, })

        this.state.allVideos.map(video => {
            rows.push({
                select: <div className="form-check form-check-inline">
                    <input name="aulaVideos" id={video.id} checked={this.state.aulaVideos.includes(video.id)} className="form-check-input" onChange={this.handleInputCheck} type="checkbox" />
                </div>,
                name: video.name,
                tags: getProcedureTagNames(video, this.state.allTags),
                kynd: video.kynd,
                type: video.type,
            });
        });
        return { columns: this.getVideoColumns(), rows };
    }

    getUserColumns = () => [
        { label: 'Agregar', field: 'select', sort: 'asc' },
        { label: 'Nombre', field: 'name', sort: 'asc' },
        { label: 'Correo', field: 'email', sort: 'asc' },
        { label: 'Membresía', field: 'type', sort: 'asc' },
    ];
    getUserData = () => {
        const rows = [];
        rows.push({ select: <div className="form-check form-check-inline"><input checked={this.arrayIsChecked("aulaUsers", "allUsers")} onChange={() => this.handleSelectAllCheck("allUsers", "aulaUsers")} className="form-check-input" type="checkbox" /></div>, })

        this.state.allUsers.map(user => {
            if (user.university === this.state.organization || this.state.typeOrganization !== "universidad") {
                rows.push({
                    select: <div className="form-check form-check-inline">
                        <input name="aulaUsers" id={user.id} checked={this.state.aulaUsers.includes(user.id)} className="form-check-input" onChange={this.handleInputCheck} type="checkbox" />
                    </div>,
                    name: user.name,
                    email: user.email,
                    type: user.type,
                });
            }
        });
        return { columns: this.getUserColumns(), rows };
    }

    submitAula = async (event) => {
        event.preventDefault()
        if (this.validateErrors()) {
            const { aulaID, previewPdfFile, previewImageFile, aulaUsers, aulaVideos, aula, editing } = this.state
            this.setState({ loading: true })

            let previewPdfURL = previewPdfFile
                ? await uploadPreviewPdf(aulaID, previewPdfFile, (data) => this.setState(data))
                : aula.previewPdfURL
            let previewImageURL = previewImageFile
                ? await uploadPreviewImage(aulaID, previewImageFile, (data) => this.setState(data))
                : aula.previewImageURL

            let data = { ...aula, previewImageURL, previewPdfURL, users: aulaUsers, videos: aulaVideos, }
            if (!editing)
                data.date_created = Date.now()

            await saveAulaData(aulaID, data)
            this.setState({ loading: false })
            this.props.history.push("/admin/aulas")
        }
    }
    validateErrors = () => {
        let errors = {}
        const required = ["title", "typeOrganization", "previewImageURL", "previewPdfURL", "organization", "date_expiration"]

        required.forEach(field => {
            if (!this.state.aula[field] && !this.state[field])
                errors[field] = true
        })
        if (this.state.aulaVideos.length === 0)
            errors.noVideos = true

        this.setState({ errors })
        return Object.keys(errors).length === 0
    }

    deleteAula = async () => {
        if (window.confirm("¿Estás seguro de que te gustaría eliminar este aula? Una vez que lo haga, no podrá deshacerlo.")) {
            await deleteAula(this.state.aulaID)
            window.alert("El aula fue eliminado con éxito.")
            this.props.history.push("/admin/aulas")
        }
    }

    render() {
        const state = this.state
        const {errors, aula} = state
        return (
            <Form onSubmit={this.submitAula}>
                <div className="content-wrapper p-5">
                    <div className="row">

                        {!state.active && <div className="col-md-12">
                            <button type="button" className="btn btn-primary float-right" onClick={this.deleteAula}>Eliminar</button>
                        </div>}

                        <div className="col-md-12">
                            <h1 className="mb-0">Aula virtual</h1>
                            <h3 className="mb-5">{state.editing ? "Editar Aula" : "Nueva Aula"}</h3>
                            <div className="row justify-content-center mb-4">
                                <div className="col-md-10">
                                    <h4>Información general del aula virtual</h4>
                                    <div className="form-container">
                                        <div className="row">
                                            <div className="col-md-12">

                                                <div className="form-group">
                                                    <label>Título</label>
                                                    <input name="title" onChange={this.handleInputChange} value={aula.title} type="text" className="form-control" />
                                                    {errors.title && <Alert variant="danger"> Este campo es requerido.</Alert>}
                                                </div>

                                                <div className="form-group">
                                                    <label>Universidad/Empresa</label>
                                                    <select name="typeOrganization" onChange={this.handleInputChange} value={state.typeOrganization} className="form-control">
                                                        <option key="0" value="">Sin asignar</option>
                                                        {ORGANIZATION_TYPES.map(type => <option key={type} value={type}> {type} </option>)}
                                                    </select>
                                                    {errors.typeOrganization && <Alert variant="danger"> Este campo es requerido.</Alert>}
                                                </div>

                                                <div className="form-group">
                                                    <label>{state.typeOrganization || "Organización"}</label>
                                                    <select disabled={!state.typeOrganization} name="organization" value={aula.organization} onChange={this.handleInputChange} className="form-control">
                                                        <option key="0" value="">Sin asignar</option>
                                                        {this.organizationsFiltered().map(org => <option key={org.id} value={org.id}> {org.title} </option>)}
                                                    </select>
                                                    {errors.organization && <Alert variant="danger"> Este campo es requerido.</Alert>}
                                                </div>

                                                <div className="form-group">
                                                    <label>Input fecha de vigencia</label><br />
                                                    <DatePicker value={aula.date_expiration && new Date(aula.date_expiration)} className="form-control"
                                                        onChange={this.handleInputDate} />
                                                    {errors.date_expiration && <Alert variant="danger"> Este campo es requerido.</Alert>}
                                                </div>

                                                <div className="col-md-4 col-sm-6">
                                                    <div className="form-group">
                                                        <label >Imagen previa </label>
                                                        <img className="img-fluid  mb-2 preview" src={aula.previewImageURL} />
                                                        <input name="previewImage" onChange={this.handleFileChange} type="file" className="form-control-file" accept="image/jpeg,image/jpg,image/png" />
                                                        <small class="form-text text-muted">La imagen debe tener dimensiones mínimas de 1090px x 720px</small>
                                                        {errors.previewImageURL && <Alert variant="danger"> Se requiere la imagen de vista previa.</Alert>}
                                                    </div>
                                                </div>

                                                <div className="form-group">
                                                    <h1>Cirugías</h1>
                                                    <label>{state.aulaVideos.length}{state.aulaVideos.length === 1 ? " video seleccionado" : " videos seleccionados"}</label><br />
                                                    <DataTableComponent data={this.getVideoData()} />
                                                    {errors.noVideos && <Alert variant="danger"> Debes seleccionar al menos un video.</Alert>}
                                                </div>

                                                {state.typeOrganization === "Universidad" &&
                                                    <React.Fragment>
                                                        <div className="form-group">
                                                            <h1>Usuarios</h1>
                                                            <label> {`${state.aulaUsers.length}`}{state.aulaUsers.length === 1 ? " usuario seleccionado" : " usuarios seleccionados"}</label><br />
                                                            <DataTableComponent data={this.getUserData()} />
                                                        </div>

                                                        <div className="form-group">
                                                            <label>Administrador del Aula</label>
                                                            <select name="adminAula" onChange={this.handleInputChange} value={aula.adminAula} className="form-control">
                                                                <option value="">Sin asignar</option>
                                                                {state.allUsers.map(user => <option key={user.id} value={user.id}> {user.email} </option>)}
                                                            </select>
                                                        </div>
                                                    </React.Fragment>}

                                                <div className="col-md-4 col-sm-6">
                                                    <div className="form-group">
                                                        <label>PDF previa </label>
                                                        <iframe width="100%" controls src={aula.previewPdfURL} />
                                                        <input name="previewPdf" onChange={this.handleFileChange} type="file" className="form-control-file" accept="application/pdf" />
                                                        <small class="form-text text-muted">El pdf será descargado por el usuario si no ha sido agregado al aula.</small>
                                                        {errors.previewPdfURL && <Alert variant="danger"> Se requiere la pdf de información previa.</Alert>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="btn-row" >
                                <button type="submit" className="btn btn-primary">Guardar</button>
                            </div>

                            <Spinner show={state.loading} />
                            <UploadFileAlertComponent show={state.fileModalShow} file={state.fileStatus} />

                        </div>
                    </div>
                </div>
            </Form>
        );
    }
}
AulaFormComponent.contextType = CurrentUser;
export default AulaFormComponent;