import React,{Component} from 'react';
import firebase from 'firebase';
import UserListComponent from './UserListComponent';

class UsersManualComponent extends Component{
    constructor(props){
        super(props);
        this.state = {users:[]};
    }

    componentDidMount = () =>{
        const db = firebase.firestore();
        
        db.collection('users').where("subscriptionId","==","MANUAL").get().then((snapshot) => {
            const users = [];
            snapshot.docs.map((doc) => users.push({id:doc.id,...doc.data()}));
            this.setState({users});
        });
    }
    render(){
        return(
            
            <div className="content-wrapper p-5">
                <div className="row">
                    <div className="col-md-12">
                        <h1>Usuarios Premium Manual</h1>
                        <UserListComponent users={this.state.users}/>
                        <div className="btn-row"  >
                        <button type="button" className="btn btn-primary" onClick={() => this.props.history.push("/admin/Users/downgrade-premium-manual")}>Quitar Premium Manual</button>
                        <button type="button" className="btn btn-primary" onClick={() => this.props.history.push("/admin/Users/actualizar-premium-manual")}>Acutalizar Premium Manual</button>
                        </div>
                    </div>
                </div>
		 	</div>
        );
    }
}

export default UsersManualComponent;