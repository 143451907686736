import React, { Component } from 'react';
import firebase from 'firebase';
import CurrentUser from "../core/PublicUserSessionContext";
import Spinner from "../../commons/Spinner";
import AulaItemComponent from '../AulasComponent/AulaItemComponent';
import logoBlanco from '../../../resources/images/logo-blanco.png'

class AulaGridAdministrar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      allDiplomados: [],
      allOrganizations: [{ aulas: [] }],
      active: { title: "", aulas: [], private: false, promoImageURL: "" },
      logoImageURL: "",
      hasCxVet: false,
      loading: true,

      allAulas: [],
    }
  }


  componentDidMount = () => {
    const db = firebase.firestore();
    let currentUser = this.context.currentUser;
    let allAulas = []
    let allAulaIDs = []
    let allOrganizations = []
    let aulaCollection = []

    db.collection('aulas').where("active", "==", true).where("adminDiplomado", "==", currentUser.id).get().then((aulaColl) => {
      aulaCollection = aulaColl
      let allAulas = aulaCollection.docs.map((doc) => { return { id: doc.id, ...doc.data() } });
      //let allAulaOrganizationIDs = aulaCollection.docs.map((doc) => doc.data().organization);

      db.collection('organizations').get().then((organizationsColl) => {

        organizationsColl.docs.forEach((organization) => {
          let orgAulas = []

          allAulas.forEach((aula) => {
            if (aula.organization === organization.id) {
              orgAulas.push(aula)
            }
          })

          if (orgAulas.length !== 0) 
            allOrganizations.push({ id: organization.id, ...organization.data(), aulas: orgAulas })

          })

        this.setState({ allOrganizations, active: allOrganizations[0], logoImageURL: allOrganizations[0].logoImageURL, loading: false })

      }).catch(error => {
        console.log(error)
        this.setState({ loading: false })
      })

    }).catch(error => {
      console.log(error)
      this.setState({ loading: false })
    })

  }


  render() {
    let orgTitle = ""
    orgTitle = this.state.active.title
    let currentUser = this.context.currentUser;


    return (
      <section className="video-grid">
        <div className="container">


          <div className="row ">
            <div className="col-md-12">

              <div className="video-title-bar d-md-flex justify-content-between">

                <h1 className="main-title">



                  Administrar Aulas
                </h1>

                <div>
                  <div className="mb-3 p-0 row no-gutters align-items-center">
                    <div className="col">
                      {//<input onChange={this.searchByName} className="form-control form-control-borderless" type="search" placeholder="Buscar en catálogo" />
                      }
                    </div>
                    <div className="col-auto pl-3 pr-3">
                      <a>
                        {//<FontAwesomeIcon icon={faSearch} />
                        }
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-3 col-md-4 col-sm-5 col-12" >

                  <img className="img-fluid  mb-2 preview" src={this.state.logoImageURL} />
                  <h1 className="main-title">
                    {orgTitle}
                  </h1>
                  <div className=" filter-sidebar">

                    <span className="h4">Organizaciones</span>
                    <p  >Todas</p>

                    <ul className="list-unstyled pl-3">
                      {this.state.allOrganizations.map(organization => {
                        return <li className={organization.id === this.state.active.id ? "Gratis active" : "Gratis"}><button onClick={() => this.setState({ logoImageURL: organization.logoImageURL, active: organization })}>{organization.title}</button></li>
                      })}
                    </ul>

                  </div>
                </div>
                <div className="col-lg-9 col-md-8 col-sm-7 col-12" >
                  <div className="row">
                    {
                      this.state.active.aulas.map(aula => {
                        return <AulaItemComponent key={aula.id} previewImageURL={aula.previewImageURL} title={aula.title} description={aula.description} link={`/administrar-aula/aula/${aula.id}`} />
                      })
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Spinner show={this.state.loading} />
      </section>
    );
  }
}
AulaGridAdministrar.contextType = CurrentUser;
export default AulaGridAdministrar