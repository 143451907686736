import React, { Component } from "react";
import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card';
import faqs from "../../resources/js/faqs";

class PublicFAQComponent extends Component {
    constructor() {
        super();
        this.state = {};
    }

    FAQElement = (props) =>
        <div className="pregunta">
            <Accordion.Toggle className="pregunta-header link-no-btn" eventKey={props.eventKey}>
                <h4>{props.faq.pregunta}</h4>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey={props.eventKey}>
                <Card.Body className="pregunta-body">
                    {props.faq.respuesta}
                </Card.Body>
            </Accordion.Collapse>
        </div>

    render() {
        const FAQElement = (passed) => this.FAQElement(passed)
        return (
            <section className="faq-page">
                <div className="container">
                    <div className="row ">
                        <div className="col-md-12">
                            <h1 className="main-title">
                                Preguntas frecuentes
                            </h1>
                            <div id="accordion" className="mt-4">
                                <Accordion defaultActiveKey={1}>
                                    {faqs.map((faq, index) => <FAQElement faq={faq} eventKey={index+1} />)}
                                </Accordion>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
export default PublicFAQComponent;