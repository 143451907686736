import React,{Component} from 'react';
import Modal from 'react-bootstrap/Modal';
import iconUser from '../../../resources/images/icon-user.svg';
import iconUserPremium from '../../../resources/images/icon-user-premium.svg';
import CurrentUser from "../core/PublicUserSessionContext";

class WelcomeModalComponent extends Component {
    constructor(props){
        super(props);
    }

    render() {
        let currentUser = "Gratis";      
        let uname = this.props.user.newUserName;

        if(uname === undefined  || uname === ""){
            uname = this.props.user.name;
        }
        let type = this.props.user.type;
        if(type === undefined  || type === ""){
            type = this.props.user.type;
        }
        console.log("RENDER WELCOME");
        console.log("TYPE IS");
        console.log(type);
        return (

            <Modal {...this.props} size="md" aria-labelledby="contained-modal-title-vcenter" centered >
                
                <Modal.Body className="text-center p-5">
                  <span className="register-modal-icon">
                    {currentUser==="Premium"?
                      <img width="80" src={iconUser} />
                    :
                      <img width="80" src={iconUserPremium} />
                    }
                    </span>
                    <p className="h4">
                        Hola {uname}, 
                    </p>
                    <p className="h5">Bienvenid@ a la comunidad CxVet!</p>
                    {type==="Gratis"?
                      <p>A partir de ahora podrás acceder a todos los videos de procedimientos quirúrgicos <span className="font-weight-bold">GRATIS</span> de CxVet</p>
                    :
                      <p>A partir de ahora podrás acceder a <span className="font-weight-bold">TODOS </span>los videos de procedimientos quirúrgicos y <span className="font-weight-bold">guías en PDF</span> de CxVet</p>
                    }
                    
                    <a href="/procedimientos" className="btn btn-secondary btn-block" >Comienza a explorar el catálogo</a>
                </Modal.Body>
              
            </Modal>
        );
    }
}
export default WelcomeModalComponent;