import React, { Component } from 'react';
import DataTableComponent from '../core/DataTableComponent';
import EliminarModal from './EliminarModal'
import ActivadoModal from './ActivadoModal'

import { getOrganizations } from '../../../service/organizations';
import { changeAulaActiveStatus, deleteAula, getAulasVirtuales } from '../../../service/aulas';

class AulasComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            aulas: [],
            organizations: [],

            eliminarModalOpen: false,
            activadoModalOpen: false,
            selectedAulaID: "",
        }
    }

    componentDidMount() {
        this.getInitialData()
    }

    getInitialData = async () => {
        let organizations = await getOrganizations()
        let aulas = await getAulasVirtuales()
        this.setState({ organizations, aulas });
    }

    getColumns = () => [
        { label: 'Título', field: 'title', sort: 'asc' },
        { label: 'Tipo', field: 'typeOrganization', sort: 'asc' },
        { label: 'Organización', field: 'organization', sort: 'asc' },
        { label: '# Videos', field: 'numVideos', sort: 'asc' },
        { label: '# Usuarios', field: 'numUsers', sort: 'asc' },
        { label: 'Editar', field: 'editar', sort: 'asc' },
        { label: 'Desactivar', field: 'desactivar', sort: 'asc' }
    ];
    getAulasData = () => {
        const rows = this.state.aulas.map((aula, index) => {
            let organization = this.state.organizations.find(org => org.id === aula.organization) || {}

            return {
                title: aula.title,
                typeOrganization: organization.type || "",
                organization: organization.title || "",
                numVideos: aula.videos.length,
                numUsers: organization.type === "Empresa" ? "N/A" : aula.users.length,
                editar: <button type="button" className="btn btn-primary" onClick={() => this.props.history.push(`/admin/aulas/aula/${aula.id}`)} >Editar</button>,
                desactivar: <button type="button" className="btn btn-primary" onClick={() => this.changeAulaStatus(aula.id, !aula.active, index)}>
                    {aula.active ? "Desactivar" : "Activar"}</button>,
            }
        });
        return { columns: this.getColumns(), rows };
    }

    changeAulaStatus = async (aulaID, activating, index) => {
        await changeAulaActiveStatus(aulaID, activating)
        let aulas = [...this.state.aulas]
        aulas[index].active = activating
        this.setState({ aulas, selectedAulaID: aulaID, activadoModalOpen: activating, eliminarModalOpen: !activating })
    }

    deleteAula = async () => {
        await deleteAula(this.state.selectedAulaID)
        this.updateAulaTable()

        this.setState({ activadoModalOpen: false, eliminarModalOpen: false })
        window.alert("El aula fue eliminado con éxito.")
    }
    updateAulaTable = () => {
        let aulas = [...this.state.aulas]
        aulas.splice(this.state.selectedAulaIDIndex, 1);
        this.setState({ aulas })
    }

    render() {
        return (
            <div className="content-wrapper p-5">
                <div className="row">
                    <div className="col-md-12">
                        <h1>Aulas </h1>
                        <DataTableComponent data={this.getAulasData()} />
                        <div className="btn-row"  >
                            <a role="button" href="/admin/aulas/crear-aula" className="btn btn-primary">Crear nueva aula</a>
                        </div>
                    </div>
                </div>

                <ActivadoModal show={this.state.activadoModalOpen} onHide={() => this.setState({ activadoModalOpen: false })} />
                <EliminarModal show={this.state.eliminarModalOpen} onHide={() => this.setState({ eliminarModalOpen: false })} deleteAula={this.deleteAula} />
            </div>
        );
    }
}
export default AulasComponent;