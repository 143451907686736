import React, {Component} from 'react';
import Dotdotdot from 'react-dotdotdot';

class ProcedureItemComponent extends Component {
    constructor(props){
        super(props);

    }

    render(){
        const procedure = this.props.procedure;
        const url = `/procedimiento/${procedure.id}`;
        const stylePreview = {backgroundImage:`url(${procedure.preview})`};
        const type = (this.props.userType=== "Premium"|| this.props.userType === "Legacy") ?"": procedure.type === "Gratis"? "free":"premium";
        const videoType = `video ${type}`;
        return(
            <div className="col-lg-4 col-md-6 video-container d-flex">
               
                  <div className={"flex-fill " + videoType} >
                     <a href={url}>
                        <div className="video-thumb" style={stylePreview}>
                        </div>
                    
                      <div className="video-content">
                        <h3>
                            {procedure.name}
                        </h3>
                        <p className="procedure_detail">
                            <Dotdotdot clamp={3}>
                                {procedure.detail}
                            </Dotdotdot>
                        </p>
                      </div>
                    </a>
                </div>
            </div>
        );
    }
}
export default ProcedureItemComponent;