import React, { Component } from 'react';
import Dotdotdot from 'react-dotdotdot';

class AulaItemComponent extends Component {
    constructor(props) {
        super(props);
    }

    render() {

        return (
            <div className="col-lg-4 col-md-6 video-container d-flex">

                <div className={"flex-fill video"} >
                    <a href={this.props.link}>
                        <div className="video-thumb" style={{ backgroundImage: `url(${this.props.previewImageURL})` }}>
                        </div>

                        <div className="video-content">
                            <h3>
                                {this.props.title}
                            </h3>
                            <p className="procedure_detail">
                                <Dotdotdot clamp={3}>
                                    Ingrese aquí para ver los videos...
                            </Dotdotdot>
                            </p>
                        </div>
                    </a>
                </div>
            </div>
        );
    }
}
export default AulaItemComponent;