import firebase from 'firebase';
import { uploadFileToStorage } from './storage';

export async function getProcedures(toOrderBy = false, direction = "") {
    let proceduresCollection = toOrderBy
        ? firebase.firestore().collection("procedures").orderBy(toOrderBy, direction)
        : firebase.firestore().collection("procedures")

    let response = await proceduresCollection.get().catch(error => console.log(`Error obtener cirugias: ${JSON.stringify(error)}`));
    let procedures = response.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    return procedures
}
export async function getProceduresOrderByDate() {
    return await getProcedures("date", "desc")
}
export async function getProceduresOrderByName() {
    return await getProcedures("name", "asc")
}

export async function getProcedure(procedureID) {
    let procedure = await firebase.firestore().collection("procedures").doc(procedureID).get().catch(error => console.log(`Error obtener datos de la cirugía: ${JSON.stringify(error)}`));
    return procedure.exists ? procedure.data() : null
}

export async function saveProcedureData(procedureID, data) {
    procedureID = procedureID || firebase.firestore().collection("procedures").doc().id
    return await firebase.firestore().collection("procedures").doc(procedureID).set(data, { merge: true }).catch(error => console.log(`Error al guardar cirugía: ${JSON.stringify(error)}`));
}

export async function deleteProcedure(procedureID) {
    return await firebase.firestore().collection("procedures").doc(procedureID).delete().catch(error => console.log(`Error al borrar cirugia: ${JSON.stringify(error)}`));
}

export async function uploadPreview(procedureID, previewFile, setState) {
    return await uploadFileToStorage("procedures", procedureID, getFileUploadName(previewFile), previewFile, "imagen vista previa", setState)
}
export async function uploadFasicle(procedureID, fasicleFile, setState) {
    return await uploadFileToStorage("procedures", procedureID, getFileUploadName(fasicleFile), fasicleFile, "guía del procedimiento", setState)
}
export async function uploadDemo(procedureID, demoFile, setState) {
    return await uploadFileToStorage("procedures", procedureID, getFileUploadName(demoFile), demoFile, "video demo", setState)
}
export async function uploadVideo(procedureID, videoFile, setState) {
    return await uploadFileToStorage("procedures", procedureID, getFileUploadName(videoFile), videoFile, "video principal", setState)
}

function getFileUploadName(file) {
    const time = Date.now().toString();
    const name = time.concat(file.name);
    return name
}


///////////////Tags///////////////
export async function getTags() {
    let response = await firebase.firestore().collection("tags").get().catch(error => console.log(`Error obtener tags: ${JSON.stringify(error)}`));
    let tags = response.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    return tags
}
export async function getTag(tagID) {
    let tag = await firebase.firestore().collection("tags").doc(tagID).get().catch(error => console.log(`Error obtener tag doc: ${JSON.stringify(error)}`));
    return tag.data()
}

export async function deleteTag(tagID) {
    return await firebase.firestore().collection("tags").doc(tagID).delete().catch(error => console.log(`Error al borrar tag: ${JSON.stringify(error)}`));
}
export async function saveTagData(tagID, data) {
    tagID = tagID || firebase.firestore().collection("tags").doc().id
    return await firebase.firestore().collection("tags").doc(tagID).set(data, { merge: true }).catch(error => console.log(`Error al guardar tag: ${JSON.stringify(error)}`));
}

export function getProcedureTagNames(procedure, tags = []) {
    let procedureTags = tags.filter(tag => procedure.tags.hasOwnProperty(tag.id))
    let tagNames = procedureTags.map(tag => tag.name)
    return tagNames.join(", ")
}
//////////////////////////////////