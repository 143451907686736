import firebase from 'firebase';


export function getUserProgress(exams) {
    let userProgress = 0
    let total = exams.length
    if (total === 0) return 0

    exams.forEach((exam, index) => {
        userProgress += exam.userHasPassed ? 1 : 0
    })

    return (parseFloat(userProgress * 100) / parseFloat(total)).toFixed(2)
}

export function getDiplomadoVideoData(diplomadoId, currentUserId) {
    return new Promise(async (resolve, reject) => {
        let allProcedures = await firebase.firestore().collection('procedures').get().catch(error => { reject(error) })
        let diplomadoVideos = await firebase.firestore().collection('diplomados').doc(diplomadoId).collection('videos').orderBy('index', 'asc').get().catch(error => { reject(error) })

        let videos = []
        diplomadoVideos.docs.forEach(video => {
            if (video.data().procedureId) {
                //console.log("VID DATA: ",{id:video.id, ...video.data()})
                let procedure = allProcedures.docs.find(doc => doc.id === video.data().procedureId)
                if (procedure)
                    videos.push({ id: video.id, ...video.data(), ...procedure.data(), userHasCompleted: video.data().completed.includes(currentUserId), active: !!video.data().active })
            }
            else{
                //console.log("VID DATA: ",{id:video.id, ...video.data()})
                videos.push({ id: video.id, ...video.data(), userHasCompleted: video.data().completed.includes(currentUserId), active: !!video.data().active })
            }
        })
        //console.log("VIDEOS ALL: ",videos)
        resolve(videos)
    })
}

export function setDiplomadoVideoCompleted(diplomadoId, currentUserId, videoId) {
    return new Promise(async (resolve, reject) => {
        await firebase.firestore().collection("diplomados").doc(diplomadoId).collection("videos").doc(videoId).update({
            completed: firebase.firestore.FieldValue.arrayUnion(currentUserId)
        }).catch(error => reject(error))

        resolve(true)
    })
}

export function getDiplomado(diplomadoId) {
    return new Promise(async (resolve, reject) => {
        let diplomado = await firebase.firestore().collection('diplomados').doc(diplomadoId).get().catch(error => reject(error))
        resolve({ id: diplomado.id, ...diplomado.data() })
    })
}