import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import { jsPDF } from "jspdf";
import cert from './Certificates/cvt_certificado_general.jpg' // relative path to image 
import certTrauma from './Certificates/traumatologia.jpg' // relative path to image 
import certNeuro from './Certificates/neurologia.jpg' // relative path to image 
import certAnes from './Certificates/anestecia.jpg' // relative path to image 
import certTejidos from './Certificates/tejidosblandos.jpg' // relative path to image 

var moment = require("moment");
moment.locale("es");

class CertificateModal extends Component {
  constructor(props) {
    super(props);

    this.state = {}
  }

  downloadCertificate = async () => {
    let image = new Image;
    image.crossOrigin = "anonymous";
    image.src = this.props.certificateURL;
    //Ejemplo: "https://firebasestorage.googleapis.com/v0/b/cvtdev.appspot.com/o/neurologia.jpg?alt=media&token=83da004e-0920-44d6-85b9-446c19604770";
    //console.log(image.src);

    const doc = new jsPDF({
      orientation: "landscape",
      unit: "in",
      format: [16, 12]
    });
    doc.addImage(image, "JPEG", .5, .5, 15, 11);

    doc.setFontSize(28)
    doc.text(this.props.name, 8.8, 6.4, 'center');
    doc.text(`Folio: ${this.props.getConstancia()}`, 14.5, 9.64, 'center');

    doc.save(`certificado-${this.props.diplomadoTitle}.pdf`);
    this.props.onHide();
  }
 

  render() {
    return (
      <Modal {...this.props} size="lg" className="progress-modal" centered >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <h5 className="modal-title" id="modalTerminoLabel">¡Felicidades!</h5>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <h1>Has completado el diplomado! A continuación puede descargar su certificado de finalización del curso. </h1>
        </Modal.Body>

        <Modal.Footer>
          <button type="button" className="btn btn-primary" onClick={this.downloadCertificate}>Bajar certificado</button>
        </Modal.Footer>
      </Modal>
    );
  }
}
export default CertificateModal;