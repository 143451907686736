import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import Alert from 'react-bootstrap/Alert';
import firebase from 'firebase';
import MaskedFormControl from 'react-bootstrap-maskedinput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

var moment = require("moment");
moment.locale("es");

class SuccessModal extends Component {
    constructor(props) {
        super(props);

        this.state = {

        }
    }

    componentDidMount = () => {
    }


    render() {

            return (
                <Modal {...this.props} size="lg" className="progress-modal" centered >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            {this.props.diplomado}
                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <h4>Ha comprado con éxito el acceso al diplomado, bienvenido al curso</h4>
                    </Modal.Body>

                    <Modal.Footer>

                        <button type="button" className="btn btn-primary" onClick={this.props.goToDiplomado} >Entrar al curso</button>

                    </Modal.Footer>
                </Modal>
            );

    }

}
export default SuccessModal;