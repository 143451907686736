import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import firebase from 'firebase';

import CurrentUser from '../core/PublicUserSessionContext';
import SelectTypeComponent from './SelectTypeComponent';
import SignUpFormComponent from './SignUpFormComponent';
import WelcomeModalComponent from './WelcomeModalComponent';
import ModalRegistrar from './ModalRegistrar';

class SignUpComponent extends Component {
    constructor() {
        super();
        const newUser = {
            newUserName: "",
            email: "",
            country: "",
            city: "",
            promosCxVet: false,
            newsCxVet: false,
            terms: false,
            type: "",
            paymentDay: "",
            date: Date.now(),
            university: "",
            generatedNumber: -1,
        }
        this.state = { user: this.context, generatedNumber: -1, newUser: newUser, showWelcomeModal: false, redirectToProcedures: false, planAnual: false };
    }

    componentWillMount = () => {
        this.setState({ user: this.context.currentUser });
    }

    updateNewUser = async (newUser) => {
        this.setState({ newUser })
    }
    checkTest = () => {
        firebase.firestore().collection("users").where("name", "==", "guess14").get().then(users => {
            window.alert("GOT IT! " + users.docs[0].data().email)

        }).catch(error => {
            window.alert("ERROR: " + error)
        })
    }
    createNewUser = () => {
        this.setState({ modalRegistrarShow: false })
        const newUser = this.state.newUser;
        this.context.iniciaCarga();
        firebase.auth().createUserWithEmailAndPassword(newUser.email, newUser.password)
            .then((response) => {
                console.log("guardado auth con exito:");
                this.setState({ uid: response.user.uid });
                this.saveNewUser(response.user.uid).then((result) => {
                    console.log("ALL GOOD");
                    this.context.terminaCarga();

                    this.props.history.push({
                        pathname: "/registro-finalizar", uid: response.user.uid, planAnual: this.state.planAnual, email: this.state.newUser.email,
                        userName: this.state.newUser.newUserName, paymentType: this.state.planAnual ? "PremiumAnual" : this.state.newUser.type, type: this.state.newUser.type
                    })

                }).catch((error) => {
                    console.log(error);
                    this.context.terminaCarga();
                    this.context.mostrarError("No se pudo crear usuario, contáctanos para resolver este problema", "ERROR CREAR USUARIO SNU");
                })
            })
            .catch(error => {
                console.log("fallo: ", error);
                if (error.code === "auth/email-already-in-use") {
                    this.context.mostrarError("Este usuario ya existe. Por favor ponte en contacto con nosotros para solucionar este problema", "ERROR CREAR USUARIO CNU");
                } else {
                    this.context.mostrarError("No se pudo crear usuario, intenta de nuevo. Si el error persiste contáctanos", "ERROR CREAR USUARIO CNU");
                }
                this.context.terminaCarga();
            })
    }

    redirectToProcedures = () => {
        this.setState({ redirectToProcedures: true })
    }


    saveNewUser = (uid) => {
        console.log("SingUpComponent.state:", this.state);
        const newUser = this.state.newUser;

        return firebase.firestore().collection("users").doc(uid).set({
            name: newUser.newUserName,
            email: newUser.email,
            country: newUser.country,
            city: newUser.city,
            promosCxVet: newUser.promosCxVet,
            newsCxVet: newUser.newsCxVet,
            type: "Gratis",
            hasPremiumAnual: false,
            paymentDay: "",
            subscriptionId: "",
            terminationDate: "",
            statusPago: "BIEN",
            university: newUser.university || "",
            userNumber: "",
            date: Date.now()
        })

    }

    getComponentToRender = () => {
        let currentUser = this.context.currentUser;
        if (currentUser.type === "Gest" || this.state.newUser.processStep === 4) {
            switch (this.state.newUser.processStep) {
                case 1:
                    return <SelectTypeComponent newUser={this.state.newUser} updateNewUser={this.updateNewUser} setAnual={(plan) => { this.setState({ planAnual: plan }) }} />
                    break;
                case 2:
                    return <SignUpFormComponent newUser={this.state.newUser} updateNewUser={this.updateNewUser} modalRegistrarShow={() => this.setState({ modalRegistrarShow: true })} />
                    break;
                default:
                    return <SelectTypeComponent newUser={this.state.newUser} updateNewUser={this.updateNewUser} setAnual={(plan) => { this.setState({ planAnual: plan }) }} />
                    break;
            }
        } else {
            if (this.state.redirectToProcedures) {
                return <Redirect to="/procedimientos" ></Redirect>;
            } else {

            }
        }

    }

    render() {
        console.log("SingUpComp.render.state", this.state);
        return <React.Fragment>
            <ModalRegistrar show={this.state.modalRegistrarShow} onHide={() => this.setState({ modalRegistrarShow: false })} closeModal={() => this.setState({ modalRegistrarShow: false })} registrarCuenta={this.createNewUser} />
            <WelcomeModalComponent show={this.state.showWelcomeModal} user={this.state.newUser} redirectToProcedures={this.redirectToProcedures} />
            {this.getComponentToRender()}
        </React.Fragment>
    }

}
SignUpComponent.contextType = CurrentUser;

export default SignUpComponent;
