import React from 'react';
export const defaultUser = {
    type: "Gest",
    paymentCard: {}
}
export const defaultError = {
    titulo: "",
    mensaje: "",
    mostrar: false
}

const CurrentUser = React.createContext({ defaultUser, error: defaultError });
export default CurrentUser; 