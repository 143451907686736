import React,{Component} from 'react';

import DataTableComponent from '../core/DataTableComponent';

class EventListComponent extends Component{
    constructor(props){
        super(props);
        this.state = {events : this.props.events}
    }

    getColumns = () => {
        return  [
         { label: 'Fecha de orden', field: 'order',  sort:'asc'},
         { label: 'Nombre', field: 'name', sort:'asc'},
         { label: 'Tipo de evento', field: 'type', sort:'asc'},
         { label: 'Fecha mostrada', field: 'date', sort:'asc'},
         { label: 'Estatus', field: 'status', sort:'asc'}
         
       ]; 
     }
     getDate = (date) => {
        const currentDate = new Date(date);
        const formatDate = `${currentDate.getFullYear()}/${currentDate.getMonth()+1}/${currentDate.getDate()}`;
        return formatDate;
    }
    
    getData = () => {
        const rows = [];
        this.props.events.map(event => {
            const pathEdit = `/admin/events/event/${event.id}`;
            rows.push({
                order: this.getDate(event.order),
                name: event.name,
                type: event.type,
                date: event.date,
                status: event.status,
                clickEvent:(event) => this.props.redirectTo(pathEdit)
              });
        });     
        return  {columns: this.getColumns(),rows};                 
    }

    render(){
        return(
            <DataTableComponent data={this.getData()}/>
            
        );
    }
}
export default EventListComponent;