import React, { Component } from 'react';

import green_check from '../../../resources/images/green_check.png'
import red_x from '../../../resources/images/red_x.png'
import { getExamScore, examIsCompleted, examAllCorrect, hasPassedExam } from '../../../service/exams'

var moment = require("moment");
moment.locale("es");

class ExamModal extends Component {
    constructor(props) {
        super(props);

        this.state = {}
    }

    render() {
        let userHasCompleted = this.props.exam.userHasCompleted
        let userHasAnsweredAllQuestions = examIsCompleted(this.props.exam)
        let userHasPassedExam = this.props.exam.userHasPassed && userHasCompleted
        let userHasFailedExam = userHasCompleted && !userHasPassedExam
        const {points, percent, total} = getExamScore(this.props.exam)

        if (!this.props.exam)
            return null
        else
            return (
                <section className="video-page">
                    <div className="container ">
                        <div name="video ">
                            <div className="container-fluid">

                                <div className="col-md-12">
                                    <div className="main-title my-3">
                                        <h1 className="video-title mb-0">
                                            <p className="h4 text-center" >{this.props.exam.title || ""}</p>
                                        </h1>
                                    </div>
                                </div>

                                {userHasCompleted && <h1 className="mb-4">{`Puntos - ${points}/${total}`}</h1>}
                                {this.props.exam.questions.map((question, indexQ) => {
                                    return <div className="ml-2 mb-3">
                                        <h5>{question.question}</h5>
                                        {question.answers.map((answer, indexA) => {
                                            return <div className="ml-5 mb-1" >
                                                <input disabled={userHasCompleted} checked={question.userAnswer == indexA}
                                                    onChange={(e) => { this.props.changeAnswer(indexQ, indexA) }} className="form-check-input" type="checkbox" />
                                                <p >{answer}
                                                    {userHasFailedExam && question.userAnswer == indexA && question.userAnswer == question.correctAnswer && <img style={{ marginLeft: 6 }} height={22} width={22} src={green_check} />}
                                                    {userHasFailedExam && question.userAnswer == indexA && question.userAnswer != question.correctAnswer && <img style={{ marginLeft: 6 }} height={22} width={22} src={red_x} />}
                                                    {userHasPassedExam && question.correctAnswer == indexA && <><img style={{ marginLeft: 6, marginRight: 10 }} height={22} width={22} src={green_check} />{question.userAnswer != question.correctAnswer && <label style={{ fontSize: 13 }}>Respuesta correcta</label>}</>}
                                                    {userHasPassedExam && question.userAnswer == indexA && question.userAnswer != question.correctAnswer && <img style={{ marginLeft: 6 }} height={22} width={22} src={red_x} />}
                                                </p>
                                            </div>
                                        })}
                                    </div>
                                })}

                                {userHasCompleted && <h3>Calificación: {percent}%</h3>}
                                <div className="row justify-content-center" >
                                    {userHasFailedExam && <button type="button" className="btn btn-primary ml-3" onClick={() => this.props.retakeExam()}>Volver a tomar el exámen</button>}
                                    {userHasPassedExam ? <label>Ya has aprobado este examen.</label> : <button disabled={!userHasAnsweredAllQuestions || userHasCompleted} type="button" className="btn btn-primary" onClick={this.props.saveExam}>Guardar</button>}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            );
    }
}
export default ExamModal;