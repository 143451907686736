import firebase from "firebase";

export async function getAllUsers() {
    let response = await firebase.firestore().collection("users").get().catch(error => console.log(`Error obtener usuarios: ${JSON.stringify(error)}`));
    let users = response.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    return users
}
export async function getUser(userID) {
    let user = await firebase.firestore().collection("users").doc(userID).get().catch(error => console.log(`Error obtener usuario ${userID}: ${JSON.stringify(error)}`));
    return user.exists ? { id: user.id, ...user.data() } : {}
}